import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../states/hooks";
import { RootState } from "../../../states/store";
import { setCryptocurrencyPair } from "../../../states/main/streamSlice";
import { setThousandSeparator } from "../../../utils/common/stringFormatUtil";
import { cryptocurrencyPairsData } from "../../../datas/common/cryptocurrencyPairsData";
import { CryptocurrencyPairEnum } from "../../../enums/main/cryptocurrencyPairEnum";
import { WebSocketConnectionState } from "../../../enums/main/webSocketConnectionStateEnum";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import pink from "@mui/material/colors/pink";

const CryptocurrencyPriceMenu = () => {
    const dispatch = useAppDispatch();
    const candles = useAppSelector((state: RootState) => state.stream.candles);

    const CryptocurrencyPair: string = useAppSelector(
        (state: RootState) => state.stream.cryptocurrencyPair
    );

    const ConnectionState: WebSocketConnectionState | null = useAppSelector(
        (state: RootState) => state.stream.connectionState
    );

    const [cryptocurrencyPrice, setCryptocurrencyPrice] = useState<
        null | number
    >(null);

    const [priceIncrease, setPriceIncrease] = useState<null | boolean>(null);

    const [anchorElCurrency, setAnchorElCurrency] =
        useState<null | HTMLElement>(null);

    useEffect(() => {
        if (candles && candles.length > 0) {
            setCryptocurrencyPrice(candles[candles.length - 1].close);
        }
        if (candles && candles.length > 1) {
            setPriceIncrease(
                candles[candles.length - 1].close >
                    candles[candles.length - 2].close
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [candles]);

    const handleOpenCurrencyMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElCurrency(event.currentTarget);
    };

    const handleCloseCurrencyMenu = () => {
        setAnchorElCurrency(null);
    };

    const handleListItemClick = (cryptocurrency: CryptocurrencyPairEnum) => {
        dispatch(setCryptocurrencyPair(cryptocurrency));
        setAnchorElCurrency(null);
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
            }}
        >
            <Tooltip
                title="Kriptovaluta választása"
                placement="bottom-start"
            >
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onClick={handleOpenCurrencyMenu}
                >
                    {
                        cryptocurrencyPairsData.filter(
                            (item) => CryptocurrencyPair === item.code
                        )[0].icon
                    }
                    {ConnectionState &&
                    ConnectionState === WebSocketConnectionState.OPEN &&
                    cryptocurrencyPrice !== null ? (
                        <>
                            {priceIncrease !== null && priceIncrease ? (
                                <ArrowDropUpIcon sx={{ color: "#00FF80" }} />
                            ) : (
                                <ArrowDropDownIcon sx={{ color: pink[400] }} />
                            )}
                            {setThousandSeparator(cryptocurrencyPrice)}{" "}
                        </>
                    ) : (
                        <CircularProgress
                            sx={{ ml: "5px", mr: "5px" }}
                            color="warning"
                            size={20}
                        />
                    )}
                    $
                </Typography>
            </Tooltip>
            <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElCurrency}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchorElCurrency)}
                onClose={handleCloseCurrencyMenu}
            >
                {cryptocurrencyPairsData.map((item) => (
                    <MenuItem
                        key={item.code}
                        selected={CryptocurrencyPair === item.code}
                        onClick={() => handleListItemClick(item.code)}
                        sx={{
                            color:
                                CryptocurrencyPair === item.code
                                    ? pink[400]
                                    : "inherit",
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                color: "inherit",
                            }}
                        >
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText>{item.label}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default CryptocurrencyPriceMenu;
