import { useEffect } from "react";
import { useAppDispatch } from "../../states/hooks";
import { setActiveMenuItem } from "../../states/common/coreSlice";
import CandlestickChart from "../../components/main/CandlestickChart";
import VolumeBarChart from "../../components/main/VolumeBarChart";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";

const Dashboard = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setActiveMenuItem("dashboard"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container
            component="main"
            sx={{
                overflow: "hidden",
                mb: 2,
                pt: 2,
                pb: 2,
                boxShadow: 2,
                borderRadius: 2,
                background: "#0D0D45",
            }}
            maxWidth="md"
        >
            <Typography
                variant="h5"
                component="h1"
                gutterBottom
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    color: "#DCC28E",
                }}
            >
                <AutoGraphIcon
                    sx={{
                        mr: 1,
                        width: 27,
                        height: 27,
                    }}
                />
                Dashboard
            </Typography>
            <CandlestickChart />
            <VolumeBarChart />
        </Container>
    );
};

export default Dashboard;
