import { useAppSelector } from "../../states/hooks";
import { RootState } from "../../states/store";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const LoadingBackdrop = () => {
    const loadingBackdrop: any = useAppSelector(
        (state: RootState) => state.core.loadingBackdrop
    );
    return (
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={
                loadingBackdrop && loadingBackdrop.open
                    ? loadingBackdrop.open
                    : false
            }
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress
                    size="4rem"
                    color="inherit"
                    sx={{
                        m: 4,
                    }}
                />
                {loadingBackdrop && loadingBackdrop.message ? (
                    <Typography align="center" component="h1" variant="h2">
                        Kérjük várjon, <br />
                        {loadingBackdrop.message}
                    </Typography>
                ) : (
                    <Typography align="center" component="h1" variant="h2">
                        Kérjük várjon!
                    </Typography>
                )}
            </Box>
        </Backdrop>
    );
};

export default LoadingBackdrop;
