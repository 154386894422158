import { Middleware } from "redux";
import {
    setWebSocketConnecting,
    setWebSocketOpen,
    setWebSocketClosed,
    setWebSocketReconnecting,
    setCleanCandles,
    addCandle,
    setCleanStream,
} from "../states/main/streamSlice";
import {
    setOpenNotificationSnackbar,
    setCloseNotificationSnackbar,
} from "../states/common/coreSlice";
import { statusAlertsData } from "../datas/common/statusAlertsData";
import CandleType from "../types/main/candleType";
import CryptocurrencyPairType from "../types/main/cryptocurrencyPairType";
import { WebSocketConnectionState } from "../enums/main/webSocketConnectionStateEnum";

const webSocketMiddleware: Middleware = (store) => {
    let webSocketClient: WebSocket | null | undefined;
    let connectionState: WebSocketConnectionState | null | undefined;
    let streamedCryptocurrencyPair: CryptocurrencyPairType | null | undefined;
    const reconnectionDelay = 6000;

    return (next: any) => (action: any) => {
        // console.log("redux: " + store.getState().stream.cryptocurrencyPair);
        // console.log("websocket: " + streamedCryptocurrencyPair);
        // console.log(
        //     streamedCryptocurrencyPair ===
        //         store.getState().stream.cryptocurrencyPair
        // );
        // If there is a new menu choice, close the connection and open a new one
        if (
            webSocketClient !== null &&
            webSocketClient !== undefined &&
            connectionState === WebSocketConnectionState.OPEN &&
            streamedCryptocurrencyPair !== null &&
            streamedCryptocurrencyPair !== undefined &&
            streamedCryptocurrencyPair !==
                store.getState().stream.cryptocurrencyPair
        ) {
            webSocketClient?.close();
            connectionState = WebSocketConnectionState.CLOSED;
            store.dispatch(setWebSocketClosed());
            store.dispatch(setCleanCandles());
        }
        if (
            setWebSocketConnecting.match(action) &&
            (!webSocketClient ||
                (webSocketClient &&
                    webSocketClient.readyState > 2 &&
                    connectionState !== WebSocketConnectionState.RECONNECTING))
        ) {
            // "wss://stream.binance.com:9443/ws/btcbusd@kline_1s"
            webSocketClient = new WebSocket(
                `wss://stream.binance.com:9443/ws/${
                    store.getState().stream.cryptocurrencyPair
                }@kline_${store.getState().stream.frequency}`
            );
            streamedCryptocurrencyPair =
                store.getState().stream.cryptocurrencyPair;
        } else if (
            webSocketClient &&
            webSocketClient.readyState > 1 &&
            connectionState === WebSocketConnectionState.RECONNECTING
        ) {
            connectionState = WebSocketConnectionState.CLOSED;
            store.dispatch(setWebSocketClosed());
            store.dispatch(setCloseNotificationSnackbar());
            setTimeout(() => {
                store.dispatch(
                    setOpenNotificationSnackbar({
                        severity:
                            statusAlertsData.exchangewebsockettryreconnect
                                .severity,
                        title: statusAlertsData.exchangewebsockettryreconnect
                            .title,
                        message:
                            statusAlertsData.exchangewebsockettryreconnect
                                .message,
                    })
                );
            }, 300);
            webSocketClient = new WebSocket(
                `wss://stream.binance.com:9443/ws/${
                    store.getState().stream.cryptocurrencyPair
                }@kline_${store.getState().stream.frequency}`
            );
            streamedCryptocurrencyPair =
                store.getState().stream.cryptocurrencyPair;
        }

        if (webSocketClient) {
            webSocketClient.onopen = () => {
                if (connectionState === WebSocketConnectionState.CLOSED) {
                    store.dispatch(setCloseNotificationSnackbar());
                    setTimeout(() => {
                        store.dispatch(
                            setOpenNotificationSnackbar({
                                severity:
                                    statusAlertsData.exchangewebsocketreopened
                                        .severity,
                                title: statusAlertsData
                                    .exchangewebsocketreopened.title,
                                message:
                                    statusAlertsData.exchangewebsocketreopened
                                        .message,
                            })
                        );
                    }, 300);
                }
                connectionState = WebSocketConnectionState.OPEN;
                store.dispatch(setWebSocketOpen());
            };

            webSocketClient.onclose = () => {
                if (store.getState().user.profile.email) {
                    connectionState = WebSocketConnectionState.CLOSED;
                    store.dispatch(setWebSocketClosed());

                    store.dispatch(setCloseNotificationSnackbar());
                    if (
                        webSocketClient !== null &&
                        webSocketClient !== undefined &&
                        streamedCryptocurrencyPair !== null &&
                        streamedCryptocurrencyPair !== undefined &&
                        streamedCryptocurrencyPair !==
                            store.getState().stream.cryptocurrencyPair
                    ) {
                        setTimeout(() => {
                            store.dispatch(
                                setOpenNotificationSnackbar({
                                    severity:
                                        statusAlertsData
                                            .exchangewebsocketcurrencychanged
                                            .severity,
                                    title: statusAlertsData
                                        .exchangewebsocketcurrencychanged.title,
                                    message:
                                        statusAlertsData
                                            .exchangewebsocketcurrencychanged
                                            .message,
                                })
                            );
                        }, 300);
                    } else {
                        setTimeout(() => {
                            store.dispatch(
                                setOpenNotificationSnackbar({
                                    severity:
                                        statusAlertsData.exchangewebsocketclose
                                            .severity,
                                    title: statusAlertsData
                                        .exchangewebsocketclose.title,
                                    message:
                                        statusAlertsData.exchangewebsocketclose
                                            .message,
                                })
                            );
                        }, 300);
                    }
                    setTimeout(() => {
                        connectionState = WebSocketConnectionState.RECONNECTING;
                        store.dispatch(setWebSocketReconnecting());
                    }, reconnectionDelay);
                }
            };

            if (
                (setWebSocketClosed.match(action) ||
                    setCleanStream.match(action)) &&
                webSocketClient.readyState < 2
            ) {
                connectionState = undefined;
                webSocketClient.close();
            }

            // webSocketClient !== null &&
            //             webSocketClient !== undefined &&
            //             connectionState === WebSocketConnectionState.OPEN &&
            //             streamedCryptocurrencyPair !== null &&
            //             streamedCryptocurrencyPair !== undefined &&
            //             streamedCryptocurrencyPair ===
            //                 store.getState().stream.cryptocurrencyPair
            webSocketClient.onmessage = (event: any) => {
                const kline = JSON.parse(event.data);
                try {
                    if (kline) {
                        //TODO Hungarian timestamp +2h
                        const candel: CandleType = {
                            timestamp: kline.E + 7200000,
                            open: kline.k.o,
                            close: kline.k.c,
                            high: kline.k.h,
                            low: kline.k.l,
                            volume: kline.k.v,
                        };
                        if (
                            webSocketClient !== null &&
                            webSocketClient !== undefined &&
                            connectionState === WebSocketConnectionState.OPEN &&
                            streamedCryptocurrencyPair !== null &&
                            streamedCryptocurrencyPair !== undefined &&
                            streamedCryptocurrencyPair ===
                                store.getState().stream.cryptocurrencyPair
                        ) {
                            store.dispatch(addCandle(candel));
                        } else {
                            store.dispatch(setCleanCandles());
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            };
        }
        return next(action);
    };
};

export default webSocketMiddleware;

// Example data structure
// https://github.com/binance/binance-spot-api-docs/blob/master/web-socket-streams.md#klinecandlestick-streams
// {
//     "e": "kline",     // Event type
//     "E": 123456789,   // Event time
//     "s": "BNBBTC",    // Symbol
//     "k": {
//       "t": 123300000, // Kline start time
//       "T": 123460000, // Kline close time
//       "s": "BNBBTC",  // Symbol
//       "i": "1m",      // Interval
//       "f": 100,       // First trade ID
//       "L": 200,       // Last trade ID
//       "o": "0.0010",  // Open price
//       "c": "0.0020",  // Close price
//       "h": "0.0025",  // High price
//       "l": "0.0015",  // Low price
//       "v": "1000",    // Base asset volume
//       "n": 100,       // Number of trades
//       "x": false,     // Is this kline closed?
//       "q": "1.0000",  // Quote asset volume
//       "V": "500",     // Taker buy base asset volume
//       "Q": "0.500",   // Taker buy quote asset volume
//       "B": "123456"   // Ignore
//     }
//   }

// https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
// 0    CONNECTING  Socket has been created. The connection is not yet open.
// 1    OPEN        The connection is open and ready to communicate.
// 2    CLOSING The connection is in the process of closing.
// 3    CLOSED  The connection is closed or couldn't be opened.
