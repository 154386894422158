import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../states/hooks";
import { RootState } from "../../states/store";
import { setUserProfile } from "../../states/main/userSlice";
import {
    setActiveMenuItem,
    setOpenNotificationSnackbar,
} from "../../states/common/coreSlice";
import { countries } from "../../datas/common/countriesData";
import {
    UserCountry,
    UserNewsletterSubscription,
} from "../../enums/main/userEnum";
import ProfileFormValuesType from "../../types/main/profileFormValuesType";
// import ProfileFormErrorsType from "../../types/main/profileFormErrorsType";
// import { initialProfileFormErrors } from "../../datas/main/initialProfileFormErrors";
import { statusAlertsData } from "../../datas/common/statusAlertsData";
import {
    readUserProfile,
    updateUserReportFrequency,
} from "../../services/main/ProfileService";
import { updateUserNewsletterSubscription } from "../../services/main/NewsletterSubscriptionService";
import { StatusCodes } from "http-status-codes";
import { formatHungarianPhoneNumber } from "../../utils/common/stringFormatUtil";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
// import Box from "@mui/material/Box";
// import FormControl from "@mui/material/FormControl";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const Profile = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userProfile = useAppSelector(
        (state: RootState) => state.user.profile
    );

    const [formValues, setFormValues] = useState<ProfileFormValuesType>({
        lastName: userProfile.lastName,
        firstName: userProfile.firstName,
        country: userProfile.country,
        zip: userProfile.zip,
        email: userProfile.email,
        phone: userProfile.phone,
        password: null,
        passwordAgain: null,
        showPassword: false,
        showPasswordAgain: false,
        newsletterSubscription: userProfile.newsletterSubscription,
        reportPerOrder: userProfile.reportPerOrder,
        reportDaily: userProfile.reportDaily,
        reportWeekly: userProfile.reportWeekly,
        reportMonthly: userProfile.reportMonthly,
    });

    const [lastReadProfile, setLastReadProfile] = useState<Date | null>(null);

    useEffect(() => {
        if (lastReadProfile !== null) {
            readUserProfile().then(
                (response) => {
                    dispatch(setUserProfile(response.data));
                },
                (error) => {
                    // console.log(error);
                    if (error.response && error.response.status) {
                        //TODO többi hibát is lekezelni
                        if (
                            error.response.status ===
                                StatusCodes.UNAUTHORIZED ||
                            error.response.status === StatusCodes.NOT_FOUND
                        ) {
                            navigate("../logout");
                        } else {
                            dispatch(
                                setOpenNotificationSnackbar({
                                    severity:
                                        statusAlertsData.communicationerror
                                            .severity,
                                    title: statusAlertsData.communicationerror
                                        .title,
                                    message:
                                        statusAlertsData.communicationerror
                                            .message,
                                })
                            );
                        }
                    }
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastReadProfile]);

    useEffect(() => {
        dispatch(setActiveMenuItem("profile"));
        setFormValues({
            lastName: userProfile.lastName,
            firstName: userProfile.firstName,
            country: userProfile.country,
            zip: userProfile.zip,
            email: userProfile.email,
            phone: userProfile.phone,
            password: null,
            passwordAgain: null,
            showPassword: false,
            showPasswordAgain: false,
            newsletterSubscription: userProfile.newsletterSubscription,
            reportPerOrder: userProfile.reportPerOrder,
            reportDaily: userProfile.reportDaily,
            reportWeekly: userProfile.reportWeekly,
            reportMonthly: userProfile.reportMonthly,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    useEffect(() => {
        // console.log(formValues);
        // const formErrorsObject: RegistrationFormErrorsType =
        // validateProfileForm(formValues);
        // setFormErrors(formErrorsObject);
        // if (Object.values(formErrorsObject).every((x) => !x)) {
        // }
        if (
            formValues.newsletterSubscription !== null &&
            formValues.newsletterSubscription !==
                userProfile.newsletterSubscription
        ) {
            updateUserNewsletterSubscription(
                formValues.newsletterSubscription
            ).then(
                () => {
                    setLastReadProfile(new Date());
                    if (
                        formValues.newsletterSubscription ===
                        UserNewsletterSubscription.SUBSCRIBED
                    ) {
                        dispatch(
                            setOpenNotificationSnackbar({
                                severity:
                                    statusAlertsData.newslettersubscribed
                                        .severity,
                                title: statusAlertsData.newslettersubscribed
                                    .title,
                                message:
                                    statusAlertsData.newslettersubscribed
                                        .message,
                            })
                        );
                    } else {
                        dispatch(
                            setOpenNotificationSnackbar({
                                severity:
                                    statusAlertsData.newsletterunsubscribedmain
                                        .severity,
                                title: statusAlertsData
                                    .newsletterunsubscribedmain.title,
                                message:
                                    statusAlertsData.newsletterunsubscribedmain
                                        .message,
                            })
                        );
                    }
                },
                (error) => {
                    // console.log(error);
                    if (error.response && error.response.status) {
                        if (
                            error.response.status ===
                                StatusCodes.UNAUTHORIZED ||
                            error.response.status === StatusCodes.NOT_FOUND
                        ) {
                            //TODO status továbbadni
                            navigate("../logout");
                        } else {
                            //TODO többi hibát is lekezelni
                            dispatch(
                                setOpenNotificationSnackbar({
                                    severity:
                                        statusAlertsData
                                            .newsletterunsubscribeerror
                                            .severity,
                                    title: statusAlertsData
                                        .newsletterunsubscribeerror.title,
                                    message:
                                        statusAlertsData
                                            .newsletterunsubscribeerror.message,
                                })
                            );
                        }
                    }
                }
            );
        }
        if (
            formValues.reportPerOrder !== null &&
            formValues.reportDaily !== null &&
            formValues.reportWeekly !== null &&
            formValues.reportMonthly !== null &&
            (formValues.reportPerOrder !== userProfile.reportPerOrder ||
                formValues.reportDaily !== userProfile.reportDaily ||
                formValues.reportWeekly !== userProfile.reportWeekly ||
                formValues.reportMonthly !== userProfile.reportMonthly)
        ) {
            updateUserReportFrequency(formValues).then(
                () => {
                    setLastReadProfile(new Date());
                    dispatch(
                        setOpenNotificationSnackbar({
                            severity:
                                statusAlertsData.changedreportfrequency
                                    .severity,
                            title: statusAlertsData.changedreportfrequency
                                .title,
                            message:
                                statusAlertsData.changedreportfrequency.message,
                        })
                    );
                },
                (error) => {
                    // console.log(error);
                    if (error.response && error.response.status) {
                        //TODO többi hibát is lekezelni
                        if (
                            error.response.status ===
                                StatusCodes.UNAUTHORIZED ||
                            error.response.status === StatusCodes.NOT_FOUND
                        ) {
                            //TODO status továbbadni
                            navigate("../logout");
                        } else {
                            dispatch(
                                setOpenNotificationSnackbar({
                                    severity:
                                        statusAlertsData
                                            .changereportfrequencynerror
                                            .severity,
                                    title: statusAlertsData
                                        .changereportfrequencynerror.title,
                                    message:
                                        statusAlertsData
                                            .changereportfrequencynerror
                                            .message,
                                })
                            );
                        }
                    }
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues]);

    // TODO 2023.05.17. will be switched back when there are reports
    // const handleReportFrequencyChange = (
    //     event: React.ChangeEvent<HTMLInputElement>
    // ) => {
    //     setFormValues({
    //         ...formValues,
    //         [event.target.name]: event.target.checked,
    //     });
    // };

    const handleSubscribe = () => {
        setFormValues({
            ...formValues,
            newsletterSubscription: UserNewsletterSubscription.SUBSCRIBED,
        });
    };

    const handleUnsubscribe = () => {
        setFormValues({
            ...formValues,
            newsletterSubscription: UserNewsletterSubscription.UNSUBSCRIBED,
        });
    };

    return (
        <Container
            component="main"
            sx={{
                overflow: "hidden",
                mb: 2,
                pt: 2,
                pb: 2,
                boxShadow: 2,
                borderRadius: 2,
                background: "#0D0D45",
            }}
            maxWidth="md"
        >
            <Typography
                variant="h5"
                component="h1"
                gutterBottom
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    color: "#DCC28E",
                }}
            >
                <AccountBoxIcon
                    sx={{
                        mr: 1,
                        width: 27,
                        height: 27,
                    }}
                />
                Profil megtekintése
            </Typography>
            <List>
                <ListItem sx={{ pl: 0 }}>
                    <Typography
                        component="span"
                        sx={{
                            width: "20vw",
                            minWidth: "105px",
                            fontWeight: 900,
                        }}
                    >
                        Név
                    </Typography>
                    <Typography component="span">
                        {userProfile.lastName && userProfile.firstName ? (
                            <>
                                {userProfile.country === UserCountry.HU ? (
                                    <>
                                        {userProfile.lastName +
                                            " " +
                                            userProfile.firstName}
                                    </>
                                ) : (
                                    <>
                                        {userProfile.firstName +
                                            " " +
                                            userProfile.lastName}
                                    </>
                                )}
                            </>
                        ) : null}
                    </Typography>
                </ListItem>
                <ListItem sx={{ pl: 0 }}>
                    <Typography
                        component="span"
                        sx={{
                            width: "20vw",
                            minWidth: "105px",
                            fontWeight: 900,
                        }}
                    >
                        E-mail
                    </Typography>
                    <Typography component="span">
                        {userProfile.email}
                    </Typography>
                </ListItem>
                <ListItem sx={{ pl: 0 }}>
                    <Typography
                        component="span"
                        sx={{
                            width: "20vw",
                            minWidth: "105px",
                            fontWeight: 900,
                        }}
                    >
                        Mobil
                    </Typography>
                    <Typography component="span">
                        {userProfile.country === UserCountry.HU ? (
                            <>{formatHungarianPhoneNumber(userProfile.phone)}</>
                        ) : (
                            <>{userProfile.phone}</>
                        )}
                    </Typography>
                </ListItem>
                {/* TODO 2023.05.17. will be switched back when there is localization */}
                {/* <ListItem sx={{ pl: 0 }}>
                    <Typography
                        component="span"
                        sx={{
                            width: "20vw",
                            minWidth: "105px",
                            fontWeight: 900,
                        }}
                    >
                        Nyelv
                    </Typography>
                    <Typography component="span">
                        {userProfile.language === "HU" ? "magyar" : "english"}
                    </Typography>
                </ListItem> */}
                <ListItem sx={{ pl: 0 }}>
                    <Typography
                        component="span"
                        sx={{
                            width: "20vw",
                            minWidth: "105px",
                            fontWeight: 900,
                        }}
                    >
                        Ország
                    </Typography>
                    <Typography component="span">
                        {
                            countries.find((country) => {
                                return country.code === userProfile.country;
                            })?.label
                        }
                    </Typography>
                </ListItem>
                <ListItem sx={{ pl: 0 }}>
                    <Typography
                        component="span"
                        sx={{
                            width: "20vw",
                            minWidth: "105px",
                            fontWeight: 900,
                        }}
                    >
                        Irányítószám
                    </Typography>
                    <Typography component="span">{userProfile.zip}</Typography>
                </ListItem>
                <ListItem sx={{ pl: 0, alignItems: "flex-start" }}>
                    <Typography
                        component="span"
                        mt={{ xs: 0, sm: 0.5 }}
                        sx={{
                            width: "20vw",
                            minWidth: "105px",
                            fontWeight: 900,
                        }}
                    >
                        Hírlevél
                    </Typography>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                        alignItems={{ xs: "flex-start", sm: "center" }}
                    >
                        <Typography component="span">
                            {userProfile.newsletterSubscription ===
                            UserNewsletterSubscription.SUBSCRIBED
                                ? "Feliratkozva"
                                : null}
                            {userProfile.newsletterSubscription ===
                            UserNewsletterSubscription.UNSUBSCRIBED
                                ? "Leiratkozva"
                                : null}
                            {userProfile.newsletterSubscription ===
                            UserNewsletterSubscription.NOT_SUBSCRIBED
                                ? "Még nincs feliratkozva"
                                : null}
                        </Typography>
                        {userProfile.newsletterSubscription ===
                        UserNewsletterSubscription.SUBSCRIBED ? (
                            <Button
                                variant="contained"
                                size="small"
                                color="error"
                                startIcon={<RemoveCircleOutlineIcon />}
                                onClick={handleUnsubscribe}
                                sx={{
                                    pt: 0,
                                    pb: 0,
                                }}
                            >
                                Leiratkozás
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                size="small"
                                startIcon={<MarkEmailReadIcon />}
                                onClick={handleSubscribe}
                                sx={{
                                    pt: 0,
                                    pb: 0,
                                }}
                            >
                                Feliratkozás
                            </Button>
                        )}
                    </Stack>
                </ListItem>
                {/* TODO 2023.05.17. will be switched back when there are reports */}
                {/* <ListItem sx={{ pl: 0, alignItems: "flex-start" }}>
                    <Typography
                        component="span"
                        sx={{
                            mt: 1,
                            width: "20vw",
                            minWidth: "110px",
                            fontWeight: 900,
                        }}
                    >
                        Jelentések
                    </Typography>
                    {userProfile.reportPerOrder !== null &&
                    userProfile.reportDaily !== null &&
                    userProfile.reportWeekly !== null &&
                    userProfile.reportMonthly !== null ? (
                        <Box sx={{ display: "flex" }}>
                            <FormControl
                                sx={{ mb: 2 }}
                                component="fieldset"
                                variant="standard"
                            >
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    userProfile.reportPerOrder
                                                }
                                                onChange={
                                                    handleReportFrequencyChange
                                                }
                                                name="reportPerOrder"
                                                sx={{
                                                    color: "#eee",
                                                }}
                                            />
                                        }
                                        label="Tranzakciónként"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    userProfile.reportDaily
                                                }
                                                onChange={
                                                    handleReportFrequencyChange
                                                }
                                                name="reportDaily"
                                                sx={{
                                                    color: "#eee",
                                                }}
                                            />
                                        }
                                        label="Naponta"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    userProfile.reportWeekly
                                                }
                                                onChange={
                                                    handleReportFrequencyChange
                                                }
                                                name="reportWeekly"
                                                sx={{
                                                    color: "#eee",
                                                }}
                                            />
                                        }
                                        label="Hetente"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    userProfile.reportMonthly
                                                }
                                                onChange={
                                                    handleReportFrequencyChange
                                                }
                                                name="reportMonthly"
                                                sx={{
                                                    color: "#eee",
                                                }}
                                            />
                                        }
                                        label="Havonta"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Box>
                    ) : null}
                </ListItem> */}
            </List>
        </Container>
    );
};

export default Profile;
