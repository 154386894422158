import ChangePasswordFormValuesType from "../../types/home/changePasswordFormValuesType";
import ChangePasswordFormErrorsType from "../../types/home/changePasswordFormErrorsType";
import { initialChangePasswordFormErrors } from "../../datas/home/initialChangePasswordFormErrors";

export const validateChangePasswordForm = (
    values: ChangePasswordFormValuesType
) => {
    const errors: ChangePasswordFormErrorsType = {
        ...initialChangePasswordFormErrors,
    };

    const checkLowerCase = (password: string) => {
        for (let char of password) {
            if (char === char.toLowerCase() && char.match(/[a-z]/i)) {
                return true;
            }
        }
        return false;
    };

    const checkUpperCase = (password: string) => {
        for (let char of password) {
            if (char === char.toUpperCase() && char.match(/[A-Z]/i)) {
                return true;
            }
        }
        return false;
    };

    const checkNumberCase = (password: string) => {
        for (let char of password) {
            if (!isNaN(Number(char)) && char.match(/[0-9]/i)) {
                return true;
            }
        }
        return false;
    };

    if (
        values.password &&
        values.password !== "" &&
        values.passwordAgain &&
        values.passwordAgain !== "" &&
        values.password !== values.passwordAgain
    ) {
        errors.password = "A beírt jelszavaknak meg kell egyeznie!";
        errors.passwordAgain = "A beírt jelszavaknak meg kell egyeznie!";
    }

    if (!values.password || values.password === "") {
        errors.password = "A jelszót kötelező kitölteni!";
    } else if (values.password.length < 8) {
        errors.password = "A jelszó nem lehet rövidebb 8 karakternél!";
    } else if (values.password.length > 30) {
        errors.password = "A jelszó nem lehet hosszabb 30 karakternél!";
    } else if (!checkLowerCase(values.password)) {
        errors.password = "A jelszónak legalább egy kisbetűt kell tartalmazni!";
    } else if (!checkUpperCase(values.password)) {
        errors.password =
            "A jelszónak legalább egy nagybetűt kell tartalmazni!";
    } else if (!checkNumberCase(values.password)) {
        errors.password = "A jelszónak legalább egy számot kell tartalmaznia!";
    }

    if (!values.passwordAgain || values.passwordAgain === "") {
        errors.passwordAgain = "A jelszót kötelező kitölteni!";
    } else if (values.passwordAgain.length < 8) {
        errors.passwordAgain = "A jelszó nem lehet rövidebb 8 karakternél!";
    } else if (values.passwordAgain.length > 40) {
        errors.passwordAgain = "A jelszó nem lehet hosszabb 40 karakternél!";
    } else if (!checkLowerCase(values.passwordAgain)) {
        errors.passwordAgain =
            "A jelszónak legalább egy kisbetűt kell tartalmaznia!";
    } else if (!checkUpperCase(values.passwordAgain)) {
        errors.passwordAgain =
            "A jelszónak legalább egy nagybetűt kell tartalmaznia!";
    } else if (!checkNumberCase(values.passwordAgain)) {
        errors.passwordAgain =
            "A jelszónak legalább egy számot kell tartalmaznia!";
    }

    return errors;
};
