import axios from "axios";
import { server } from "../../configs/endpoints";

const API_URL = server;

export const unsubscribe = (newsletterUnsubscribeId: string) => {
    const request = {
        newsletterUnsubscribeId: newsletterUnsubscribeId,
    };
    return axios
        .patch(API_URL + "/user/newsletterunsubscribe", request)
        .then((response) => {
            return response.data;
        });
};
