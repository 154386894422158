export const server =
    process.env.REACT_APP_ACT_SRV_HOST +
    ":" +
    process.env.REACT_APP_ACT_SRV_PORT +
    process.env.REACT_APP_ACT_SRV_CONTEXT;

export const client =
    process.env.REACT_APP_ACT_HOST === "https://kriptomatik.hu"
        ? process.env.REACT_APP_ACT_HOST
        : process.env.REACT_APP_ACT_HOST + ":" + process.env.REACT_APP_ACT_PORT;
