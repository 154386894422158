import axios from "axios";
import { server } from "../../configs/endpoints";
import RegistrationFormValuesType from "../../types/home/registrationFormValuesType";
import { setAuthToken } from "../common/AuthService";

const API_URL = server;

export const getReferralUser = (referralId: string) => {
    return axios
        .get(API_URL + "/user/referral/" + referralId)
        .then((response) => {
            return response.data;
        });
};

export const registration = (formValues: RegistrationFormValuesType) => {
    const request = {
        lastName: formValues.lastName,
        firstName: formValues.firstName,
        country: formValues.country?.code,
        zip: formValues.zip,
        email: formValues.email,
        phone: formValues.country?.phoneCode + formValues.phone,
        password: formValues.password,
        newsletterSubscription: formValues.newsletterSubscription
            ? "SUBSCRIBED"
            : "NOT_SUBSCRIBED",
        language: formValues.language,
        termsAccepted: "20221015_gtc.pdf",
        receivedReferralId: localStorage.getItem("actReceivedReferralId"),
        referredBy: formValues.referredBy,
    };
    return axios
        .post(API_URL + "/user/registration", request)
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("actUser", JSON.stringify(response.data));
                setAuthToken();
            }
            return response.data;
        });
};

export const validation = (validationId: string) => {
    const request = {
        validationId: validationId,
    };
    return axios
        .patch(API_URL + "/user/validation", request)
        .then((response) => {
            return response.data;
        });
};
