import axios from "axios";
import { server } from "../../configs/endpoints";
import ExchangeFormValuesType from "../../types/main/exchangeFormValuesType";

const API_URL = server;

export const testExchangeConnection = (formValues: ExchangeFormValuesType) => {
    const request = {
        platform: "binance",
        apiKey: formValues.apiKey,
        secret: formValues.secret,
        sandboxMode: false, //TODO plug into the form in case of admin privilege
    };
    return axios
        .post(API_URL + "/protected/user/exchange/connectiontest", request)
        .then((response) => {
            return response;
        });
};

export const createExchange = (formValues: ExchangeFormValuesType) => {
    const request = {
        platform: "binance",
        apiKey: formValues.apiKey,
        secret: formValues.secret,
        orderMaxCost: 200, //TODO plug into the form in case of admin privilege
        sandboxMode: false, //TODO plug into the form in case of admin privilege
    };
    return axios
        .post(API_URL + "/protected/user/exchange", request)
        .then((response) => {
            return response;
        });
};

export const readUserExchanges = () => {
    return axios.get(API_URL + "/protected/user/exchanges").then((response) => {
        return response;
    });
};

export const deleteExchange = (apiKey: string) => {
    return axios
        .delete(API_URL + "/protected/user/exchange/" + apiKey)
        .then((response) => {
            return response;
        });
};
