import { configureStore } from "@reduxjs/toolkit";
import coreReducer from "./common/coreSlice";
import userReducer from "./main/userSlice";
import streamReducer from "./main/streamSlice";
import socketMiddleware from "../middlewares/webSocketMiddleware";

export const store = configureStore({
    reducer: {
        core: coreReducer,
        user: userReducer,
        stream: streamReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // immutableCheck: { warnAfter: 128 },
            // serializableCheck: { warnAfter: 128 },
            immutableCheck: false,
            serializableCheck: false,
        }).prepend(socketMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
