import OrdersSummaryType from "../../types/main/transactionsType";

export const initialOrdersSummaryValues: OrdersSummaryType = {
    numberOfOpenOrders: 0,
    openAmount: 0,
    openPurchases: 0,
    currentlyAfterSales: 0,
    currentlyProfits: 0,
    openOrders: [],
    numberOfClosedSlices: 0,
    closedPurchases: 0,
    closedAfterSales: 0,
    closedProfits: 0,
    closedSlices: [],
};
