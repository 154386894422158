import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../states/hooks";
import { RootState } from "../../states/store";
import { StatusCodes } from "http-status-codes";
import {
    setActiveMenuItem,
    setOpenLoadingBackdrop,
    setCloseLoadingBackdrop,
} from "../../states/common/coreSlice";
import OrderType from "../../types/main/orderType";
import TransactionsType from "../../types/main/transactionsType";
import { OrderSide, OrderState } from "../../enums/main/orderEnum";
import { initialOrdersSummaryValues } from "../../datas/main/initialOrdersSummaryValues";
import { readUserExchangesOrders } from "../../services/main/OrdersService";
import {
    setDateToString,
    setFloatThousandSeparator,
} from "../../utils/common/stringFormatUtil";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const Transactions = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userProfile = useAppSelector(
        (state: RootState) => state.user.profile
    );

    const candles = useAppSelector((state: RootState) => state.stream.candles);

    const [transactions, setTransactions] = useState<TransactionsType>(
        initialOrdersSummaryValues
    );

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            background: "#010138",
            color: "#DCC28E",
            fontWeight: 900,
        },
        [`&.${tableCellClasses.body}`]: {
            background: "#010138",
            color: "#eee",
            fontSize: 13,
            whiteSpace: "nowrap",
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    useEffect(() => {
        dispatch(setActiveMenuItem("transactions"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(setActiveMenuItem("transactions"));
        if (userProfile && userProfile.email) {
            dispatch(
                setOpenLoadingBackdrop({
                    message: "tranzakciók frissítése!",
                })
            );
            readUserExchangesOrders().then(
                (response) => {
                    const orders: OrderType[] = response.data;

                    dispatch(setCloseLoadingBackdrop());
                    if (orders && orders.length !== 0) {
                        // save orders to state

                        setTransactions(
                            orders.reduce(
                                (
                                    accumulator: TransactionsType,
                                    currentOrder: OrderType,
                                    index: number
                                ) => {
                                    //initial empty array correction
                                    if (index === 0) {
                                        accumulator.closedSlices = [];
                                        accumulator.openOrders = [];
                                    }

                                    if (
                                        currentOrder.side === OrderSide.BUY &&
                                        currentOrder.state === OrderState.OPEN
                                    ) {
                                        ++accumulator.numberOfOpenOrders;
                                        accumulator.openPurchases +=
                                            currentOrder.cost;
                                        accumulator.openAmount +=
                                            currentOrder.amount;
                                        accumulator.openOrders.push(
                                            currentOrder
                                        );
                                    } else if (
                                        currentOrder.side === OrderSide.SELL &&
                                        currentOrder.pair !== null &&
                                        currentOrder.profit !== null
                                    ) {
                                        ++accumulator.numberOfClosedSlices;
                                        accumulator.closedPurchases +=
                                            currentOrder.pair.cost;
                                        accumulator.closedAfterSales +=
                                            currentOrder.cost;
                                        accumulator.closedProfits +=
                                            currentOrder.profit;
                                        accumulator.closedSlices.push(
                                            currentOrder
                                        );
                                    }
                                    return accumulator;
                                },
                                { ...initialOrdersSummaryValues }
                            )
                        );
                    }
                },
                (error) => {
                    // console.log(error)
                    setTransactions(initialOrdersSummaryValues);
                    dispatch(setCloseLoadingBackdrop());
                    if (error && error.response && error.response.status) {
                        if (
                            error.response.status === StatusCodes.UNAUTHORIZED
                        ) {
                            navigate("../logout");
                        } else {
                            //
                        }
                    }
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    useEffect(() => {
        if (candles && candles.length > 0) {
            const newTransactions = { ...transactions };
            newTransactions.currentlyAfterSales =
                transactions.openAmount * candles[candles.length - 1].close;
            newTransactions.currentlyProfits =
                newTransactions.currentlyAfterSales -
                newTransactions.openPurchases;
            setTransactions(newTransactions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [candles]);
    return (
        <Container
            component="main"
            sx={{
                overflow: "hidden",
                mb: 2,
                pt: 2,
                pb: 2,
                boxShadow: 2,
                borderRadius: 2,
                background: "#0D0D45",
            }}
            maxWidth="md"
        >
            <Typography
                variant="h5"
                component="h1"
                gutterBottom
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    color: "#DCC28E",
                }}
            >
                <CurrencyExchangeIcon
                    sx={{
                        mr: 1,
                        width: 27,
                        height: 27,
                    }}
                />
                Tranzakciók
            </Typography>
            <Grid
                container
                spacing={2}
                sx={{ mb: 2 }}
            >
                <Grid
                    item
                    xs={12}
                    sm={6}
                >
                    <List>
                        <ListItem sx={{ pl: 0 }}>
                            <Typography
                                component="span"
                                sx={{
                                    color: "#00FF80",
                                    width: "18vw",
                                    minWidth: "150px",
                                    fontWeight: 900,
                                }}
                            >
                                Lezárt szeletek
                            </Typography>
                            <Typography component="span">
                                {setFloatThousandSeparator(
                                    transactions.numberOfClosedSlices,
                                    0
                                )}{" "}
                                db
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ pl: 0 }}>
                            <Typography
                                component="span"
                                sx={{
                                    width: "18vw",
                                    minWidth: "150px",
                                    fontWeight: 900,
                                }}
                            >
                                Lezárt befektetés
                            </Typography>
                            <Typography component="span">
                                {setFloatThousandSeparator(
                                    transactions.closedPurchases,
                                    4
                                )}{" "}
                                USDC
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ pl: 0 }}>
                            <Typography
                                component="span"
                                sx={{
                                    width: "18vw",
                                    minWidth: "150px",
                                    fontWeight: 900,
                                }}
                            >
                                Eladás
                            </Typography>
                            <Typography component="span">
                                {setFloatThousandSeparator(
                                    transactions.closedAfterSales,
                                    4
                                )}{" "}
                                USDC
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ pl: 0 }}>
                            <Typography
                                component="span"
                                sx={{
                                    width: "18vw",
                                    minWidth: "150px",
                                    fontWeight: 900,
                                }}
                            >
                                Realizált profit
                            </Typography>
                            <Typography component="span">
                                {setFloatThousandSeparator(
                                    transactions.closedProfits,
                                    4
                                )}{" "}
                                USDC
                            </Typography>
                        </ListItem>
                    </List>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                >
                    <List>
                        <ListItem sx={{ pl: 0 }}>
                            <Typography
                                component="span"
                                sx={{
                                    width: "18vw",
                                    minWidth: "150px",
                                    fontWeight: 900,
                                }}
                            >
                                Nyitott megrendelések
                            </Typography>
                            <Typography component="span">
                                {transactions.numberOfOpenOrders} db
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ pl: 0 }}>
                            <Typography
                                component="span"
                                sx={{
                                    width: "18vw",
                                    minWidth: "150px",
                                    fontWeight: 900,
                                }}
                            >
                                Nyitott befektetés
                            </Typography>
                            <Typography component="span">
                                {setFloatThousandSeparator(
                                    transactions.openPurchases,
                                    4
                                )}{" "}
                                USDC
                            </Typography>
                        </ListItem>
                        {/* <ListItem sx={{ pl: 0 }}>
                            <Typography
                                component="span"
                                sx={{
                                    width: "18vw",
                                    minWidth: "150px",
                                    fontWeight: 900,
                                }}
                            >
                                Becsült ára
                            </Typography>
                            <Typography component="span">
                                {setFloatThousandSeparator(
                                    transactions.currentlyAfterSales,
                                    4
                                )}{" "}
                                USDC (TODO!)
                            </Typography>
                        </ListItem>
                        <ListItem sx={{ pl: 0 }}>
                            <Typography
                                component="span"
                                sx={{
                                    width: "18vw",
                                    minWidth: "150px",
                                    fontWeight: 900,
                                }}
                            >
                                Különbség
                            </Typography>
                            <Typography component="span">
                                {setFloatThousandSeparator(
                                    transactions.currentlyProfits,
                                    4
                                )}{" "}
                                USDC (TODO!)
                            </Typography>
                        </ListItem> */}
                    </List>
                </Grid>
            </Grid>
            <Typography
                variant="h5"
                component="h1"
                gutterBottom
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    color: "#DCC28E",
                }}
            >
                <HourglassTopIcon
                    sx={{
                        mr: 1,
                        width: 27,
                        height: 27,
                    }}
                />
                Nyitott megrendelések
            </Typography>
            <TableContainer
                component={Paper}
                sx={{ background: "#010138" }}
            >
                <Table
                    stickyHeader
                    sx={{ minWidth: 700 }}
                    aria-label="customized table"
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">
                                Időpont
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                Befektetett
                                <br />
                                USDC
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                Kereskedési pár
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                Árfolyam
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                Mennyiség
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                Minimum
                                <br />
                                eladási ár
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.openOrders.map((openOrder) => (
                            <StyledTableRow key={openOrder.id}>
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                >
                                    {setDateToString(openOrder.createdAt)}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {setFloatThousandSeparator(
                                        openOrder.cost,
                                        4
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {openOrder.symbol}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {setFloatThousandSeparator(
                                        openOrder.price,
                                        4
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {setFloatThousandSeparator(
                                        openOrder.amount,
                                        4
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    {setFloatThousandSeparator(
                                        openOrder.minSellingPrice,
                                        4
                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default Transactions;
