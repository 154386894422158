import LoginFormValuesType from "../../types/home/loginFormValuesType";
import LoginFormErrorsType from "../../types/home/loginFormErrorsType";
import { initialLoginFormErrors } from "../../datas/home/initialLoginFormErrors";

export const validateLoginForm = (values: LoginFormValuesType) => {
    const errors: LoginFormErrorsType = {
        ...initialLoginFormErrors,
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    const checkLowerCase = (password: string) => {
        for (let char of password) {
            if (char === char.toLowerCase() && char.match(/[a-z]/i)) {
                return true;
            }
        }
        return false;
    };

    const checkUpperCase = (password: string) => {
        for (let char of password) {
            if (char === char.toUpperCase() && char.match(/[A-Z]/i)) {
                return true;
            }
        }
        return false;
    };

    const checkNumberCase = (password: string) => {
        for (let char of password) {
            if (!isNaN(Number(char)) && char.match(/[0-9]/i)) {
                return true;
            }
        }
        return false;
    };

    if (!values.email || values.email === "") {
        errors.email = "Az e-mail címet kötelező kitölteni bejelentkezéshez!";
    } else if (!emailRegex.test(values.email)) {
        errors.email = "Hibás e-mail cím formátum!";
    }

    if (!values.password || values.password === "") {
        errors.password = "A jelszót kötelező kitölteni bejelentkezéshez!";
    } else if (values.password.length < 8) {
        errors.password = "A jelszó nem lehet rövidebb 8 karakternél!";
    } else if (values.password.length > 30) {
        errors.password = "A jelszó nem lehet hosszabb 30 karakternél!";
    } else if (!checkLowerCase(values.password)) {
        errors.password = "A jelszónak legalább egy kisbetűt kell tartalmazni!";
    } else if (!checkUpperCase(values.password)) {
        errors.password =
            "A jelszónak legalább egy nagybetűt kell tartalmazni!";
    } else if (!checkNumberCase(values.password)) {
        errors.password = "A jelszónak legalább egy számot kell tartalmaznia!";
    }

    return errors;
};
