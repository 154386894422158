export enum ExchangePlatform {
    BINANCE = "binance",
    COINBASE = "coinbase",
    CRYPTOCOM = "cryptocom",
}

export enum ExchangeStatus {
    CONNECTED = "CONNECTED",
    ERROR = "ERROR",
}
