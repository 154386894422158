import { useEffect, useState, ChangeEvent, MouseEvent, FormEvent } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../states/hooks";
import { setActiveMenuItem } from "../../states/common/coreSlice";
import {
    setOpenLoadingBackdrop,
    setCloseLoadingBackdrop,
    setOpenMessageDialog,
} from "../../states/common/coreSlice";
import { StatusCodes } from "http-status-codes";
import { FeedbackSeverityEnum } from "../../enums/common/feedbackSeverityEnum";
import ChangePasswordFormValuesType from "../../types/home/changePasswordFormValuesType";
import ChangePasswordFormErrorsType from "../../types/home/changePasswordFormErrorsType";
import { statusAlertsData } from "../../datas/common/statusAlertsData";
import { initialChangePasswordFormValues } from "../../datas/home/initialChangePasswordFormValues";
import { initialChangePasswordFormErrors } from "../../datas/home/initialChangePasswordFormErrors";
import { validateChangePasswordForm } from "../../utils/home/validateChangePasswordFormUtil";
import {
    getEmailFromChangePasswordId,
    changePassword,
} from "../../services/common/AuthService";
import Zoom from "@mui/material/Zoom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import ChangePasswordBackgroundImage from "../../assets/change-password-bg.jpg";

const ChangePassword = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();
    const changePasswordId = searchParams.get("id");

    useEffect(() => {
        dispatch(setActiveMenuItem("changePassword"));
        if (changePasswordId && changePasswordId !== "") {
            dispatch(
                setOpenLoadingBackdrop({
                    message: "adatok ellenőrzése folyamatban!",
                })
            );
            getEmailFromChangePasswordId(changePasswordId).then(
                (response) => {
                    setFormValues({
                        ...formValues,
                        changePasswordId: changePasswordId,
                        email: response,
                    });
                    dispatch(setCloseLoadingBackdrop());
                },
                (error) => {
                    console.log(error);
                    dispatch(
                        setOpenMessageDialog({
                            severity: FeedbackSeverityEnum.ERROR,
                            title: "Jelszó igénylő link hibás",
                            message:
                                "Sajnos a jelszó igényléshez használt link lejárt. Használja a legfrissebben küldött, még nem felhasznált linket vagy igényeljen újat e-mail címe megadásával.",
                        })
                    );
                    navigate("../getpassword?status=changepasswordlinkexpired");
                    dispatch(setCloseLoadingBackdrop());
                }
            );
        } else {
            dispatch(
                setOpenMessageDialog({
                    severity: statusAlertsData.changepasswordlinkempty.severity,
                    title: statusAlertsData.changepasswordlinkempty.title,
                    message: statusAlertsData.changepasswordlinkempty.message,
                })
            );
            navigate("../getpassword?status=changepasswordlinkempty");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [formValues, setFormValues] = useState<ChangePasswordFormValuesType>(
        initialChangePasswordFormValues
    );
    const [formErrors, setFormErrors] = useState<ChangePasswordFormErrorsType>(
        initialChangePasswordFormErrors
    );
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    useEffect(() => {
        if (isSubmitted) {
            setFormErrors(validateChangePasswordForm(formValues));
        }
    }, [formValues, isSubmitted]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    };

    const handleShowPassword = () => {
        setFormValues({
            ...formValues,
            showPassword: !formValues.showPassword,
        });
    };

    const handleShowPasswordAgain = () => {
        setFormValues({
            ...formValues,
            showPasswordAgain: !formValues.showPasswordAgain,
        });
    };

    const handleMouseDownPasswords = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(
            setOpenLoadingBackdrop({
                message: "jelszó változtatás folyamatban!",
            })
        );
        setIsSubmitted(true);
        const formErrorsObject: ChangePasswordFormErrorsType =
            validateChangePasswordForm(formValues);
        setFormErrors(formErrorsObject);
        if (Object.values(formErrorsObject).every((x) => !x)) {
            changePassword(formValues).then(
                () => {
                    navigate("../login/?status=changedpassword");
                    dispatch(setCloseLoadingBackdrop());
                },
                (error) => {
                    if (error && error.response && error.response.status) {
                        if (error.response.status === StatusCodes.NOT_FOUND) {
                            dispatch(
                                setOpenMessageDialog({
                                    severity:
                                        statusAlertsData
                                            .notfoundusertochangepassword
                                            .severity,
                                    title: statusAlertsData
                                        .notfoundusertochangepassword.title,
                                    message:
                                        statusAlertsData
                                            .notfoundusertochangepassword
                                            .message,
                                })
                            );
                            navigate(
                                "../getpassword?status=notfoundusertochangepassword"
                            );
                            dispatch(setCloseLoadingBackdrop());
                        } else if (
                            error.response.status === StatusCodes.FORBIDDEN
                        ) {
                            dispatch(
                                setOpenMessageDialog({
                                    severity:
                                        statusAlertsData
                                            .changepasswordlinkexpired.severity,
                                    title: statusAlertsData
                                        .changepasswordlinkexpired.title,
                                    message:
                                        statusAlertsData
                                            .changepasswordlinkexpired.message,
                                })
                            );
                            navigate(
                                "../getpassword?status=changepasswordlinkexpired"
                            );
                            dispatch(setCloseLoadingBackdrop());
                        } else if (
                            error.response.status === StatusCodes.NOT_ACCEPTABLE
                        ) {
                            dispatch(
                                setOpenMessageDialog({
                                    severity:
                                        statusAlertsData.pendingchangepassword
                                            .severity,
                                    title: statusAlertsData
                                        .pendingchangepassword.title,
                                    message:
                                        statusAlertsData.pendingchangepassword
                                            .message,
                                })
                            );
                            navigate(
                                "../getpassword?status=pendingchangepassword"
                            );
                            dispatch(setCloseLoadingBackdrop());
                        }
                    }
                }
            );
        } else {
            console.log(formErrors);
            dispatch(
                setOpenMessageDialog({
                    severity: statusAlertsData.changepasswordformerror.severity,
                    title: statusAlertsData.changepasswordformerror.title,
                    message: statusAlertsData.changepasswordformerror.message,
                })
            );
            dispatch(setCloseLoadingBackdrop());
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: `calc(100vh - 130px)`,
                backgroundImage: `url(${ChangePasswordBackgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <CssBaseline />
            <Zoom in={true}>
                <Container
                    component="main"
                    sx={{
                        pt: 2,
                        pb: 2,
                        mt: 8,
                        mb: 2,
                        boxShadow: 2,
                        borderRadius: 2,
                        background: "rgba(255, 255, 255, 0.95)!important",
                        overflow: "hidden",
                        overflowY: "scroll",
                    }}
                    maxWidth="xs"
                >
                    <Box
                        sx={{
                            mt: 0,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOpenIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Jelszó változtatás
                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{ mt: 3 }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        required
                                        variant="outlined"
                                        color={
                                            isSubmitted &&
                                            formErrors.password === null
                                                ? "success"
                                                : undefined
                                        }
                                        error={
                                            isSubmitted &&
                                            formErrors.password !== null
                                        }
                                    >
                                        <InputLabel htmlFor="password">
                                            Jelszó
                                        </InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            required
                                            name="password"
                                            id="password"
                                            type={
                                                formValues.showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            value={formValues.password}
                                            inputProps={{ maxLength: 40 }}
                                            onChange={handleChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPasswords
                                                        }
                                                        edge="end"
                                                    >
                                                        {formValues.showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Jelszó"
                                            autoComplete="new-password"
                                        />
                                        <FormHelperText>
                                            {isSubmitted &&
                                            formErrors.password !== null
                                                ? formErrors.password
                                                : null}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        required
                                        variant="outlined"
                                        color={
                                            isSubmitted &&
                                            formErrors.passwordAgain === null
                                                ? "success"
                                                : undefined
                                        }
                                        error={
                                            isSubmitted &&
                                            formErrors.passwordAgain !== null
                                        }
                                    >
                                        <InputLabel htmlFor="passwordAgain">
                                            Jelszó mégegyszer
                                        </InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            required
                                            name="passwordAgain"
                                            id="passwordAgain"
                                            type={
                                                formValues.showPasswordAgain
                                                    ? "text"
                                                    : "password"
                                            }
                                            value={formValues.passwordAgain}
                                            inputProps={{ maxLength: 40 }}
                                            onChange={handleChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleShowPasswordAgain
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPasswords
                                                        }
                                                        edge="end"
                                                    >
                                                        {formValues.showPasswordAgain ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Jelszó mégegyszer"
                                            autoComplete="new-password"
                                        />
                                        <FormHelperText>
                                            {isSubmitted &&
                                            formErrors.passwordAgain !== null
                                                ? formErrors.passwordAgain
                                                : null}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                startIcon={<LockPersonIcon />}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Mentés
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </Zoom>
        </Box>
    );
};

export default ChangePassword;
