import InitialCoreState, {
    UpdateCoreAction,
} from "../../types/redux/common/coreType";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LanguageEnum } from "../../enums/common/languageEnum";

const initialCoreState: InitialCoreState = {
    activeMenuItem: "",
    activeLanguage: LanguageEnum.HUNGARIAN,
    sidebarDrawer: {
        open: false,
    },
    loadingBackdrop: {
        open: false,
        message: null,
    },
    messageDialog: {
        open: false,
        severity: null,
        title: null,
        message: null,
        navigate: null,
    },
    notificationSnackbar: {
        open: false,
        severity: null,
        title: null,
        message: null,
    },
};

export const coreSlice = createSlice({
    name: UpdateCoreAction,
    initialState: initialCoreState,
    reducers: {
        setActiveMenuItem: (state, action: PayloadAction<string>) => {
            state.activeMenuItem = action.payload;
        },
        setActiveLanguage: (state, action: PayloadAction<LanguageEnum>) => {
            state.activeLanguage = action.payload;
        },
        setOpenSidebarDrawer: (state) => {
            state.sidebarDrawer.open = true;
        },
        setCloseSidebarDrawer: (state) => {
            state.sidebarDrawer.open = false;
        },
        setOpenLoadingBackdrop: (state, action: PayloadAction<any>) => {
            action.payload.open = true;
            state.loadingBackdrop = action.payload;
        },
        setCloseLoadingBackdrop: (state) => {
            state.loadingBackdrop = { ...initialCoreState.loadingBackdrop };
        },
        setOpenMessageDialog: (state, action: PayloadAction<any>) => {
            action.payload.open = true;
            state.messageDialog = action.payload;
        },
        setCloseMessageDialog: (state) => {
            state.messageDialog = { ...initialCoreState.messageDialog };
        },
        setOpenNotificationSnackbar: (state, action: PayloadAction<any>) => {
            action.payload.open = true;
            state.notificationSnackbar = action.payload;
        },
        setCloseNotificationSnackbar: (state) => {
            state.notificationSnackbar = {
                ...initialCoreState.notificationSnackbar,
            };
        },
        setCleanCore: (state) => {
            state.sidebarDrawer = { ...initialCoreState.sidebarDrawer };
            state.loadingBackdrop = { ...initialCoreState.loadingBackdrop };
            state.messageDialog = { ...initialCoreState.messageDialog };
            state.notificationSnackbar = {
                ...initialCoreState.notificationSnackbar,
            };
        },
    },
});

// Action creators are generated for each case reducer function
export const { setActiveMenuItem } = coreSlice.actions;
export const { setActiveLanguage } = coreSlice.actions;
export const { setOpenSidebarDrawer } = coreSlice.actions;
export const { setCloseSidebarDrawer } = coreSlice.actions;
export const { setOpenLoadingBackdrop } = coreSlice.actions;
export const { setCloseLoadingBackdrop } = coreSlice.actions;
export const { setOpenMessageDialog } = coreSlice.actions;
export const { setCloseMessageDialog } = coreSlice.actions;
export const { setOpenNotificationSnackbar } = coreSlice.actions;
export const { setCloseNotificationSnackbar } = coreSlice.actions;
export const { setCleanCore } = coreSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default coreSlice.reducer;
