import { WebSocketConnectionState } from "../../../enums/main/webSocketConnectionStateEnum";
import { CryptocurrencyPairEnum } from "../../../enums/main/cryptocurrencyPairEnum";
import { StreamFrequency } from "../../../enums/main/streamFrequencyEnum";
import candleType from "../../main/candleType";

interface InitialStreamState {
    connectionState: WebSocketConnectionState | null;
    cryptocurrencyPair: CryptocurrencyPairEnum;
    frequency: StreamFrequency;
    numberOfCandles: number;
    candles: candleType[];
}

const UpdateStreamAction: string = "UpdateStreamAction";

export default InitialStreamState;
export { UpdateStreamAction };
