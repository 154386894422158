import ChangePasswordFormValuesType from "../../types/home/changePasswordFormValuesType";

export const initialChangePasswordFormValues: ChangePasswordFormValuesType = {
    changePasswordId: "",
    email: "",
    password: "",
    passwordAgain: "",
    showPassword: false,
    showPasswordAgain: false,
};
