export enum StreamFrequency {
    ONE_SECOND = "1s",
    ONE_MINUTE = "1m",
    THREE_MINUTES = "3m",
    FIVE_MINUTES = "5m",
    FIFTEEN_MINUTES = "15m",
    THIRTY_MINUTES = "30m",
    ONE_HOUR = "1h",
    TWO_HOURS = "2h",
    FOUR_HOURS = "4h",
    SIX_HOURS = "6h",
    EIGHT_HOURS = "8h",
    TWELVE_HOURS = "12h",
    ONE_DAY = "1d",
    THREE_DAYS = "3d",
    ONE_WEEK = "1w",
    ONE_MONTH = "1M",
}

// https://binance-docs.github.io/apidocs/spot/en/#kline-candlestick-streams
// Kline/Candlestick chart intervals:

// s-> seconds; m -> minutes; h -> hours; d -> days; w -> weeks; M -> months

// 1s
// 1m
// 3m
// 5m
// 15m
// 30m
// 1h
// 2h
// 4h
// 6h
// 8h
// 12h
// 1d
// 3d
// 1w
// 1M
