import { useEffect, useState } from "react";
import { useAppSelector } from "../../states/hooks";
import { RootState } from "../../states/store";
import { setThousandSeparator } from "../../utils/common/stringFormatUtil";
import ReactApexChart from "react-apexcharts";
import apexchart, { ApexOptions } from "apexcharts";
import pink from "@mui/material/colors/pink";

const CandlestickChart = () => {
    const userProfile = useAppSelector(
        (state: RootState) => state.user.profile
    );
    const candles = useAppSelector((state: RootState) => state.stream.candles);

    const hu = require("apexcharts/dist/locales/hu.json");
    const en = require("apexcharts/dist/locales/en.json");

    const [candlestickChartSeries, setCandlestickChartSeries] = useState<any>([
        {
            data: [],
        },
    ]);

    const [candlestickChartOptions, setCandlestickChartOptions] =
        useState<ApexOptions>({
            chart: {
                id: "candlestick-chart",
                type: "candlestick",
                foreColor: "#eee",
                background: "#010138",
                animations: {
                    enabled: false,
                },
                // locales: [hu, en],
                // defaultLocale: "hu",
                dropShadow: {
                    enabled: false,
                    enabledOnSeries: undefined,
                },
                toolbar: {
                    show: false,
                    // tools: {
                    //     download: false,
                    //     selection: false,
                    //     zoom: true,
                    //     zoomin: true,
                    //     zoomout: true,
                    //     pan: false,
                    //     reset: false,
                    // },
                    // autoSelected: "zoom",
                },
            },
            tooltip: {
                enabled: false,
                // x: {
                //     show: true,
                //     format: "dd MMM",
                //     formatter: undefined,
                // },
                // y: {
                //     formatter: undefined,
                //     title: {
                //         formatter: (seriesName) => seriesName,
                //     },
                // },
            },
            stroke: {
                width: 1,
                curve: "smooth",
            },
            plotOptions: {
                candlestick: {
                    colors: {
                        upward: "#00FF80",
                        downward: pink[400],
                    },
                },
            },
            xaxis: {
                type: "datetime",
                tooltip: {
                    enabled: false,
                },
            },
            yaxis: {
                decimalsInFloat: 4,
                // Math.round(Number(value))
                labels: {
                    formatter: (value) => {
                        return setThousandSeparator(Number(value)).toString();
                    },
                },
                tooltip: {
                    enabled: false,
                },
            },
        });

    useEffect(() => {
        if (userProfile && userProfile.language) {
            const newCandlestickChartOptions: ApexOptions = {
                ...candlestickChartOptions,
            };
            if (
                newCandlestickChartOptions &&
                newCandlestickChartOptions.chart
            ) {
                if (userProfile.language === "HU") {
                    newCandlestickChartOptions.chart.locales = [hu];
                    newCandlestickChartOptions.chart.defaultLocale = "hu";
                    setCandlestickChartOptions(newCandlestickChartOptions);
                    apexchart.exec(
                        "candlestick-chart",
                        "updateOptions",
                        newCandlestickChartOptions
                    );
                } else {
                    newCandlestickChartOptions.chart.locales = [en];
                    newCandlestickChartOptions.chart.defaultLocale = "en";
                    setCandlestickChartOptions(newCandlestickChartOptions);
                    apexchart.exec(
                        "candlestick-chart",
                        "updateOptions",
                        newCandlestickChartOptions
                    );
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    const getNewCandlestickChartSeries = () => {
        return [
            {
                data: candles.map((candle) => {
                    return {
                        x: new Date(candle.timestamp),
                        y: [candle.open, candle.high, candle.low, candle.close],
                    };
                }),
            },
        ];
    };

    useEffect(() => {
        setCandlestickChartSeries(getNewCandlestickChartSeries());
        apexchart.exec(
            "candlestick-chart",
            "updateSeries",
            candlestickChartSeries
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [candles]);

    return (
        <ReactApexChart
            options={candlestickChartOptions}
            series={candlestickChartSeries}
            type="candlestick"
            width="100%"
            height={350}
        />
    );
};

export default CandlestickChart;
