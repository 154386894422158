import InitialUserState, {
    UpdateUserAction,
} from "../../types/redux/main/userType";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialUserState: InitialUserState = {
    profile: {
        referralId: null,
        role: null,
        lastName: null,
        firstName: null,
        email: null,
        phone: null,
        country: null,
        zip: null,
        language: null,
        newsletterSubscription: null,
        reportPerOrder: null,
        reportDaily: null,
        reportWeekly: null,
        reportMonthly: null,
        referredBy: null,
    },
};

export const userSlice = createSlice({
    name: UpdateUserAction,
    initialState: initialUserState,
    reducers: {
        setUserProfile: (state, action: PayloadAction<any>) => {
            state.profile = action.payload;
        },
        setUserProfileLanguage: (state, action: PayloadAction<any>) => {
            state.profile.language = action.payload;
        },
        setCleanUser: (state) => {
            state.profile = { ...initialUserState.profile };
        },
    },
});

// Action creators are generated for each case reducer function
export const { setUserProfile } = userSlice.actions;
export const { setUserProfileLanguage } = userSlice.actions;
export const { setCleanUser } = userSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default userSlice.reducer;
