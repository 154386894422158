export enum UserCountry {
    HU = "HU",
}

// export enum UserLanguage {
//     HUNGARIAN = "HU",
//     ENGLISH = "GB",
// }

export enum UserRole {
    ADMIN = "ADMIN",
    VIEWER = "VIEWER",
    VALIDATOR = "VALIDATOR",
    USER = "USER",
}

export enum UserAccountStatus {
    PENDING = "PENDING",
    VALIDATED = "VALIDATED",
    DELETED = "DELETED",
}

export enum UserNewsletterSubscription {
    NOT_SUBSCRIBED = "NOT_SUBSCRIBED",
    SUBSCRIBED = "SUBSCRIBED",
    UNSUBSCRIBED = "UNSUBSCRIBED",
}
