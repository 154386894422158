import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../states/hooks";
import { RootState } from "../../states/store";
import {
    setActiveMenuItem,
    setOpenLoadingBackdrop,
    setCloseLoadingBackdrop,
} from "../../states/common/coreSlice";
import { readUsers } from "../../services/main/UserService";
import { StatusCodes } from "http-status-codes";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

const Users = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userProfile = useAppSelector(
        (state: RootState) => state.user.profile
    );

    const [users, setUsers] = useState<any[]>([]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            background: "#010138",
            color: "#DCC28E",
            fontWeight: 900,
        },
        [`&.${tableCellClasses.body}`]: {
            background: "#010138",
            color: "#eee",
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    useEffect(() => {
        dispatch(setActiveMenuItem("admin/users"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(setActiveMenuItem("admin/users"));
        if (userProfile && userProfile.email) {
            dispatch(
                setOpenLoadingBackdrop({
                    message: "felhasználók betöltése!",
                })
            );
            readUsers().then(
                (response) => {
                    dispatch(setCloseLoadingBackdrop());
                    setUsers(response.data);
                },
                (error) => {
                    // console.log(error)
                    dispatch(setCloseLoadingBackdrop());
                    if (error && error.response && error.response.status) {
                        if (
                            error.response.status === StatusCodes.UNAUTHORIZED
                        ) {
                            navigate("../logout");
                        } else {
                            //
                        }
                    }
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    return (
        <Container
            component="main"
            sx={{
                overflow: "hidden",
                mb: 2,
                pt: 2,
                pb: 2,
                boxShadow: 2,
                borderRadius: 2,
                background: "#0D0D45",
            }}
            maxWidth="md"
        >
            <Typography
                variant="h5"
                component="h1"
                gutterBottom
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    color: "#DCC28E",
                }}
            >
                <PeopleAltIcon
                    sx={{
                        mr: 1,
                        width: 27,
                        height: 27,
                    }}
                />
                Felhasználók
            </Typography>
            <TableContainer component={Paper} sx={{ background: "#010138" }}>
                <Table
                    stickyHeader
                    sx={{ minWidth: 700 }}
                    aria-label="customized table"
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Vezetéknév</StyledTableCell>
                            <StyledTableCell>Keresztnév</StyledTableCell>
                            <StyledTableCell>E-mail cím</StyledTableCell>
                            <StyledTableCell align="center">
                                Jogosultsági kör
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                Fiók státusz
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <StyledTableRow key={user.id}>
                                <StyledTableCell component="th" scope="row">
                                    {user.lastName}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {user.firstName}
                                </StyledTableCell>
                                <StyledTableCell>{user.email}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {user.role}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {user.accountStatus}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default Users;
