export enum CryptocurrencyPairEnum {
    BTCUSDC = "btcusdc",
    ETHUSDC = "ethusdc",
    BNBUSDC = "bnbusdc",
    XRPUSDC = "xrpusdc",
    SOLUSDC = "solusdc",
    ARBUSDC = "arbusdc",
    POLUSDC = "polusdc",
    DOGEUSDC = "dogeusdc",
    // PEPEUSDC = "pepeusdc",
    // SHIBUSDC = "shibusdc",
    RUNEUSDC = "runeusdc",
    MEMEUSDT = "memeusdt",
    IDUSDT = "idusdt",
}
