import axios from "axios";
import { server } from "../../configs/endpoints";
import { UserNewsletterSubscription } from "../../enums/main/userEnum";

const API_URL = server;

export const updateUserNewsletterSubscription = (
    newsletterSubscription: UserNewsletterSubscription
) => {
    const request = {
        newsletterSubscription: newsletterSubscription,
    };
    return axios
        .patch(
            API_URL + "/protected/user/changenewslettersubscription",
            request
        )
        .then((response) => {
            return response.data;
        });
};
