export const capitalizeFirstLetters = (text: string) => {
    if (text && text !== "") {
        const wordsBySpace = replaceAccentCharacters(text.toLowerCase()).split(
            " "
        );
        text = wordsBySpace
            .map((word) => {
                return (
                    (word[0] ? word[0].toUpperCase() : "") + word.substring(1)
                );
            })
            .join(" ");
        const wordsByHyphen = text.split("-");
        text = wordsByHyphen
            .map((word) => {
                return (
                    (word[0] ? word[0].toUpperCase() : "") + word.substring(1)
                );
            })
            .join(" ");
    }
    return text;
};

export const replaceAccentCharacters = (text: string) => {
    if (text && text !== "") {
        const find = [
            "à",
            "â",
            "ä",
            "ã",
            "å",
            "ā",
            "À",
            "Â",
            "Ä",
            "Ã",
            "Å",
            "Ā",
            "è",
            "ê",
            "ë",
            "ė",
            "ē",
            "È",
            "Ê",
            "Ë",
            "Ė",
            "Ē",
            "î",
            "ï",
            "ì",
            "ī",
            "Î",
            "Ï",
            "Ì",
            "Ī",
            "û",
            "ù",
            "ū",
            "Û",
            "Ù",
            "Ū",
            "ô",
            "ò",
            "õ",
            "ō",
            "Ô",
            "Ò",
            "Õ",
            "Ō",
        ];
        const replace = [
            "á",
            "á",
            "á",
            "á",
            "á",
            "á",
            "Á",
            "Á",
            "Á",
            "Á",
            "Á",
            "Á",
            "é",
            "é",
            "é",
            "é",
            "é",
            "É",
            "É",
            "É",
            "É",
            "É",
            "í",
            "í",
            "í",
            "í",
            "Í",
            "Í",
            "Í",
            "Í",
            "ü",
            "ú",
            "ü",
            "Ü",
            "Ú",
            "Ü",
            "ö",
            "ó",
            "ö",
            "ö",
            "Ö",
            "Ó",
            "Ö",
            "Ö",
        ];
        for (let i = 0; i < find.length; i++) {
            text = text.replace(find[i], replace[i]);
        }
        text = text.trim();
    }
    return text;
};

export const formatHungarianPhoneNumber = (text: string | null) => {
    if (text && text !== "") {
        return (
            "+" +
            text.replace(
                /\D*(\d{2})\D*(\d{2})\D*(\d{3})\D*(\d{4})\D*/,
                "$1 $2 $3 $4"
            )
        );
    }
    return text;
};

// TODO en-EN
export const setThousandSeparator = (number: number) => {
    if (number && Number(number)) {
        return Number(number).toLocaleString("hu-HU");
    }
    return number;
};

// TODO en-EN
export const setFloatThousandSeparator = (
    floatNumber: number,
    precision: number
) => {
    const parts = floatNumber.toFixed(precision).split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return (
        numberPart.replace(thousands, " ") +
        (decimalPart ? "," + decimalPart : "")
    );
};

// TODO en-EN
export const setDateToString = (date: Date) => {
    return date
        .toString()
        .split(".")[0]
        .replaceAll("-", ".")
        .replace("T", ". ");
};
