import axios from "axios";
import { server } from "../../configs/endpoints";

const API_URL = server;

export const readUsers = () => {
    return axios.get(API_URL + "/protected/admin/users").then((response) => {
        return response;
    });
};
