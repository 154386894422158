import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAppSelector } from "../states/hooks";
import { RootState } from "../states/store";
import { getCurrentUser } from "../services/common/AuthService";
import { UserRole } from "../enums/main/userEnum";
import NotFound from "../pages/common/NotFound";

const ProtectedAdminRoute: React.FC = () => {
    const user = getCurrentUser();

    const userProfile = useAppSelector(
        (state: RootState) => state.user.profile
    );

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    if (
        userProfile &&
        userProfile.role &&
        userProfile.role !== UserRole.ADMIN
    ) {
        return <NotFound />;
    }

    return <Outlet />;
};

export default ProtectedAdminRoute;
