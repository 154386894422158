import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../states/hooks";
import { RootState } from "../../../states/store";
import {
    setCloseSidebarDrawer,
    setActiveMenuItem,
} from "../../../states/common/coreSlice";
import { UserRole } from "../../../enums/main/userEnum";
import { sidebarDrawerStyles } from "./sidebarDrawerStyles";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import LogoutIcon from "@mui/icons-material/Logout";
import actLogoImage from "../../../assets/act-logo.png";

const sidebarDrawerWidth = 240;

const sidebarDrawerMenuItems = [
    {
        id: 0,
        role: [UserRole.USER, UserRole.ADMIN],
        icon: <AutoGraphIcon />,
        label: "Dashboard",
        route: "dashboard",
    },
    {
        id: 1,
        role: [UserRole.ADMIN],
        icon: <PeopleAltIcon />,
        label: "Felhasználók",
        route: "admin/users",
    },
    {
        id: 2,
        role: [UserRole.USER, UserRole.ADMIN],
        icon: <CurrencyExchangeIcon />,
        label: "Tranzakciók",
        route: "transactions",
    },
    {
        id: 3,
        role: [UserRole.USER, UserRole.ADMIN],
        icon: <Diversity1Icon />,
        label: "Affiliate",
        route: "affiliate",
    },
    {
        id: 4,
        role: [UserRole.USER, UserRole.ADMIN],
        icon: <LogoutIcon />,
        label: "Kilépés",
        route: "logout",
    },
];

const openedMixin = (theme: Theme): CSSObject => ({
    width: sidebarDrawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("xs")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const SidebarDrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: sidebarDrawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const SidebarDrawer = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const userProfile = useAppSelector(
        (state: RootState) => state.user.profile
    );
    const sidebarDrawerOpen: boolean = useAppSelector(
        (state: RootState) => state.core.sidebarDrawer.open
    );
    const activeMenuItem: string = useAppSelector(
        (state: RootState) => state.core.activeMenuItem
    );

    const handleCloseSidebarDrawer = () => {
        dispatch(setCloseSidebarDrawer());
    };

    const handleListItemClick = (route: string) => {
        handleCloseSidebarDrawer();
        dispatch(setActiveMenuItem(route));
        navigate(route);
    };

    return (
        <Drawer
            sx={sidebarDrawerStyles.drawer}
            variant="permanent"
            open={sidebarDrawerOpen}
        >
            <SidebarDrawerHeader sx={{ minHeight: "64px" }}>
                <Box
                    onClick={() => handleListItemClick("dashboard")}
                    sx={{
                        flexGrow: 0,
                        display: "flex",
                        mr: 4,
                    }}
                >
                    <img height="50" src={actLogoImage} alt="ACT logo" />
                </Box>
                <IconButton
                    sx={sidebarDrawerStyles.icon}
                    onClick={handleCloseSidebarDrawer}
                >
                    <ChevronLeftIcon />
                </IconButton>
            </SidebarDrawerHeader>
            <Divider />
            <List>
                {sidebarDrawerMenuItems.map((item) => (
                    <Fragment key={"sidebar-menu-fragment-" + item.id}>
                        {userProfile.role &&
                        item.role.includes(userProfile.role) ? (
                            <ListItem
                                button
                                key={item.id}
                                selected={activeMenuItem === item.route}
                                onClick={() => handleListItemClick(item.route)}
                            >
                                <ListItemIcon
                                    sx={
                                        activeMenuItem === item.route
                                            ? sidebarDrawerStyles.iconSelected
                                            : sidebarDrawerStyles.icon
                                    }
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    sx={sidebarDrawerStyles.text}
                                    primary={item.label}
                                />
                            </ListItem>
                        ) : null}
                    </Fragment>
                ))}
            </List>
            <Divider />
        </Drawer>
    );
};

export default SidebarDrawer;
