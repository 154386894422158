import { useEffect } from "react";
import { useNavigate } from "react-router";
import { logout } from "../../services/common/AuthService";
import { useAppDispatch } from "../../states/hooks";
import { setCleanCore } from "../../states/common/coreSlice";
import { setCleanUser } from "../../states/main/userSlice";
import { setCleanStream } from "../../states/main/streamSlice";

const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    //csak egyszer
    useEffect(() => {
        logout();
        dispatch(setCleanCore());
        dispatch(setCleanUser());
        dispatch(setCleanStream());
        navigate("/login");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
};

export default Logout;
