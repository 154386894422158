import CryptocurrencyPairType from "../../types/main/cryptocurrencyPairType";
import { CryptocurrencyPairEnum } from "../../enums/main/cryptocurrencyPairEnum";
import {
    Binance,
    Btc,
    Eth,
    Xrp,
    Doge,
    Solana,
    Arb,
    Matic,
} from "react-web3-icons";

// https://react-web3-icons-mu.vercel.app

export const cryptocurrencyPairsData: readonly CryptocurrencyPairType[] = [
    {
        code: CryptocurrencyPairEnum.BTCUSDC,
        label: "BTC/USDC",
        icon: <Btc />,
    },
    {
        code: CryptocurrencyPairEnum.ETHUSDC,
        label: "ETH/USDC",
        icon: <Eth />,
    },
    {
        code: CryptocurrencyPairEnum.BNBUSDC,
        label: "BNB/USDC",
        icon: <Binance />,
    },
    {
        code: CryptocurrencyPairEnum.XRPUSDC,
        label: "XRP/USDC",
        icon: <Xrp />,
    },
    {
        code: CryptocurrencyPairEnum.SOLUSDC,
        label: "SOL/USDC",
        icon: <Solana />,
    },
    {
        code: CryptocurrencyPairEnum.ARBUSDC,
        label: "ARB/USDC",
        icon: <Arb />,
    },
    {
        code: CryptocurrencyPairEnum.POLUSDC,
        label: "POL/USDC",
        icon: <Matic />,
    },
    {
        code: CryptocurrencyPairEnum.DOGEUSDC,
        label: "DOGE/USDC",
        icon: <Doge />,
    },
    // {
    //     code: CryptocurrencyPairEnum.SHIBUSDC,
    //     label: "SHIB/USDC",
    //     icon: <Shib />,
    // },
    // {
    //     code: CryptocurrencyPairEnum.PEPEUSDC,
    //     label: "PEPE/USDC",
    //     icon: <Binance />,
    // },
    {
        code: CryptocurrencyPairEnum.RUNEUSDC,
        label: "RUNE/USDC",
        icon: <Binance />,
    },
    {
        code: CryptocurrencyPairEnum.MEMEUSDT,
        label: "MEME/USDT",
        icon: <Binance />,
    },
    {
        code: CryptocurrencyPairEnum.IDUSDT,
        label: "ID/USDT",
        icon: <Binance />,
    },
];
