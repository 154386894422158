import axios from "axios";
import { server } from "../../configs/endpoints";

const API_URL = server;

export const readUserExchangesOrders = () => {
    return axios
        .get(API_URL + "/protected/user/exchanges/orders")
        .then((response) => {
            return response;
        });
};
