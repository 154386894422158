import { useEffect } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../states/hooks";
import { setCleanCore } from "../../states/common/coreSlice";
import { setCleanUser } from "../../states/main/userSlice";
import { setCleanStream } from "../../states/main/streamSlice";
import NavMenuAppBar from "../../components/home/NavMenuAppBar/NavMenuAppBar";
import Welcome from "./Welcome";
import Registration from "./Registration";
import Validation from "./Validation";
import Login from "./Login";
import GetPassword from "./GetPassword";
import ChangePassword from "./ChangePassword";
// import Term20221015 from "./terms/Term20221015";
import Footer from "../../components/common/Footer";
import NotFound from "../common/NotFound";
import NewsletterUnsubscribe from "./NewsletterUnsubscribe";

const Home = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const receivedReferralId = searchParams.get("referral");

    useEffect(() => {
        dispatch(setCleanCore());
        dispatch(setCleanUser());
        dispatch(setCleanStream());
        if (receivedReferralId && receivedReferralId !== "") {
            localStorage.setItem("actReceivedReferralId", receivedReferralId);
            navigate("/registration");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <NavMenuAppBar />
            <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="registration" element={<Registration />} />
                <Route path="validation" element={<Validation />} />
                <Route path="login" element={<Login />} />
                <Route path="getpassword" element={<GetPassword />} />
                <Route path="changepassword" element={<ChangePassword />} />
                <Route
                    path="newsletterunsubscribe"
                    element={<NewsletterUnsubscribe />}
                />
                {/* <Route
                    path="20221015_general_terms_and_conditions"
                    element={<Term20221015 />}
                /> */}
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
        </>
    );
};

export default Home;
