import { LanguageEnum } from "../../../enums/common/languageEnum";
import { FeedbackSeverityEnum } from "../../../enums/common/feedbackSeverityEnum";

interface InitialCoreState {
    activeMenuItem: string;
    activeLanguage: LanguageEnum;
    sidebarDrawer: {
        open: boolean;
    };
    loadingBackdrop: {
        open: boolean;
        message: string | null;
    };
    messageDialog: {
        open: boolean;
        severity: FeedbackSeverityEnum | null;
        title: string | null;
        message: string | null;
        navigate: string | null;
    };
    notificationSnackbar: {
        open: boolean;
        severity: FeedbackSeverityEnum | null;
        title: string | null;
        message: string | null;
    };
}

const UpdateCoreAction: string = "UpdateCoreAction";

export default InitialCoreState;
export { UpdateCoreAction };
