import { useEffect, useState } from "react";
import { statusAlertsData } from "../../datas/common/statusAlertsData";
import StatusAlertType from "../../types/common/statusAlertType.d";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const StatusAlert = (props: { status: string }) => {
    const [loginStatusAlert, setLoginStatusAlert] =
        useState<StatusAlertType | null>(null);

    useEffect(() => {
        if (props && props.status) {
            type StatusAlertsLiteralType = keyof typeof statusAlertsData;
            let key: StatusAlertsLiteralType;

            for (key in statusAlertsData) {
                if (key === props.status) {
                    setLoginStatusAlert(statusAlertsData[key]);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {loginStatusAlert &&
            loginStatusAlert.severity &&
            loginStatusAlert.title &&
            loginStatusAlert.message ? (
                <>
                    <Alert
                        severity={loginStatusAlert.severity}
                        sx={{
                            borderRadius: 2,
                            border: 1,
                            borderColor: "#01003a",
                            marginBottom: 2,
                        }}
                    >
                        <AlertTitle>{loginStatusAlert.title}</AlertTitle>
                        {loginStatusAlert.message}
                    </Alert>
                </>
            ) : null}
        </>
    );
};

export default StatusAlert;
