import { useAppDispatch } from "../../states/hooks";
import { setActiveMenuItem } from "../../states/common/coreSlice";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import NotFoundBackgroundImage from "../../assets/not-found-bg.jpg";

const NotFound = () => {
    const dispatch = useAppDispatch();
    dispatch(setActiveMenuItem("notfound"));

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: `calc(100vh - 130px)`,
                backgroundImage: `url(${NotFoundBackgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                alignItems: "center",
            }}
        >
            <CssBaseline />
            <Container
                sx={{
                    mt: 8,
                    ml: 2,
                    mr: 2,
                    p: 2,
                    boxShadow: 2,
                    border: 1,
                    borderRadius: 2,
                    borderColor: "#eee",
                    background: "rgba(255, 255, 255, 0.3)!important",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    verflow: "hidden",
                    overflowY: "scroll",
                }}
                maxWidth="sm"
            >
                <Typography
                    align="center"
                    component="h1"
                    variant="h5"
                    sx={{ color: "#eee" }}
                >
                    404
                    <br />
                    Az oldal nem található
                </Typography>
            </Container>
        </Box>
    );
};

export default NotFound;
