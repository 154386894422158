import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../states/hooks";
import { RootState } from "../../../states/store";
import {
    setOpenSidebarDrawer,
    setActiveMenuItem,
} from "../../../states/common/coreSlice";
import CryptocurrencyPriceMenu from "./CryptocurrencyPriceMenu";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import pink from "@mui/material/colors/pink";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

const sidebarDrawerWidth = 240;

const navBarAppBarMenuItems = [
    {
        id: 0,
        icon: <AccountBoxIcon />,
        label: "Profil",
        route: "profile",
    },
    {
        id: 1,
        icon: <SettingsIcon />,
        label: "Kriptovaluta tőzsde",
        route: "exchanges",
    },
    {
        id: 2,
        icon: <LogoutIcon />,
        label: "Kilépés",
        route: "logout",
    },
];

interface NavbarAppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<NavbarAppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: sidebarDrawerWidth,
        width: `calc(100% - ${sidebarDrawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const NavbarAppBar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const sidebarDrawerOpen: boolean = useAppSelector(
        (state: RootState) => state.core.sidebarDrawer.open
    );
    const activeMenuItem: string = useAppSelector(
        (state: RootState) => state.core.activeMenuItem
    );

    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleSidebarOpen = () => {
        dispatch(setOpenSidebarDrawer());
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleListItemClick = (route: string) => {
        handleCloseUserMenu();
        dispatch(setActiveMenuItem(route));
        navigate(route);
    };

    return (
        <AppBar
            position="fixed"
            open={sidebarDrawerOpen}
            sx={{ displayPrint: "none" }}
        >
            <Toolbar
                disableGutters
                sx={{ minHeight: "64px", pl: "24px", pr: "24px" }}
            >
                <Tooltip title="Menü megnyitása">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleSidebarOpen}
                        edge="start"
                        sx={{
                            mr: 5,
                            color: "rgba(255, 255, 255, 0.7)!important",
                            ...(sidebarDrawerOpen && { display: "none" }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Tooltip>
                <CryptocurrencyPriceMenu />
                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Beállítások megnyitása">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar sx={{ bgcolor: pink[400] }}>
                                <AccountCircleIcon />
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {navBarAppBarMenuItems.map((item) => (
                            <MenuItem
                                key={item.id}
                                selected={activeMenuItem === item.route}
                                onClick={() => handleListItemClick(item.route)}
                                sx={{
                                    color:
                                        activeMenuItem === item.route
                                            ? pink[400]
                                            : "inherit",
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        color: "inherit",
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText>{item.label}</ListItemText>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
                {/* TODO 2023.05.17. will be switched back when there is localization */}
                {/* <LanguageMenu /> */}
            </Toolbar>
        </AppBar>
    );
};

export default NavbarAppBar;
