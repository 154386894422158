import RegistrationFormValuesType from "../../types/home/registrationFormValuesType";
import CountryType from "../../types/common/countryType.d";
import { LanguageEnum } from "../../enums/common/languageEnum";

const countryHungary: CountryType = {
    code: "HU",
    label: "Magyarország",
    phoneCode: "36",
};

export const initialRegistrationFormValues: RegistrationFormValuesType = {
    lastName: "",
    firstName: "",
    country: countryHungary,
    zip: "",
    email: "",
    phone: "",
    password: "",
    passwordAgain: "",
    showPassword: false,
    showPasswordAgain: false,
    newsletterSubscription: true,
    language: LanguageEnum.HUNGARIAN,
    termsAccepted: false,
    referredBy: null,
};
