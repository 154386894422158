import { useEffect, useState, ChangeEvent, MouseEvent, FormEvent } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../states/hooks";
import {
    setActiveMenuItem,
    setOpenLoadingBackdrop,
    setCloseLoadingBackdrop,
    setOpenMessageDialog,
} from "../../states/common/coreSlice";
import { StatusCodes } from "http-status-codes";
import LoginFormValuesType from "../../types/home/loginFormValuesType";
import LoginFormErrorsType from "../../types/home/loginFormErrorsType";
import { statusAlertsData } from "../../datas/common/statusAlertsData";
import { initialLoginFormValues } from "../../datas/home/initialLoginFormValues";
import { initialLoginFormErrors } from "../../datas/home/initialLoginFormErrors";
import { validateLoginForm } from "../../utils/home/validateLoginFormUtil";
import { login } from "../../services/common/AuthService";
import StatusAlert from "../../components/home/StatusAlert";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Zoom from "@mui/material/Zoom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LoginIcon from "@mui/icons-material/Login";
import LoginBackgroundImage from "../../assets/login-bg.jpg";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setActiveMenuItem("login"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState<string | null>(
        searchParams.get("status")
    );

    const [formValues, setFormValues] = useState<LoginFormValuesType>(
        initialLoginFormValues
    );
    const [formErrors, setFormErrors] = useState<LoginFormErrorsType>(
        initialLoginFormErrors
    );
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    useEffect(() => {
        if (isSubmitted) {
            setFormErrors(validateLoginForm(formValues));
        }
    }, [formValues, isSubmitted]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    };

    const handleShowPassword = () => {
        setFormValues({
            ...formValues,
            showPassword: !formValues.showPassword,
        });
    };

    const handleMouseDownPasswords = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(
            setOpenLoadingBackdrop({
                message: "bejelentkezés folyamatban!",
            })
        );
        setIsSubmitted(true);
        const formErrorsObject: LoginFormErrorsType =
            validateLoginForm(formValues);
        setFormErrors(formErrorsObject);
        if (Object.values(formErrorsObject).every((x) => !x)) {
            login(formValues).then(
                () => {
                    navigate("/main/dashboard");
                    dispatch(setCloseLoadingBackdrop());
                },
                (error) => {
                    // console.log(error);
                    if (error && error.response && error.response.status) {
                        if (
                            error.response.status === StatusCodes.UNAUTHORIZED
                        ) {
                            dispatch(
                                setOpenMessageDialog({
                                    severity:
                                        statusAlertsData.loginunauthorized
                                            .severity,
                                    title: statusAlertsData.loginunauthorized
                                        .title,
                                    message:
                                        statusAlertsData.loginunauthorized
                                            .message,
                                })
                            );
                            setStatus("loginunauthorized");
                            dispatch(setCloseLoadingBackdrop());
                        } else if (
                            error.response.status ===
                            StatusCodes.METHOD_NOT_ALLOWED
                        ) {
                            dispatch(
                                setOpenMessageDialog({
                                    severity:
                                        statusAlertsData.pendinglogin.severity,
                                    title: statusAlertsData.pendinglogin.title,
                                    message:
                                        statusAlertsData.pendinglogin.message,
                                })
                            );
                            setStatus("pendinglogin");
                            dispatch(setCloseLoadingBackdrop());
                        } else {
                            dispatch(
                                setOpenMessageDialog({
                                    severity:
                                        statusAlertsData.loginerror.severity,
                                    title: statusAlertsData.loginerror.title,
                                    message:
                                        statusAlertsData.loginerror.message,
                                })
                            );
                        }
                        dispatch(setCloseLoadingBackdrop());
                    }
                }
            );
        } else {
            dispatch(
                setOpenMessageDialog({
                    severity: statusAlertsData.loginformerror.severity,
                    title: statusAlertsData.loginformerror.title,
                    message: statusAlertsData.loginformerror.message,
                })
            );
            dispatch(setCloseLoadingBackdrop());
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: `calc(100vh - 130px)`,
                backgroundImage: `url(${LoginBackgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <CssBaseline />
            <Zoom in={true}>
                <Container
                    component="main"
                    sx={{
                        pt: 2,
                        pb: 2,
                        mt: 8,
                        mb: 2,
                        boxShadow: 2,
                        borderRadius: 2,
                        background: "rgba(255, 255, 255, 0.95)!important",
                        overflow: "hidden",
                        overflowY: "scroll",
                    }}
                    maxWidth="xs"
                >
                    <Box
                        sx={{
                            mt: 0,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <>{status ? <StatusAlert status={status} /> : null}</>
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Bejelentkezés
                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{ mt: 3 }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        required
                                        name="email"
                                        id="email"
                                        label="E-mail cím"
                                        autoComplete="email"
                                        value={formValues.email}
                                        onChange={handleChange}
                                        color={
                                            isSubmitted &&
                                            formErrors.email === null
                                                ? "success"
                                                : undefined
                                        }
                                        error={
                                            isSubmitted &&
                                            formErrors.email !== null
                                        }
                                        helperText={
                                            isSubmitted &&
                                            formErrors.email !== null
                                                ? formErrors.email
                                                : null
                                        }
                                        inputProps={{
                                            inputMode: "email",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl
                                        fullWidth
                                        required
                                        variant="outlined"
                                        color={
                                            isSubmitted &&
                                            formErrors.password === null
                                                ? "success"
                                                : undefined
                                        }
                                        error={
                                            isSubmitted &&
                                            formErrors.password !== null
                                        }
                                    >
                                        <InputLabel htmlFor="password">
                                            Jelszó
                                        </InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            required
                                            name="password"
                                            id="password"
                                            type={
                                                formValues.showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            value={formValues.password}
                                            inputProps={{ maxLength: 40 }}
                                            onChange={handleChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPasswords
                                                        }
                                                        edge="end"
                                                    >
                                                        {formValues.showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Jelszó"
                                            autoComplete="new-password"
                                        />
                                        <FormHelperText>
                                            {isSubmitted &&
                                            formErrors.password !== null
                                                ? formErrors.password
                                                : null}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                startIcon={<LoginIcon />}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Bejelentkezés
                            </Button>
                            <Grid container>
                                <Grid item sm>
                                    <Link
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            dispatch(
                                                setActiveMenuItem("getpassword")
                                            );
                                            navigate("../getpassword");
                                        }}
                                        variant="body2"
                                    >
                                        Elfelejtettem a jelszavamat
                                    </Link>
                                </Grid>
                                <Grid item sm sx={{ textAlign: "right" }}>
                                    <Link
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            dispatch(
                                                setActiveMenuItem(
                                                    "registration"
                                                )
                                            );
                                            navigate("../registration");
                                        }}
                                        variant="body2"
                                    >
                                        {
                                            "Nincs felhasználói fiókja? Regisztráljon"
                                        }
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </Zoom>
        </Box>
    );
};

export default Login;
