import {
    useEffect,
    useState,
    ChangeEvent,
    SyntheticEvent,
    MouseEvent,
    FormEvent,
} from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../states/hooks";
import { RootState } from "../../states/store";
import { setActiveMenuItem } from "../../states/common/coreSlice";
import {
    setOpenLoadingBackdrop,
    setCloseLoadingBackdrop,
    setOpenMessageDialog,
} from "../../states/common/coreSlice";
import { StatusCodes } from "http-status-codes";
import RegistrationFormValuesType from "../../types/home/registrationFormValuesType";
import RegistrationFormErrorsType from "../../types/home/registrationFormErrorsType";
import ReferralNameType from "../../types/common/referralNameType.d";
import CountryType from "../../types/common/countryType.d";
import { LanguageEnum } from "../../enums/common/languageEnum";
import { statusAlertsData } from "../../datas/common/statusAlertsData";
import { initialRegistrationFormValues } from "../../datas/home/initialRegistrationFormValues";
import { initialRegistrationFormErrors } from "../../datas/home/initialRegistrationFormErrors";
import { countries } from "../../datas/common/countriesData";
import { validateRegistrationForm } from "../../utils/home/validateRegistrationFormUtil";
import {
    getReferralUser,
    registration,
} from "../../services/home/RegistrationService";
import { pink } from "@mui/material/colors";
import Zoom from "@mui/material/Zoom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import RegistrationBackgroundImage from "../../assets/registration-bg.jpg";

const Registration = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const activeLanguage: LanguageEnum = useAppSelector(
        (state: RootState) => state.core.activeLanguage
    );

    const [formValues, setFormValues] = useState<RegistrationFormValuesType>(
        initialRegistrationFormValues
    );
    const [formErrors, setFormErrors] = useState<RegistrationFormErrorsType>(
        initialRegistrationFormErrors
    );

    const [referralName, setReferralName] = useState<ReferralNameType | null>(
        null
    );

    useEffect(() => {
        dispatch(setActiveMenuItem("registration"));
        const referralId = localStorage.getItem("actReceivedReferralId");
        if (referralId && referralId !== "") {
            getReferralUser(referralId).then(
                (response) => {
                    if (response.id && response.id !== "") {
                        setFormValues({
                            ...formValues,
                            referredBy: response,
                        });
                    }
                    if (
                        response.lastName &&
                        response.firstName &&
                        response.lastName !== "" &&
                        response.firstName !== ""
                    ) {
                        setReferralName({
                            lastName: response.lastName,
                            firstName: response.firstName,
                        });
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    useEffect(() => {
        if (isSubmitted) {
            setFormErrors(validateRegistrationForm(formValues));
        }
    }, [formValues, isSubmitted]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    };

    const handleCountryChange =
        (prop: keyof RegistrationFormValuesType) =>
        (event: SyntheticEvent<Element, Event>, value: CountryType | null) => {
            setFormValues({ ...formValues, [prop]: value });
        };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.checked,
        });
    };

    const handleShowPassword = () => {
        setFormValues({
            ...formValues,
            showPassword: !formValues.showPassword,
        });
    };

    const handleShowPasswordAgain = () => {
        setFormValues({
            ...formValues,
            showPasswordAgain: !formValues.showPasswordAgain,
        });
    };

    const handleMouseDownPasswords = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(
            setOpenLoadingBackdrop({
                message: "a regisztráció folyamatban!",
            })
        );
        setIsSubmitted(true);
        const formErrorsObject: RegistrationFormErrorsType =
            validateRegistrationForm(formValues);
        setFormErrors(formErrorsObject);
        if (Object.values(formErrorsObject).every((x) => !x)) {
            formValues.language = activeLanguage;
            // console.log(formValues);
            registration(formValues).then(
                () => {
                    localStorage.removeItem("actReceivedReferralId");
                    dispatch(
                        setOpenMessageDialog({
                            severity: statusAlertsData.registered.severity,
                            title: statusAlertsData.registered.title,
                            message: statusAlertsData.registered.message,
                        })
                    );
                    navigate("../login?status=registered");
                    dispatch(setCloseLoadingBackdrop());
                },
                (error) => {
                    // console.log(error);
                    if (error.response.status === StatusCodes.CONFLICT) {
                        dispatch(
                            setOpenMessageDialog({
                                severity:
                                    statusAlertsData.duplicatregistrationerror
                                        .severity,
                                title: statusAlertsData
                                    .duplicatregistrationerror.title,
                                message:
                                    statusAlertsData.duplicatregistrationerror
                                        .message,
                            })
                        );
                        dispatch(setCloseLoadingBackdrop());
                    } else if (
                        error.response.status === StatusCodes.BAD_REQUEST
                    ) {
                        dispatch(
                            setOpenMessageDialog({
                                severity:
                                    statusAlertsData.registrationformerror
                                        .severity,
                                title: statusAlertsData.registrationformerror
                                    .title,
                                message:
                                    statusAlertsData.registrationformerror
                                        .message,
                            })
                        );
                        dispatch(setCloseLoadingBackdrop());
                    } else {
                        dispatch(
                            setOpenMessageDialog({
                                severity:
                                    statusAlertsData.registrationerror.severity,
                                title: statusAlertsData.registrationerror.title,
                                message:
                                    statusAlertsData.registrationerror.message,
                            })
                        );
                        dispatch(setCloseLoadingBackdrop());
                    }
                }
            );
        } else {
            // console.log(formErrors);
            dispatch(
                setOpenMessageDialog({
                    severity: statusAlertsData.registrationformerror.severity,
                    title: statusAlertsData.registrationformerror.title,
                    message: statusAlertsData.registrationformerror.message,
                })
            );
            dispatch(setCloseLoadingBackdrop());
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: `calc(100vh - 130px)`,
                backgroundImage: `url(${RegistrationBackgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <CssBaseline />
            <Zoom in={true}>
                <Container
                    component="main"
                    sx={{
                        pt: 2,
                        pb: 2,
                        mt: 8,
                        mb: 2,
                        boxShadow: 2,
                        borderRadius: 2,
                        background: "rgba(255, 255, 255, 0.95)!important",
                        overflow: "hidden",
                        overflowY: "scroll",
                    }}
                    maxWidth="sm"
                >
                    <Box
                        sx={{
                            marginTop: 0,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <SensorOccupiedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Regisztráció
                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{ mt: 3 }}
                        >
                            <Grid container spacing={2}>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    sx={{
                                        m: 1,
                                    }}
                                >
                                    {referralName &&
                                    referralName.lastName &&
                                    referralName.firstName ? (
                                        <>
                                            <Grid item>
                                                <Avatar
                                                    sx={{
                                                        width: 30,
                                                        height: 30,
                                                        color: "rgba(255, 255, 255, 0.95)!important",
                                                        background: pink[400],
                                                    }}
                                                >
                                                    <SupervisedUserCircleIcon />
                                                </Avatar>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    component="h2"
                                                    variant="body1"
                                                >
                                                    <strong>
                                                        Az Ön ajánlója:
                                                    </strong>{" "}
                                                    <Typography
                                                        component="span"
                                                        variant="body1"
                                                        sx={{
                                                            color: pink[400],
                                                        }}
                                                    >
                                                        {activeLanguage ===
                                                        LanguageEnum.HUNGARIAN ? (
                                                            <>
                                                                {referralName.lastName +
                                                                    " " +
                                                                    referralName.firstName}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {referralName.firstName +
                                                                    " " +
                                                                    referralName.lastName}
                                                            </>
                                                        )}
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                        </>
                                    ) : null}
                                </Grid>
                                {activeLanguage === LanguageEnum.HUNGARIAN ? (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoFocus
                                                fullWidth
                                                required
                                                name="lastName"
                                                id="lastName"
                                                label="Vezetéknév"
                                                autoComplete="family-name"
                                                value={formValues.lastName}
                                                inputProps={{ maxLength: 40 }}
                                                onChange={handleChange}
                                                color={
                                                    isSubmitted &&
                                                    formErrors.lastName === null
                                                        ? "success"
                                                        : undefined
                                                }
                                                error={
                                                    isSubmitted &&
                                                    formErrors.lastName !== null
                                                }
                                                helperText={
                                                    isSubmitted &&
                                                    formErrors.lastName !== null
                                                        ? formErrors.lastName
                                                        : null
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                name="firstName"
                                                id="firstName"
                                                label="Keresztnév"
                                                autoComplete="given-name"
                                                value={formValues.firstName}
                                                inputProps={{ maxLength: 40 }}
                                                onChange={handleChange}
                                                color={
                                                    isSubmitted &&
                                                    formErrors.firstName ===
                                                        null
                                                        ? "success"
                                                        : undefined
                                                }
                                                error={
                                                    isSubmitted &&
                                                    formErrors.firstName !==
                                                        null
                                                }
                                                helperText={
                                                    isSubmitted &&
                                                    formErrors.firstName !==
                                                        null
                                                        ? formErrors.firstName
                                                        : null
                                                }
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                name="firstName"
                                                id="firstName"
                                                label="Keresztnév"
                                                autoComplete="given-name"
                                                value={formValues.firstName}
                                                inputProps={{ maxLength: 40 }}
                                                onChange={handleChange}
                                                color={
                                                    isSubmitted &&
                                                    formErrors.firstName ===
                                                        null
                                                        ? "success"
                                                        : undefined
                                                }
                                                error={
                                                    isSubmitted &&
                                                    formErrors.firstName !==
                                                        null
                                                }
                                                helperText={
                                                    isSubmitted &&
                                                    formErrors.firstName !==
                                                        null
                                                        ? formErrors.firstName
                                                        : null
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                autoFocus
                                                fullWidth
                                                required
                                                name="lastName"
                                                id="lastName"
                                                label="Vezetéknév"
                                                autoComplete="family-name"
                                                value={formValues.lastName}
                                                inputProps={{ maxLength: 40 }}
                                                onChange={handleChange}
                                                color={
                                                    isSubmitted &&
                                                    formErrors.lastName === null
                                                        ? "success"
                                                        : undefined
                                                }
                                                error={
                                                    isSubmitted &&
                                                    formErrors.lastName !== null
                                                }
                                                helperText={
                                                    isSubmitted &&
                                                    formErrors.lastName !== null
                                                        ? formErrors.lastName
                                                        : null
                                                }
                                            />
                                        </Grid>
                                    </>
                                )}

                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        fullWidth
                                        id="country"
                                        options={countries}
                                        value={formValues.country}
                                        onChange={handleCountryChange(
                                            "country"
                                        )}
                                        autoHighlight
                                        getOptionLabel={(option) =>
                                            option.label
                                        }
                                        isOptionEqualToValue={(
                                            option: any,
                                            value: any
                                        ) =>
                                            option &&
                                            option.code &&
                                            value &&
                                            value.code &&
                                            option.code === value.code
                                        }
                                        getOptionDisabled={(option) =>
                                            option !== countries[98]
                                        }
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                sx={{
                                                    "& > img": {
                                                        mr: 2,
                                                        flexShrink: 0,
                                                    },
                                                }}
                                                {...props}
                                            >
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                                {option.label} ({option.code}) +
                                                {option.phoneCode}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                name="country"
                                                {...params}
                                                label="Válasszon országot"
                                                color={
                                                    isSubmitted &&
                                                    formErrors.country === null
                                                        ? "success"
                                                        : undefined
                                                }
                                                error={
                                                    isSubmitted &&
                                                    formErrors.country !== null
                                                }
                                                helperText={
                                                    isSubmitted &&
                                                    formErrors.country !== null
                                                        ? formErrors.country
                                                        : null
                                                }
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "country", // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {/* Magyaroszág választása esetén a maszkolt irányítószám komponenst használjuk. */}
                                    <TextField
                                        fullWidth
                                        required
                                        name="zip"
                                        id="zip"
                                        label="Irányítószám"
                                        autoComplete="zip"
                                        value={formValues.zip}
                                        onChange={handleChange}
                                        color={
                                            isSubmitted &&
                                            formErrors.zip === null
                                                ? "success"
                                                : undefined
                                        }
                                        error={
                                            isSubmitted &&
                                            formErrors.zip !== null
                                        }
                                        helperText={
                                            isSubmitted &&
                                            formErrors.zip !== null
                                                ? formErrors.zip
                                                : null
                                        }
                                        inputProps={{
                                            maxLength:
                                                formValues.country &&
                                                formValues.country.code &&
                                                formValues.country.code === "HU"
                                                    ? 4
                                                    : null,
                                            inputMode: "numeric",
                                            pattern: "[0-9]*",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        name="email"
                                        id="email"
                                        label="E-mail cím"
                                        autoComplete="email"
                                        value={formValues.email}
                                        onChange={handleChange}
                                        color={
                                            isSubmitted &&
                                            formErrors.email === null
                                                ? "success"
                                                : undefined
                                        }
                                        error={
                                            isSubmitted &&
                                            formErrors.email !== null
                                        }
                                        helperText={
                                            isSubmitted &&
                                            formErrors.email !== null
                                                ? formErrors.email
                                                : null
                                        }
                                        inputProps={{
                                            inputMode: "email",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        required
                                        name="phone"
                                        id="phone"
                                        label="Mobil telefonszám"
                                        autoComplete="phone"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    +
                                                    {formValues.country &&
                                                    formValues.country.phoneCode
                                                        ? formValues.country
                                                              .phoneCode
                                                        : ""}
                                                </InputAdornment>
                                            ),
                                        }}
                                        value={formValues.phone}
                                        onChange={handleChange}
                                        color={
                                            isSubmitted &&
                                            formErrors.phone === null
                                                ? "success"
                                                : undefined
                                        }
                                        error={
                                            isSubmitted &&
                                            formErrors.phone !== null
                                        }
                                        helperText={
                                            isSubmitted &&
                                            formErrors.phone !== null
                                                ? formErrors.phone
                                                : null
                                        }
                                        inputProps={{
                                            inputMode: "tel",
                                            maxLength:
                                                formValues.country &&
                                                formValues.country.code &&
                                                formValues.country.code === "HU"
                                                    ? 9
                                                    : null,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        fullWidth
                                        required
                                        variant="outlined"
                                        color={
                                            isSubmitted &&
                                            formErrors.password === null
                                                ? "success"
                                                : undefined
                                        }
                                        error={
                                            isSubmitted &&
                                            formErrors.password !== null
                                        }
                                    >
                                        <InputLabel htmlFor="password">
                                            Jelszó
                                        </InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            required
                                            name="password"
                                            id="password"
                                            type={
                                                formValues.showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            value={formValues.password}
                                            inputProps={{ maxLength: 40 }}
                                            onChange={handleChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPasswords
                                                        }
                                                        edge="end"
                                                    >
                                                        {formValues.showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Jelszó"
                                            autoComplete="new-password"
                                        />
                                        <FormHelperText>
                                            {isSubmitted &&
                                            formErrors.password !== null
                                                ? formErrors.password
                                                : null}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl
                                        fullWidth
                                        required
                                        variant="outlined"
                                        color={
                                            isSubmitted &&
                                            formErrors.passwordAgain === null
                                                ? "success"
                                                : undefined
                                        }
                                        error={
                                            isSubmitted &&
                                            formErrors.passwordAgain !== null
                                        }
                                    >
                                        <InputLabel htmlFor="passwordAgain">
                                            Jelszó mégegyszer
                                        </InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            required
                                            name="passwordAgain"
                                            id="passwordAgain"
                                            type={
                                                formValues.showPasswordAgain
                                                    ? "text"
                                                    : "password"
                                            }
                                            value={formValues.passwordAgain}
                                            inputProps={{ maxLength: 40 }}
                                            onChange={handleChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleShowPasswordAgain
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPasswords
                                                        }
                                                        edge="end"
                                                    >
                                                        {formValues.showPasswordAgain ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Jelszó mégegyszer"
                                            autoComplete="new-password"
                                        />
                                        <FormHelperText>
                                            {isSubmitted &&
                                            formErrors.passwordAgain !== null
                                                ? formErrors.passwordAgain
                                                : null}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="newsletterSubscription"
                                                id="newsletterSubscription"
                                                color="primary"
                                                checked={
                                                    formValues.newsletterSubscription
                                                }
                                                onChange={handleCheckboxChange}
                                            />
                                        }
                                        label="Feliratkozom a hírlevélre"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl
                                        required
                                        error={
                                            isSubmitted &&
                                            formErrors.termsAccepted !== null
                                        }
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    required
                                                    name="termsAccepted"
                                                    id="termsAccepted"
                                                    color="primary"
                                                    checked={
                                                        formValues.termsAccepted
                                                    }
                                                    onChange={
                                                        handleCheckboxChange
                                                    }
                                                />
                                            }
                                            label="Az Automatizált Kriptovaluta Kereskedés szabályzatát és adatkezelési tájékoztatót megismertem és elfogadom."
                                        />
                                        <FormHelperText>
                                            {isSubmitted &&
                                            formErrors.termsAccepted !== null
                                                ? formErrors.termsAccepted
                                                : null}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                startIcon={<HowToRegIcon />}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Regisztráció
                            </Button>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => {
                                            dispatch(
                                                setActiveMenuItem("login")
                                            );
                                            navigate("../login");
                                        }}
                                        variant="body2"
                                    >
                                        Van már fiókja? Jelenkezzen be
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </Zoom>
        </Box>
    );
};

export default Registration;
