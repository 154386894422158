import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Route, Routes } from "react-router-dom";
import ProtectedAdminRoute from "../../routes/ProtectedAdminRoute";
import { StatusCodes } from "http-status-codes";
import { useAppDispatch } from "../../states/hooks";
import { setUserProfile, setCleanUser } from "../../states/main/userSlice";
import { setActiveLanguage, setCleanCore } from "../../states/common/coreSlice";
import {
    setWebSocketConnecting,
    setCleanStream,
} from "../../states/main/streamSlice";
import {
    getCurrentUser,
    setAuthToken,
} from "../../services/common/AuthService";
import { readUserProfile } from "../../services/main/ProfileService";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import NavbarAppBar from "../../components/main/NavbarAppBar/NavbarAppBar";
import SidebarDrawer from "../../components/main/SidebarDrawer/SidebarDrawer";
import Profile from "./Profile";
import Exchanges from "./Exchanges";
import Dashboard from "./Dashboard";
import Users from "./Users";
import Affiliate from "./Affiliate";
import Transactions from "./Transactions";
import Logout from "./Logout";
import NotFound from "../common/NotFound";

const Main = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    //csak egyszer fut le amikoe mount-ol a komponens
    useEffect(() => {
        setAuthToken();
        dispatch(setCleanUser());
        dispatch(setCleanCore());
        dispatch(setCleanStream());
        if (getCurrentUser().token) {
            readUserProfile().then(
                (response) => {
                    dispatch(setUserProfile(response.data));
                    dispatch(setActiveLanguage(response.data.language));
                    dispatch(setWebSocketConnecting());
                    localStorage.setItem("actLanguge", response.data.language);
                },
                (error) => {
                    // console.log(error);
                    if (error.response && error.response.status) {
                        //TODO többi hibát is lekezelni
                        if (
                            error.response.status === StatusCodes.UNAUTHORIZED
                        ) {
                            //TODO status továbbadni
                            navigate("logout");
                        } else if (
                            error.response.status === StatusCodes.NOT_FOUND
                        ) {
                            //TODO status továbbadni
                            navigate("logout");
                        }
                    }
                }
            );
        } else {
            //nincs local user
            //TODO status továbbadni
            navigate("/logout");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    background: "#010138",
                    height: "100vh",
                }}
            >
                <CssBaseline />
                <NavbarAppBar />
                <SidebarDrawer />
                <Box
                    sx={{
                        color: "white",
                        mt: "80px",
                        ml: "80px",
                        mr: "15px",
                    }}
                >
                    <Routes>
                        <Route element={<ProtectedAdminRoute />}>
                            <Route path="admin/users" element={<Users />} />
                        </Route>
                        <Route path="profile" element={<Profile />} />
                        <Route path="exchanges" element={<Exchanges />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="transactions" element={<Transactions />} />
                        <Route path="affiliate" element={<Affiliate />} />
                        <Route path="logout" element={<Logout />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Box>
            </Box>
        </>
    );
};

export default Main;
