import { FeedbackSeverityEnum } from "../../enums/common/feedbackSeverityEnum";
import StatusAlertType from "../../types/common/statusAlertType";

export const statusAlertsData: { [index: string]: StatusAlertType } = {
    registered: {
        severity: FeedbackSeverityEnum.INFORMATION,
        title: "Gratulálunk",
        message:
            "Sikeresen regisztrált az Automatizált Kriptovaluta Kereskedés oldalra. Kérjük véglegesítse a regisztrációját az e-mailben kapott link segítségével, utána be tud jelentkezni a fiókjába.",
    },
    duplicatregistrationerror: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Regisztrációs hiba",
        message:
            "A megadott e-mail címhez már tartozik fiók. Amennyiben elfelejtette jelszavát, kérjük igényeljen újat vagy regisztráljon más e-mail címmel!",
    },
    registrationformerror: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Regisztrációs hiba",
        message: "Kérjük javítsa a bevitt adatokat!",
    },
    registrationerror: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Regisztrációs hiba",
        message: "A regisztráció sikertelen, kérjük próbálja meg később!",
    },
    validated: {
        severity: FeedbackSeverityEnum.SUCCESS,
        title: "Gratulálunk",
        message:
            "Sikeresen validálta az e-mail címét, most már be tud jelentkezni a felhasználói fiókjába.",
    },
    validationlinkempty: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Validáló link hiányos",
        message: "A validáláshoz használt link hibás!",
    },
    notfoundusertovalidate: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Validálási hiba",
        message:
            "A validáláshoz használt linkhez nem tartozik felhasználói fiók!",
    },
    alreadyvalidated: {
        severity: FeedbackSeverityEnum.INFORMATION,
        title: "Értesítés",
        message:
            "Az e-mail címet korábban már validálta, be tud jelentkezni a felhasználói fiókjába.",
    },
    validationerror: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Validálási hiba",
        message:
            "Az e-mail címhez tartozó felhasználói fiók validálása sikertelen, kérjük próbálja meg később.",
    },
    loginunauthorized: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Bejelentkezési hiba",
        message:
            "Hibás e-mail cím és/vagy jelszó. Kérjük javítsa a bevitt adatokat!",
    },
    loginformerror: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Bejelentkezési hiba",
        message: "Kérjük javítsa a bevitt adatokat!",
    },
    pendinglogin: {
        severity: FeedbackSeverityEnum.WARNING,
        title: "Értesítés",
        message:
            "Kérjük először véglegesítse a regisztrációját az e-mailben kapott link segítségével, utána be tud jelentkezni a felhasználói fiókjába.",
    },
    loginerror: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Bejelentkezési hiba",
        message: "A bejelentkezés sikertelen, kérjük próbálja meg később!",
    },
    notfoundusertogetpassword: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Jelszó igénylés hiba",
        message:
            "A jelszó igényléshez megadott e-mail címhez nem tartozik felhasználói fiók! Kérjük ellenőrizze a kitöltést vagy regisztráljon újra.",
    },
    getpasswordformerror: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Jelszó igénylési hiba",
        message: "Kérjük javítsa a bevitt adatokat!",
    },
    changepasswordlinksent: {
        severity: FeedbackSeverityEnum.INFORMATION,
        title: "Értesítés",
        message:
            "Sikeresen elküldtük a jelszó beállításhoz szükséges linket, kérjük ellenőrizze az e-mail fiókját!",
    },
    changedpassword: {
        severity: FeedbackSeverityEnum.SUCCESS,
        title: "Gratulálunk",
        message: "Sikeresen beállította az új jelszavát.",
    },
    pendingchangepassword: {
        severity: FeedbackSeverityEnum.WARNING,
        title: "Értesítés",
        message:
            "Kérjük először véglegesítse a regisztrációját az e-mailben kapott link segítségével, utána tud jelszót igényelni",
    },
    changepasswordlinkexpired: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Jelszó igénylő link lejárt",
        message:
            "A jelszó igényléshez használt link lejárt, igényeljen újat e-mail címe megadásával.",
    },
    notfoundusertochangepassword: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Jelszó igénylés hiba",
        message:
            "A jelszó igényléshez használt linkhez nem tartozik felhasználói fiók!",
    },
    changepasswordformerror: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Jelszó változtatási hiba",
        message: "Kérjük javítsa a bevitt adatokat!",
    },
    referrallinkcopied: {
        severity: FeedbackSeverityEnum.SUCCESS,
        title: "Értesítés",
        message: "Az egyedi ajánlói linkjét sikeresen felmásolta a vágólapra!",
    },
    referrallinknerror: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Hiba",
        message: "Az ajánlói link generálása sikertelen volt!",
    },
    changedreportfrequency: {
        severity: FeedbackSeverityEnum.SUCCESS,
        title: "Értesítés",
        message: "Sikeresen frissítette az email jelentések gyakoriságát!",
    },
    changereportfrequencynerror: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Hiba",
        message:
            "Az email jelentések gyakoriságának frissítése sikertelen volt!",
    },
    newslettersubscribed: {
        severity: FeedbackSeverityEnum.SUCCESS,
        title: "Gratulálunk",
        message: "Sikeresen feliratkozott a hírlevélre.",
    },
    newsletterunsubscribedmain: {
        severity: FeedbackSeverityEnum.INFORMATION,
        title: "Értesítés",
        message: "Sikeresen leiratkozott a hírlevélről.",
    },
    newsletterunsubscribedhome: {
        severity: FeedbackSeverityEnum.INFORMATION,
        title: "Értesítés",
        message:
            "Sikeresen leiratkozott a hírlevélről. Amennyiben szeretne újra feliratkozni, a fiókjában a Profil oldalán megteheti.",
    },
    newsletterunsubscribelinkempty: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Hírlevél leiratkozó link hiányos",
        message: "A hírlevél leiratkozáshoz használt link hibás!",
    },
    notfoundusertonewsletterunsubscribe: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Hírlevél leiratkozási hiba",
        message:
            "A hírlevél leiratkozáshoz használt linkhez nem tartozik felhasználói fiók!",
    },
    alreadyunsubscribedfromnewsletter: {
        severity: FeedbackSeverityEnum.INFORMATION,
        title: "Értesítés",
        message: "Az az e-mail cím korábban már leiratkozott a hírlevélről.",
    },
    newsletterunsubscribeerror: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Leiratkozási hiba",
        message:
            "Az e-mail cím hírlevélről való leiratkozása sikertelen, kérjük próbálja meg később.",
    },
    communicationerror: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Hálozati hiba",
        message:
            "Hiba történt a kapcsolódás során, kérjük próbálja újra később.",
    },
    exchangeconnectiontestok: {
        severity: FeedbackSeverityEnum.SUCCESS,
        title: "Értesítés",
        message: "Sikeres teszt kapcsolat a Binance szervereivel.",
    },
    exchangeconnectiontesterror: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Kapcsolódási hiba",
        message:
            "Sikertelen kapcsolódás a kriptovaluta tőzsde szervereihez, kérem ellenőrizze az API és titkos kulcsokat.",
    },
    exchangecreated: {
        severity: FeedbackSeverityEnum.SUCCESS,
        title: "Értesítés",
        message: "Sikeresen rögzítette a kriptovaluta tőzsde kapcsolatot.",
    },
    exchangedeleted: {
        severity: FeedbackSeverityEnum.SUCCESS,
        title: "Értesítés",
        message: "Sikeresen törölte a kriptovaluta tőzsde kapcsolatot.",
    },
    exchangenotfound: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Hiba",
        message: "A kriptovaluta tőzsde nem található az adatbázisban.",
    },
    exchangalreadyinuse: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Hiba",
        message:
            "A megadott kriptrovaluta tőzsde kapcsolatot nem lehet rögzíteni, mert már egy másik felhasználó használja.",
    },
    exchangecreateerror: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Hiba",
        message: "Sikertelen rögzítés, kérem próbálja meg később.",
    },
    exchangewebsocketclose: {
        severity: FeedbackSeverityEnum.ERROR,
        title: "Hiba",
        message:
            "Megszakadt a kapcsolat a kriptovaluta tőzsdével. Újracsatlakozás folyamatban.",
    },
    exchangewebsocketcurrencychanged: {
        severity: FeedbackSeverityEnum.INFORMATION,
        title: "Értesítés",
        message:
            "Kis türelmet, a kriptovaluta árfolyam adatok lekérdezése folyamatban.",
    },
    exchangewebsockettryreconnect: {
        severity: FeedbackSeverityEnum.INFORMATION,
        title: "Értesítés",
        message:
            "Adatok lekérdezéséhez kapcsolat kialakítása a tőzsdével folyamatban.",
    },
    exchangewebsocketreopened: {
        severity: FeedbackSeverityEnum.SUCCESS,
        title: "Sikeres kapcsolódás",
        message: "Él a kapcsolat a kriptovaluta tőzsdével.",
    },
};
