import axios from "axios";
import { server } from "../../configs/endpoints";
import ProfileFormValuesType from "../../types/main/profileFormValuesType";
import { LanguageEnum } from "../../enums/common/languageEnum";

const API_URL = server;

export const readUserProfile = () => {
    return axios.get(API_URL + "/protected/user/profile").then((response) => {
        return response;
    });
};

export const updateUserLanguage = (userLanguage: LanguageEnum) => {
    const request = {
        language: userLanguage,
    };
    return axios
        .patch(API_URL + "/protected/user/language", request)
        .then((response) => {
            return response;
        });
};

export const updateUserReportFrequency = (
    formValues: ProfileFormValuesType
) => {
    const request = {
        reportPerOrder: formValues.reportPerOrder,
        reportDaily: formValues.reportDaily,
        reportWeekly: formValues.reportWeekly,
        reportMonthly: formValues.reportMonthly,
    };
    return axios
        .patch(API_URL + "/protected/user/reportfrequency", request)
        .then((response) => {
            return response;
        });
};
