import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                position: "absolute",
                zIndex: "appBar",
                display: "flex",
                minWidth: "100vw",
                height: "60px",
                color: "#fff",
                alignItems: "center",
                justifyContent: "center",
                background: "#282A34",
            }}
        >
            <Container maxWidth="sm">
                <Typography noWrap align="center" variant="body1">
                    {/* <Link color="#fff" underline="hover" href="#">
                        Automatizált Kriptovaluta Kereskedés
                    </Link> */}
                    Automatizált Kriptovaluta Kereskedés
                </Typography>
                <Typography
                    noWrap
                    align="center"
                    variant="body2"
                    sx={{ display: { xs: "none", md: "inherit" } }}
                >
                    Copyright © {new Date().getFullYear()} | Inexo Studio |{" "}
                    <Link
                        rel="noopener noreferrer"
                        color="#fff"
                        underline="hover"
                        href="mailto:info@inexo.hu"
                        target="_top"
                    >
                        info@inexo.hu
                    </Link>
                </Typography>
                <Typography
                    noWrap
                    align="center"
                    variant="body2"
                    color="#fff"
                    sx={{ display: { xs: "inherit", md: "none" } }}
                >
                    Copyright © {new Date().getFullYear()} |{" "}
                    <Link
                        rel="noopener noreferrer"
                        color="#fff"
                        underline="hover"
                        href="mailto:info@inexo.hu"
                        target="_top"
                    >
                        Inexo Studio
                    </Link>
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
