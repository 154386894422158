export const sidebarDrawerStyles = {
    drawer: {
        displayPrint: "none",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            backgroundColor: "#0D0D45",
            color: "rgba(255, 255, 255, 0.7)",
            minWidth: "65px",
        },
        "& .Mui-selected": {
            color: "#DCC28E",
        },
    },
    icon: {
        marginLeft: "5px",
        color: "rgba(255, 255, 255, 0.7)!important",
    },
    iconSelected: {
        marginLeft: "5px",
        color: "#DCC28E!important",
    },
    closeIcon: {
        color: "rgba(255, 255, 255, 0.7)!important",
    },
    text: {
        "& span": {
            marginLeft: "-10px",
            fontWeight: "400",
            fontSize: "16px",
        },
    },
};
