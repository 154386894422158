import { forwardRef, ReactElement, Ref } from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../states/hooks";
import { RootState } from "../../states/store";
import { setCloseMessageDialog } from "../../states/common/coreSlice";
import { FeedbackSeverityEnum } from "../../enums/common/feedbackSeverityEnum";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const MessageDialog = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const messageDialog: any = useAppSelector(
        (state: RootState) => state.core.messageDialog
    );

    const handleClose = () => {
        if (
            messageDialog &&
            messageDialog.navigate &&
            messageDialog.navigate !== ""
        ) {
            navigate(messageDialog.navigate);
        }
        dispatch(setCloseMessageDialog());
    };

    const StatusIcon = () => {
        switch (messageDialog.severity) {
            case FeedbackSeverityEnum.SUCCESS:
                return (
                    <CheckBoxOutlinedIcon fontSize="large" color="success" />
                );
            case FeedbackSeverityEnum.INFORMATION:
                return <InfoOutlinedIcon fontSize="large" color="info" />;
            case FeedbackSeverityEnum.WARNING:
                return <WarningAmberIcon fontSize="large" color="warning" />;
            case FeedbackSeverityEnum.ERROR:
                return (
                    <ReportGmailerrorredOutlinedIcon
                        fontSize="large"
                        color="error"
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={
                    messageDialog && messageDialog.open
                        ? messageDialog.open
                        : false
                }
                TransitionComponent={Transition}
                keepMounted
                disableEscapeKeyDown
                aria-describedby="alert-dialog-slide-description"
            >
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <StatusIcon />
                    <DialogTitle color="success">
                        {messageDialog && messageDialog.title
                            ? messageDialog.title
                            : "Értesítés"}
                    </DialogTitle>
                </Stack>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {messageDialog && messageDialog.message
                            ? messageDialog.message
                            : null}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<CheckIcon />}
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleClose}
                        autoFocus
                    >
                        Rendben
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MessageDialog;
