import { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../states/hooks";
import { setActiveMenuItem } from "../../states/common/coreSlice";
import {
    setOpenLoadingBackdrop,
    setCloseLoadingBackdrop,
    setOpenMessageDialog,
} from "../../states/common/coreSlice";
import GetPasswordFormValuesType from "../../types/home/getPasswordFormValuesType";
import GetPasswordFormErrorsType from "../../types/home/getPasswordFormErrorsType";
import { statusAlertsData } from "../../datas/common/statusAlertsData";
import { initialGetPasswordFormValues } from "../../datas/home/initialGetPasswordFormValues";
import { initialGetPasswordFormErrors } from "../../datas/home/initialGetPasswordFormErrors";
import { validateGetPasswordForm } from "../../utils/home/validateGetPasswordFormUtil";
import { getChangePasswordIdFromEmail } from "../../services/common/AuthService";
import StatusAlert from "../../components/home/StatusAlert";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Zoom from "@mui/material/Zoom";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MailLockIcon from "@mui/icons-material/MailLock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import GetPasswordBackgroundImage from "../../assets/get-password-bg.jpg";

const GetPassword = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setActiveMenuItem("getpassword"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [searchParams] = useSearchParams();
    const [status, setStatus] = useState<string | null>(
        searchParams.get("status")
    );

    const [formValues, setFormValues] = useState<GetPasswordFormValuesType>(
        initialGetPasswordFormValues
    );
    const [formErrors, setFormErrors] = useState<GetPasswordFormErrorsType>(
        initialGetPasswordFormErrors
    );
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    useEffect(() => {
        if (isSubmitted) {
            setFormErrors(validateGetPasswordForm(formValues));
        }
    }, [formValues, isSubmitted]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(
            setOpenLoadingBackdrop({
                message: "jelszó igénylés folyamatban!",
            })
        );
        setIsSubmitted(true);
        const formErrorsObject: GetPasswordFormErrorsType =
            validateGetPasswordForm(formValues);
        setFormErrors(formErrorsObject);
        if (Object.values(formErrorsObject).every((x) => !x)) {
            getChangePasswordIdFromEmail(formValues).then(
                () => {
                    dispatch(
                        setOpenMessageDialog({
                            severity:
                                statusAlertsData.changepasswordlinksent
                                    .severity,
                            title: statusAlertsData.changepasswordlinksent
                                .title,
                            message:
                                statusAlertsData.changepasswordlinksent.message,
                        })
                    );
                    navigate("../login?status=changepasswordlinksent");
                    dispatch(setCloseLoadingBackdrop());
                },
                (error) => {
                    console.log(error);
                    dispatch(
                        setOpenMessageDialog({
                            severity:
                                statusAlertsData.notfoundusertogetpassword
                                    .severity,
                            title: statusAlertsData.notfoundusertogetpassword
                                .title,
                            message:
                                statusAlertsData.notfoundusertogetpassword
                                    .message,
                        })
                    );
                    setStatus("notfoundusertogetpassword");
                    dispatch(setCloseLoadingBackdrop());
                }
            );
        } else {
            dispatch(
                setOpenMessageDialog({
                    severity: statusAlertsData.getpasswordformerror.severity,
                    title: statusAlertsData.getpasswordformerror.title,
                    message: statusAlertsData.getpasswordformerror.message,
                })
            );
            setStatus(null);
            dispatch(setCloseLoadingBackdrop());
        }
    };
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: `calc(100vh - 130px)`,
                backgroundImage: `url(${GetPasswordBackgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <CssBaseline />
            <Zoom in={true}>
                <Container
                    component="main"
                    sx={{
                        pt: 2,
                        pb: 2,
                        mt: 8,
                        mb: 2,
                        boxShadow: 2,
                        borderRadius: 2,
                        background: "rgba(255, 255, 255, 0.95)!important",
                        overflow: "hidden",
                        overflowY: "scroll",
                    }}
                    maxWidth="xs"
                >
                    <Box
                        sx={{
                            mt: 0,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        {status ? (
                            <>
                                <StatusAlert status={status} />
                            </>
                        ) : null}
                        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                            <MailLockIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Új jelszó igénylése
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            noValidate
                            sx={{ mt: 3 }}
                        >
                            <TextField
                                autoFocus
                                fullWidth
                                required
                                name="email"
                                id="email"
                                label="E-mail cím"
                                autoComplete="email"
                                value={formValues.email}
                                onChange={handleChange}
                                color={
                                    isSubmitted && formErrors.email === null
                                        ? "success"
                                        : undefined
                                }
                                error={isSubmitted && formErrors.email !== null}
                                helperText={
                                    isSubmitted && formErrors.email !== null
                                        ? formErrors.email
                                        : null
                                }
                                inputProps={{
                                    inputMode: "email",
                                }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                startIcon={<LockOpenIcon />}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Ellenörző e-mail küldése
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </Zoom>
        </Box>
    );
};

export default GetPassword;
