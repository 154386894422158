import { LanguageEnum } from "../../../enums/common/languageEnum";
import {
    UserCountry,
    UserNewsletterSubscription,
    UserRole,
} from "../../../enums/main/userEnum";

interface InitialUserState {
    profile: {
        referralId: string | null;
        role: UserRole | null;
        lastName: string | null;
        firstName: string | null;
        email: string | null;
        phone: string | null;
        country: UserCountry | null;
        zip: string | null;
        language: LanguageEnum | null;
        newsletterSubscription: UserNewsletterSubscription | null;
        reportPerOrder: boolean | null;
        reportDaily: boolean | null;
        reportWeekly: boolean | null;
        reportMonthly: boolean | null;
        referredBy: object | null;
    };
}
const UpdateUserAction: string = "UpdateUserAction";

export default InitialUserState;
export { UpdateUserAction };
