import GetPasswordFormValuesType from "../../types/home/getPasswordFormValuesType";
import GetPasswordFormErrorsType from "../../types/home/getPasswordFormErrorsType";
import { initialGetPasswordFormErrors } from "../../datas/home/initialGetPasswordFormErrors";

export const validateGetPasswordForm = (values: GetPasswordFormValuesType) => {
    const errors: GetPasswordFormErrorsType = {
        ...initialGetPasswordFormErrors,
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email || values.email === "") {
        errors.email = "Az e-mail címet kötelező kitölteni jelszó igényléshez!";
    } else if (!emailRegex.test(values.email)) {
        errors.email = "Hibás e-mail cím formátum!";
    }

    return errors;
};
