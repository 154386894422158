import ExchangeFormValuesType from "../../types/main/exchangeFormValuesType";
import ExchangeFormErrorsType from "../../types/main/exchangeFormErrorsType";
import { initialExchangeFormErrors } from "../../datas/main/initialExchangeFormErrors";

export const validateExchangeForm = (values: ExchangeFormValuesType) => {
    const errors: ExchangeFormErrorsType = {
        ...initialExchangeFormErrors,
    };

    if (!values.apiKey || values.apiKey === "") {
        errors.apiKey = "Az API kulcsot kötelező kitölteni";
    }

    if (!values.secret || values.secret === "") {
        errors.secret = "A tiktos kulcsot kötelező kitölteni";
    }

    return errors;
};
