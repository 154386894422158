import axios from "axios";
import { server } from "../../configs/endpoints";
import GetPasswordFormValuesType from "../../types/home/getPasswordFormValuesType";
import ChangePasswordFormValuesType from "../../types/home/changePasswordFormValuesType";
import LoginFormValuesType from "../../types/home/loginFormValuesType";

const API_URL = server;

export const login = (formValues: LoginFormValuesType) => {
    const request = {
        email: formValues.email,
        password: formValues.password,
    };
    return axios.post(API_URL + "/user/login", request).then((response) => {
        if (response.data.token) {
            localStorage.setItem("actUser", JSON.stringify(response.data));
        }
        return response.data;
    });
};

export const logout = async () => {
    localStorage.removeItem("actUser");
    setAuthToken();
};

export const getChangePasswordIdFromEmail = (
    formValues: GetPasswordFormValuesType
) => {
    const request = {
        email: formValues.email,
    };
    return axios
        .get(API_URL + "/user/getpassword/" + request.email)
        .then((response) => {
            return response.data;
        });
};

export const getEmailFromChangePasswordId = (changePasswordId: string) => {
    return axios
        .get(API_URL + "/user/changepassword/" + changePasswordId)
        .then((response) => {
            return response.data;
        });
};

export const changePassword = (formValues: ChangePasswordFormValuesType) => {
    const request = {
        changePasswordId: formValues.changePasswordId,
        email: formValues.email,
        password: formValues.password,
    };
    return axios
        .patch(API_URL + "/user/changepassword/", request)
        .then((response) => {
            return response.data;
        });
};

export const getCurrentUser = () => {
    const userLocalStorage = localStorage.getItem("actUser");
    if (userLocalStorage) {
        return JSON.parse(userLocalStorage);
    }
    return null;
};

export const setAuthToken = async () => {
    const userLocalStorage = localStorage.getItem("actUser");
    if (userLocalStorage) {
        const user = JSON.parse(userLocalStorage);
        if (user && user.token) {
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${user.token}`;
        } else {
            delete axios.defaults.headers.common["Authorization"];
        }
    }
};
