import { forwardRef } from "react";
import { useAppDispatch, useAppSelector } from "../../states/hooks";
import { RootState } from "../../states/store";
import { setCloseNotificationSnackbar } from "../../states/common/coreSlice";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NotificationSnackbar = () => {
    const dispatch = useAppDispatch();

    const notificationSnackbar: any = useAppSelector(
        (state: RootState) => state.core.notificationSnackbar
    );

    const handleClose = () => {
        dispatch(setCloseNotificationSnackbar());
    };

    return (
        <>
            {notificationSnackbar && notificationSnackbar.severity ? (
                <Snackbar
                    open={
                        notificationSnackbar && notificationSnackbar.open
                            ? notificationSnackbar.open
                            : false
                    }
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert
                        onClose={handleClose}
                        severity={notificationSnackbar.severity}
                        sx={{ width: "100%" }}
                    >
                        {notificationSnackbar.message}
                    </Alert>
                </Snackbar>
            ) : null}
        </>
    );
};

export default NotificationSnackbar;
