import { useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../states/hooks";
import { setActiveMenuItem } from "../../../states/common/coreSlice";
import { RootState } from "../../../states/store";
import { pink } from "@mui/material/colors";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from "@mui/icons-material/Login";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import HowToRegIcon from "@mui/icons-material/HowToReg";
// import PolicyIcon from "@mui/icons-material/Policy";
// import LanguageMenu from "../../common/LanguageMenu";
import actLogoImage from "../../../assets/act-logo.png";

/* TODO 2023.05.17. will be switched back when there is general terms */

const navMenuAppBarMenuItems = [
    {
        id: 0,
        icon: <HomeIcon />,
        label: "Főoldal",
        route: "",
    },
    {
        id: 1,
        icon: <LoginIcon />,
        label: "Bejelentkezés",
        route: "login",
    },
    {
        id: 3,
        icon: <HowToRegIcon />,
        label: "Regisztráció",
        route: "registration",
    },
    {
        id: 4,
        icon: <LockOpenIcon />,
        label: "Jelszó igénylés",
        route: "getpassword",
    },
    // {
    //     id: 5,
    //     icon: <PolicyIcon />,
    //     label: "ÁSZF",
    //     route: "20221015_general_terms_and_conditions",
    // },
];

const NavMenuAppBar = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const activeMenuItem: string = useAppSelector(
        (state: RootState) => state.core.activeMenuItem
    );

    const getActiveMenuIndex = () => {
        return navMenuAppBarMenuItems.findIndex(
            (navMenuAppBarMenuItem) =>
                navMenuAppBarMenuItem.route === activeMenuItem
        );
    };

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleListItemClick = (route: string) => {
        dispatch(setActiveMenuItem(route));
        setAnchorElNav(null);
        navigate(route);
    };

    return (
        <AppBar
            position="static"
            sx={{
                displayPrint: "none",
                height: "70px",
                background: "#01003A",
                justifyContent: "center",
            }}
        >
            <Container maxWidth="xl" sx={{ background: "#01003A" }}>
                <Toolbar disableGutters>
                    <Box
                        onClick={() => handleListItemClick("")}
                        sx={{ display: { xs: "none", md: "flex" }, mr: 2 }}
                    >
                        <img height="50" src={actLogoImage} alt="ACT logo" />
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            width: "64px",
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <Tooltip title="Menü megnyitása">
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {navMenuAppBarMenuItems.map((item, index) => (
                                <MenuItem
                                    key={item.id}
                                    selected={index === getActiveMenuIndex()}
                                    onClick={() =>
                                        handleListItemClick(item.route)
                                    }
                                    sx={{
                                        color:
                                            activeMenuItem === item.route
                                                ? pink[400]
                                                : "inherit",
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            color: "inherit",
                                        }}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText>{item.label}</ListItemText>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box
                        onClick={() => handleListItemClick("")}
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <img height="50" src={actLogoImage} alt="ACT logo" />
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        {navMenuAppBarMenuItems.map((item) => (
                            <Button
                                key={item.id}
                                startIcon={item.icon}
                                onClick={() => handleListItemClick(item.route)}
                                sx={{
                                    my: 1,
                                    mr: 1,
                                    color:
                                        activeMenuItem === item.route
                                            ? pink[400]
                                            : "white",
                                }}
                            >
                                {item.label}
                            </Button>
                        ))}
                    </Box>
                    {/* TODO 2023.05.17. will be switched back when there is localization */}
                    {/* <Box sx={{ flexGrow: 0 }}>
                        <LanguageMenu />
                    </Box> */}
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default NavMenuAppBar;
