import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../states/hooks";
import { setActiveMenuItem } from "../../states/common/coreSlice";
import {
    setOpenLoadingBackdrop,
    setCloseLoadingBackdrop,
    setOpenMessageDialog,
} from "../../states/common/coreSlice";
import { StatusCodes } from "http-status-codes";
import { statusAlertsData } from "../../datas/common/statusAlertsData";
import { unsubscribe } from "../../services/home/NewsletterUnsubscribeService";

const NewsletterUnsubscribe = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();
    const newsletterUnsubscribeId = searchParams.get("id");

    useEffect(() => {
        dispatch(setActiveMenuItem("newsletterunsubscribe"));
        if (newsletterUnsubscribeId && newsletterUnsubscribeId !== "") {
            dispatch(
                setOpenLoadingBackdrop({
                    message: "hírlevél leiratkozás folyamatban!",
                })
            );
            unsubscribe(newsletterUnsubscribeId).then(
                () => {
                    dispatch(
                        setOpenMessageDialog({
                            severity:
                                statusAlertsData.newsletterunsubscribedhome
                                    .severity,
                            title: statusAlertsData.newsletterunsubscribedhome
                                .title,
                            message:
                                statusAlertsData.newsletterunsubscribedhome
                                    .message,
                        })
                    );
                    navigate("../login?status=newsletterunsubscribed");
                    dispatch(setCloseLoadingBackdrop());
                },
                (error) => {
                    if (error && error.response && error.response.status) {
                        if (error.response.status === StatusCodes.BAD_REQUEST) {
                            dispatch(
                                setOpenMessageDialog({
                                    severity:
                                        statusAlertsData
                                            .newsletterunsubscribelinkempty
                                            .severity,
                                    title: statusAlertsData
                                        .newsletterunsubscribelinkempty.title,
                                    message:
                                        statusAlertsData
                                            .newsletterunsubscribelinkempty
                                            .message,
                                })
                            );
                            navigate(
                                "../login?status=newsletterunsubscribelinkempty"
                            );
                            dispatch(setCloseLoadingBackdrop());
                        } else if (
                            error.response.status === StatusCodes.NOT_FOUND
                        ) {
                            dispatch(
                                setOpenMessageDialog({
                                    severity:
                                        statusAlertsData
                                            .notfoundusertonewsletterunsubscribe
                                            .severity,
                                    title: statusAlertsData
                                        .notfoundusertonewsletterunsubscribe
                                        .title,
                                    message:
                                        statusAlertsData
                                            .notfoundusertonewsletterunsubscribe
                                            .message,
                                })
                            );
                            navigate(
                                "../login?status=notfoundusertonewsletterunsubscribe"
                            );
                            dispatch(setCloseLoadingBackdrop());
                        } else if (
                            error.response.status === StatusCodes.NOT_ACCEPTABLE
                        ) {
                            dispatch(
                                setOpenMessageDialog({
                                    severity:
                                        statusAlertsData
                                            .alreadyunsubscribedfromnewsletter
                                            .severity,
                                    title: statusAlertsData
                                        .alreadyunsubscribedfromnewsletter
                                        .title,
                                    message:
                                        statusAlertsData
                                            .alreadyunsubscribedfromnewsletter
                                            .message,
                                })
                            );
                            navigate(
                                "../login?status=alreadyunsubscribedfromnewsletter"
                            );
                            dispatch(setCloseLoadingBackdrop());
                        } else {
                            dispatch(
                                setOpenMessageDialog({
                                    severity:
                                        statusAlertsData
                                            .newsletterunsubscribeerror
                                            .severity,
                                    title: statusAlertsData
                                        .newsletterunsubscribeerror.title,
                                    message:
                                        statusAlertsData
                                            .newsletterunsubscribeerror.message,
                                })
                            );
                            navigate(
                                "../login?status=newsletterunsubscribeerror"
                            );
                            dispatch(setCloseLoadingBackdrop());
                        }
                    }
                }
            );
        } else {
            dispatch(
                setOpenMessageDialog({
                    severity:
                        statusAlertsData.newsletterunsubscribelinkempty
                            .severity,
                    title: statusAlertsData.newsletterunsubscribelinkempty
                        .title,
                    message:
                        statusAlertsData.newsletterunsubscribelinkempty.message,
                })
            );
            navigate("../login?status=newsletterunsubscribelinkempty");
            dispatch(setCloseLoadingBackdrop());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
};

export default NewsletterUnsubscribe;
