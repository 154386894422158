import CountryType from "../../types/common/countryType";

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries: readonly CountryType[] = [
    { code: "AD", label: "Andorra", phoneCode: "376" },
    {
        code: "AE",
        label: "United Arab Emirates",
        phoneCode: "971",
    },
    { code: "AF", label: "Afghanistan", phoneCode: "93" },
    {
        code: "AG",
        label: "Antigua and Barbuda",
        phoneCode: "1-268",
    },
    { code: "AI", label: "Anguilla", phoneCode: "1-264" },
    { code: "AL", label: "Albania", phoneCode: "355" },
    { code: "AM", label: "Armenia", phoneCode: "374" },
    { code: "AO", label: "Angola", phoneCode: "244" },
    { code: "AQ", label: "Antarctica", phoneCode: "672" },
    { code: "AR", label: "Argentina", phoneCode: "54" },
    { code: "AS", label: "American Samoa", phoneCode: "1-684" },
    { code: "AT", label: "Austria", phoneCode: "43" },
    {
        code: "AU",
        label: "Australia",
        phoneCode: "61",
    },
    { code: "AW", label: "Aruba", phoneCode: "297" },
    { code: "AX", label: "Alland Islands", phoneCode: "358" },
    { code: "AZ", label: "Azerbaijan", phoneCode: "994" },
    {
        code: "BA",
        label: "Bosnia and Herzegovina",
        phoneCode: "387",
    },
    { code: "BB", label: "Barbados", phoneCode: "1-246" },
    { code: "BD", label: "Bangladesh", phoneCode: "880" },
    { code: "BE", label: "Belgium", phoneCode: "32" },
    { code: "BF", label: "Burkina Faso", phoneCode: "226" },
    { code: "BG", label: "Bulgaria", phoneCode: "359" },
    { code: "BH", label: "Bahrain", phoneCode: "973" },
    { code: "BI", label: "Burundi", phoneCode: "257" },
    { code: "BJ", label: "Benin", phoneCode: "229" },
    { code: "BL", label: "Saint Barthelemy", phoneCode: "590" },
    { code: "BM", label: "Bermuda", phoneCode: "1-441" },
    { code: "BN", label: "Brunei Darussalam", phoneCode: "673" },
    { code: "BO", label: "Bolivia", phoneCode: "591" },
    { code: "BR", label: "Brazil", phoneCode: "55" },
    { code: "BS", label: "Bahamas", phoneCode: "1-242" },
    { code: "BT", label: "Bhutan", phoneCode: "975" },
    { code: "BV", label: "Bouvet Island", phoneCode: "47" },
    { code: "BW", label: "Botswana", phoneCode: "267" },
    { code: "BY", label: "Belarus", phoneCode: "375" },
    { code: "BZ", label: "Belize", phoneCode: "501" },
    {
        code: "CA",
        label: "Canada",
        phoneCode: "1",
    },
    {
        code: "CC",
        label: "Cocos (Keeling) Islands",
        phoneCode: "61",
    },
    {
        code: "CD",
        label: "Congo, Democratic Republic of the",
        phoneCode: "243",
    },
    {
        code: "CF",
        label: "Central African Republic",
        phoneCode: "236",
    },
    {
        code: "CG",
        label: "Congo, Republic of the",
        phoneCode: "242",
    },
    { code: "CH", label: "Switzerland", phoneCode: "41" },
    { code: "CI", label: "Cote d'Ivoire", phoneCode: "225" },
    { code: "CK", label: "Cook Islands", phoneCode: "682" },
    { code: "CL", label: "Chile", phoneCode: "56" },
    { code: "CM", label: "Cameroon", phoneCode: "237" },
    { code: "CN", label: "China", phoneCode: "86" },
    { code: "CO", label: "Colombia", phoneCode: "57" },
    { code: "CR", label: "Costa Rica", phoneCode: "506" },
    { code: "CU", label: "Cuba", phoneCode: "53" },
    { code: "CV", label: "Cape Verde", phoneCode: "238" },
    { code: "CW", label: "Curacao", phoneCode: "599" },
    { code: "CX", label: "Christmas Island", phoneCode: "61" },
    { code: "CY", label: "Cyprus", phoneCode: "357" },
    { code: "CZ", label: "Czech Republic", phoneCode: "420" },
    {
        code: "DE",
        label: "Germany",
        phoneCode: "49",
    },
    { code: "DJ", label: "Djibouti", phoneCode: "253" },
    { code: "DK", label: "Denmark", phoneCode: "45" },
    { code: "DM", label: "Dominica", phoneCode: "1-767" },
    {
        code: "DO",
        label: "Dominican Republic",
        phoneCode: "1-809",
    },
    { code: "DZ", label: "Algeria", phoneCode: "213" },
    { code: "EC", label: "Ecuador", phoneCode: "593" },
    { code: "EE", label: "Estonia", phoneCode: "372" },
    { code: "EG", label: "Egypt", phoneCode: "20" },
    { code: "EH", label: "Western Sahara", phoneCode: "212" },
    { code: "ER", label: "Eritrea", phoneCode: "291" },
    { code: "ES", label: "Spain", phoneCode: "34" },
    { code: "ET", label: "Ethiopia", phoneCode: "251" },
    { code: "FI", label: "Finland", phoneCode: "358" },
    { code: "FJ", label: "Fiji", phoneCode: "679" },
    {
        code: "FK",
        label: "Falkland Islands (Malvinas)",
        phoneCode: "500",
    },
    {
        code: "FM",
        label: "Micronesia, Federated States of",
        phoneCode: "691",
    },
    { code: "FO", label: "Faroe Islands", phoneCode: "298" },
    {
        code: "FR",
        label: "France",
        phoneCode: "33",
    },
    { code: "GA", label: "Gabon", phoneCode: "241" },
    { code: "GB", label: "United Kingdom", phoneCode: "44" },
    { code: "GD", label: "Grenada", phoneCode: "1-473" },
    { code: "GE", label: "Georgia", phoneCode: "995" },
    { code: "GF", label: "French Guiana", phoneCode: "594" },
    { code: "GG", label: "Guernsey", phoneCode: "44" },
    { code: "GH", label: "Ghana", phoneCode: "233" },
    { code: "GI", label: "Gibraltar", phoneCode: "350" },
    { code: "GL", label: "Greenland", phoneCode: "299" },
    { code: "GM", label: "Gambia", phoneCode: "220" },
    { code: "GN", label: "Guinea", phoneCode: "224" },
    { code: "GP", label: "Guadeloupe", phoneCode: "590" },
    { code: "GQ", label: "Equatorial Guinea", phoneCode: "240" },
    { code: "GR", label: "Greece", phoneCode: "30" },
    {
        code: "GS",
        label: "South Georgia and the South Sandwich Islands",
        phoneCode: "500",
    },
    { code: "GT", label: "Guatemala", phoneCode: "502" },
    { code: "GU", label: "Guam", phoneCode: "1-671" },
    { code: "GW", label: "Guinea-Bissau", phoneCode: "245" },
    { code: "GY", label: "Guyana", phoneCode: "592" },
    { code: "HK", label: "Hong Kong", phoneCode: "852" },
    {
        code: "HM",
        label: "Heard Island and McDonald Islands",
        phoneCode: "672",
    },
    { code: "HN", label: "Honduras", phoneCode: "504" },
    { code: "HR", label: "Croatia", phoneCode: "385" },
    { code: "HT", label: "Haiti", phoneCode: "509" },
    { code: "HU", label: "Magyarország", phoneCode: "36" },
    { code: "ID", label: "Indonesia", phoneCode: "62" },
    { code: "IE", label: "Ireland", phoneCode: "353" },
    { code: "IL", label: "Israel", phoneCode: "972" },
    { code: "IM", label: "Isle of Man", phoneCode: "44" },
    { code: "IN", label: "India", phoneCode: "91" },
    {
        code: "IO",
        label: "British Indian Ocean Territory",
        phoneCode: "246",
    },
    { code: "IQ", label: "Iraq", phoneCode: "964" },
    {
        code: "IR",
        label: "Iran, Islamic Republic of",
        phoneCode: "98",
    },
    { code: "IS", label: "Iceland", phoneCode: "354" },
    { code: "IT", label: "Italy", phoneCode: "39" },
    { code: "JE", label: "Jersey", phoneCode: "44" },
    { code: "JM", label: "Jamaica", phoneCode: "1-876" },
    { code: "JO", label: "Jordan", phoneCode: "962" },
    {
        code: "JP",
        label: "Japan",
        phoneCode: "81",
    },
    { code: "KE", label: "Kenya", phoneCode: "254" },
    { code: "KG", label: "Kyrgyzstan", phoneCode: "996" },
    { code: "KH", label: "Cambodia", phoneCode: "855" },
    { code: "KI", label: "Kiribati", phoneCode: "686" },
    { code: "KM", label: "Comoros", phoneCode: "269" },
    {
        code: "KN",
        label: "Saint Kitts and Nevis",
        phoneCode: "1-869",
    },
    {
        code: "KP",
        label: "Korea, Democratic People's Republic of",
        phoneCode: "850",
    },
    { code: "KR", label: "Korea, Republic of", phoneCode: "82" },
    { code: "KW", label: "Kuwait", phoneCode: "965" },
    { code: "KY", label: "Cayman Islands", phoneCode: "1-345" },
    { code: "KZ", label: "Kazakhstan", phoneCode: "7" },
    {
        code: "LA",
        label: "Lao People's Democratic Republic",
        phoneCode: "856",
    },
    { code: "LB", label: "Lebanon", phoneCode: "961" },
    { code: "LC", label: "Saint Lucia", phoneCode: "1-758" },
    { code: "LI", label: "Liechtenstein", phoneCode: "423" },
    { code: "LK", label: "Sri Lanka", phoneCode: "94" },
    { code: "LR", label: "Liberia", phoneCode: "231" },
    { code: "LS", label: "Lesotho", phoneCode: "266" },
    { code: "LT", label: "Lithuania", phoneCode: "370" },
    { code: "LU", label: "Luxembourg", phoneCode: "352" },
    { code: "LV", label: "Latvia", phoneCode: "371" },
    { code: "LY", label: "Libya", phoneCode: "218" },
    { code: "MA", label: "Morocco", phoneCode: "212" },
    { code: "MC", label: "Monaco", phoneCode: "377" },
    {
        code: "MD",
        label: "Moldova, Republic of",
        phoneCode: "373",
    },
    { code: "ME", label: "Montenegro", phoneCode: "382" },
    {
        code: "MF",
        label: "Saint Martin (French part)",
        phoneCode: "590",
    },
    { code: "MG", label: "Madagascar", phoneCode: "261" },
    { code: "MH", label: "Marshall Islands", phoneCode: "692" },
    {
        code: "MK",
        label: "Macedonia, the Former Yugoslav Republic of",
        phoneCode: "389",
    },
    { code: "ML", label: "Mali", phoneCode: "223" },
    { code: "MM", label: "Myanmar", phoneCode: "95" },
    { code: "MN", label: "Mongolia", phoneCode: "976" },
    { code: "MO", label: "Macao", phoneCode: "853" },
    {
        code: "MP",
        label: "Northern Mariana Islands",
        phoneCode: "1-670",
    },
    { code: "MQ", label: "Martinique", phoneCode: "596" },
    { code: "MR", label: "Mauritania", phoneCode: "222" },
    { code: "MS", label: "Montserrat", phoneCode: "1-664" },
    { code: "MT", label: "Malta", phoneCode: "356" },
    { code: "MU", label: "Mauritius", phoneCode: "230" },
    { code: "MV", label: "Maldives", phoneCode: "960" },
    { code: "MW", label: "Malawi", phoneCode: "265" },
    { code: "MX", label: "Mexico", phoneCode: "52" },
    { code: "MY", label: "Malaysia", phoneCode: "60" },
    { code: "MZ", label: "Mozambique", phoneCode: "258" },
    { code: "NA", label: "Namibia", phoneCode: "264" },
    { code: "NC", label: "New Caledonia", phoneCode: "687" },
    { code: "NE", label: "Niger", phoneCode: "227" },
    { code: "NF", label: "Norfolk Island", phoneCode: "672" },
    { code: "NG", label: "Nigeria", phoneCode: "234" },
    { code: "NI", label: "Nicaragua", phoneCode: "505" },
    { code: "NL", label: "Netherlands", phoneCode: "31" },
    { code: "NO", label: "Norway", phoneCode: "47" },
    { code: "NP", label: "Nepal", phoneCode: "977" },
    { code: "NR", label: "Nauru", phoneCode: "674" },
    { code: "NU", label: "Niue", phoneCode: "683" },
    { code: "NZ", label: "New Zealand", phoneCode: "64" },
    { code: "OM", label: "Oman", phoneCode: "968" },
    { code: "PA", label: "Panama", phoneCode: "507" },
    { code: "PE", label: "Peru", phoneCode: "51" },
    { code: "PF", label: "French Polynesia", phoneCode: "689" },
    { code: "PG", label: "Papua New Guinea", phoneCode: "675" },
    { code: "PH", label: "Philippines", phoneCode: "63" },
    { code: "PK", label: "Pakistan", phoneCode: "92" },
    { code: "PL", label: "Poland", phoneCode: "48" },
    {
        code: "PM",
        label: "Saint Pierre and Miquelon",
        phoneCode: "508",
    },
    { code: "PN", label: "Pitcairn", phoneCode: "870" },
    { code: "PR", label: "Puerto Rico", phoneCode: "1" },
    {
        code: "PS",
        label: "Palestine, State of",
        phoneCode: "970",
    },
    { code: "PT", label: "Portugal", phoneCode: "351" },
    { code: "PW", label: "Palau", phoneCode: "680" },
    { code: "PY", label: "Paraguay", phoneCode: "595" },
    { code: "QA", label: "Qatar", phoneCode: "974" },
    { code: "RE", label: "Reunion", phoneCode: "262" },
    { code: "RO", label: "Romania", phoneCode: "40" },
    { code: "RS", label: "Serbia", phoneCode: "381" },
    { code: "RU", label: "Russian Federation", phoneCode: "7" },
    { code: "RW", label: "Rwanda", phoneCode: "250" },
    { code: "SA", label: "Saudi Arabia", phoneCode: "966" },
    { code: "SB", label: "Solomon Islands", phoneCode: "677" },
    { code: "SC", label: "Seychelles", phoneCode: "248" },
    { code: "SD", label: "Sudan", phoneCode: "249" },
    { code: "SE", label: "Sweden", phoneCode: "46" },
    { code: "SG", label: "Singapore", phoneCode: "65" },
    { code: "SH", label: "Saint Helena", phoneCode: "290" },
    { code: "SI", label: "Slovenia", phoneCode: "386" },
    {
        code: "SJ",
        label: "Svalbard and Jan Mayen",
        phoneCode: "47",
    },
    { code: "SK", label: "Slovakia", phoneCode: "421" },
    { code: "SL", label: "Sierra Leone", phoneCode: "232" },
    { code: "SM", label: "San Marino", phoneCode: "378" },
    { code: "SN", label: "Senegal", phoneCode: "221" },
    { code: "SO", label: "Somalia", phoneCode: "252" },
    { code: "SR", label: "Suriname", phoneCode: "597" },
    { code: "SS", label: "South Sudan", phoneCode: "211" },
    {
        code: "ST",
        label: "Sao Tome and Principe",
        phoneCode: "239",
    },
    { code: "SV", label: "El Salvador", phoneCode: "503" },
    {
        code: "SX",
        label: "Sint Maarten (Dutch part)",
        phoneCode: "1-721",
    },
    {
        code: "SY",
        label: "Syrian Arab Republic",
        phoneCode: "963",
    },
    { code: "SZ", label: "Swaziland", phoneCode: "268" },
    {
        code: "TC",
        label: "Turks and Caicos Islands",
        phoneCode: "1-649",
    },
    { code: "TD", label: "Chad", phoneCode: "235" },
    {
        code: "TF",
        label: "French Southern Territories",
        phoneCode: "262",
    },
    { code: "TG", label: "Togo", phoneCode: "228" },
    { code: "TH", label: "Thailand", phoneCode: "66" },
    { code: "TJ", label: "Tajikistan", phoneCode: "992" },
    { code: "TK", label: "Tokelau", phoneCode: "690" },
    { code: "TL", label: "Timor-Leste", phoneCode: "670" },
    { code: "TM", label: "Turkmenistan", phoneCode: "993" },
    { code: "TN", label: "Tunisia", phoneCode: "216" },
    { code: "TO", label: "Tonga", phoneCode: "676" },
    { code: "TR", label: "Turkey", phoneCode: "90" },
    {
        code: "TT",
        label: "Trinidad and Tobago",
        phoneCode: "1-868",
    },
    { code: "TV", label: "Tuvalu", phoneCode: "688" },
    {
        code: "TW",
        label: "Taiwan, Province of China",
        phoneCode: "886",
    },
    {
        code: "TZ",
        label: "United Republic of Tanzania",
        phoneCode: "255",
    },
    { code: "UA", label: "Ukraine", phoneCode: "380" },
    { code: "UG", label: "Uganda", phoneCode: "256" },
    {
        code: "US",
        label: "United States",
        phoneCode: "1",
    },
    { code: "UY", label: "Uruguay", phoneCode: "598" },
    { code: "UZ", label: "Uzbekistan", phoneCode: "998" },
    {
        code: "VA",
        label: "Holy See (Vatican City State)",
        phoneCode: "379",
    },
    {
        code: "VC",
        label: "Saint Vincent and the Grenadines",
        phoneCode: "1-784",
    },
    { code: "VE", label: "Venezuela", phoneCode: "58" },
    {
        code: "VG",
        label: "British Virgin Islands",
        phoneCode: "1-284",
    },
    {
        code: "VI",
        label: "US Virgin Islands",
        phoneCode: "1-340",
    },
    { code: "VN", label: "Vietnam", phoneCode: "84" },
    { code: "VU", label: "Vanuatu", phoneCode: "678" },
    { code: "WF", label: "Wallis and Futuna", phoneCode: "681" },
    { code: "WS", label: "Samoa", phoneCode: "685" },
    { code: "XK", label: "Kosovo", phoneCode: "383" },
    { code: "YE", label: "Yemen", phoneCode: "967" },
    { code: "YT", label: "Mayotte", phoneCode: "262" },
    { code: "ZA", label: "South Africa", phoneCode: "27" },
    { code: "ZM", label: "Zambia", phoneCode: "260" },
    { code: "ZW", label: "Zimbabwe", phoneCode: "263" },
];
