import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../states/hooks";
import { RootState } from "../../states/store";
import {
    setActiveMenuItem,
    setOpenLoadingBackdrop,
    setCloseLoadingBackdrop,
    setOpenMessageDialog,
    setOpenNotificationSnackbar,
} from "../../states/common/coreSlice";
import { client } from "../../configs/endpoints";
import { statusAlertsData } from "../../datas/common/statusAlertsData";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    ViberShareButton,
    ViberIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon,
} from "react-share";

const Affiliate = () => {
    const dispatch = useAppDispatch();

    const userProfile = useAppSelector(
        (state: RootState) => state.user.profile
    );

    const [referralLink, setReferralLink] = useState<string>("");
    const [onMouse, setOnMouse] = useState<boolean>(false);

    useEffect(() => {
        dispatch(
            setOpenLoadingBackdrop({
                message: "ajánlói link generálása folyamatban!",
            })
        );
        if (userProfile && userProfile.referralId) {
            setReferralLink(client + "/?referral=" + userProfile.referralId);
        } else {
            dispatch(
                setOpenMessageDialog({
                    severity: statusAlertsData.referrallinknerror.severity,
                    title: statusAlertsData.referrallinknerror.title,
                    message: statusAlertsData.referrallinknerror.message,
                })
            );
        }
        dispatch(setCloseLoadingBackdrop());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    useEffect(() => {
        dispatch(setActiveMenuItem("affiliate"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleMouseEnter = () => {
        setOnMouse(true);
    };

    const handleMouseLeave = () => {
        setOnMouse(false);
    };

    const handleClickCopy = () => {
        dispatch(
            setOpenNotificationSnackbar({
                severity: statusAlertsData.referrallinkcopied.severity,
                title: statusAlertsData.referrallinkcopied.title,
                message: statusAlertsData.referrallinkcopied.message,
            })
        );
    };

    return (
        <Container
            component="main"
            sx={{
                overflow: "hidden",
                mb: 2,
                pt: 2,
                pb: 2,
                boxShadow: 2,
                borderRadius: 2,
                background: "#0D0D45",
            }}
            maxWidth="md"
        >
            <Typography
                variant="h5"
                component="h1"
                gutterBottom
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    color: "#DCC28E",
                }}
            >
                <Diversity1Icon
                    sx={{
                        mr: 1,
                        width: 27,
                        height: 27,
                    }}
                />
                Affiliate program
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
                Egyedi ajánló linkje segítségével könnyedén népszerűsítheti az
                Automatizált Kriptovaluta Kereskedés oldalát.
            </Typography>
            {referralLink && referralLink !== "" ? (
                <>
                    <CopyToClipboard
                        text={referralLink}
                        onCopy={handleClickCopy}
                    >
                        <Box
                            sx={{
                                overflow: "hidden",
                                mt: 3,
                                mb: 3,
                                p: 2,
                                border: 1,
                                borderRadius: 2,
                                borderColor: "#eee",
                                cursor: "copy",
                                "&:hover": {
                                    color: "#00FF80",
                                    // background: "#eee",
                                },
                            }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onTouchStart={handleMouseEnter}
                            onTouchEnd={handleMouseLeave}
                        >
                            <Stack
                                direction={{ xs: "column", sm: "row" }}
                                justifyContent="space-between"
                                alignItems={{
                                    xs: "left",
                                    sm: "center",
                                }}
                                sx={{
                                    minHeight: "33px",
                                    fontSize: "1em !important",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    component="span"
                                    fontSize={{
                                        xs: "0.8em !important",
                                        sm: "1em !important",
                                    }}
                                    sx={{
                                        overflow: "hidden",
                                        flexDirection: "row",
                                        color: "inherit",
                                    }}
                                >
                                    {referralLink}
                                </Typography>
                                {onMouse ? (
                                    <Typography
                                        variant="button"
                                        component="button"
                                        mt={{ xs: 1, sm: 0 }}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            pl: 1,
                                            pr: 1,
                                            border: 1,
                                            borderRadius: 2,
                                            borderColor: "#00FF80",
                                            cursor: "inherit",
                                            color: "inherit",
                                            background: "#0D0C42",
                                        }}
                                    >
                                        <ContentCopyIcon
                                            sx={{
                                                mr: 1,
                                                width: 15,
                                                height: 15,
                                            }}
                                        />
                                        másolás
                                    </Typography>
                                ) : null}
                            </Stack>
                        </Box>
                    </CopyToClipboard>
                    <Typography variant="body1" component="p" gutterBottom>
                        Az alábbi QR kód tartalmazza az ajánli linkjét:
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            // height: "auto",
                            width: "100%",
                            maxWidth: "256px",
                            ml: "auto",
                            mr: "auto",
                            mt: 2,
                            mb: 3,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <QRCode
                            value={referralLink}
                            size={256}
                            style={{
                                height: "auto",
                                maxWidth: "100%",
                                width: "100%",
                            }}
                            viewBox={`0 0 256 256`}
                        />
                    </Box>
                    <Typography
                        variant="body1"
                        component="p"
                        gutterBottom
                        sx={{ mb: 2 }}
                    >
                        Ajánli linkjét meg tudja osztani ismerőseivel,
                        barátaival bármelyik platformon.
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <EmailShareButton
                            url={referralLink}
                            subject="Regisztráljon az Automatizált Kriptovaluta Kereskedésre."
                            body=""
                        >
                            <EmailIcon size={32} round />
                        </EmailShareButton>
                        <FacebookShareButton url={referralLink}>
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        {true ? (
                            // TODO Facebook app id szükséges
                            <FacebookMessengerShareButton
                                url={referralLink}
                                appId=""
                            >
                                <FacebookMessengerIcon size={32} round />
                            </FacebookMessengerShareButton>
                        ) : null}
                        <ViberShareButton url={referralLink} title="Viber">
                            <ViberIcon size={32} round />
                        </ViberShareButton>
                        <WhatsappShareButton
                            url={referralLink}
                            title="WhatsUp"
                            separator=":: "
                        >
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                        <LinkedinShareButton
                            url={referralLink}
                            title="LinkedIn"
                        >
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                    </Stack>
                </>
            ) : null}
        </Container>
    );
};

export default Affiliate;
