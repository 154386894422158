import RegistrationFormErrorsType from "../../types/home/registrationFormErrorsType";

export const initialRegistrationFormErrors: RegistrationFormErrorsType = {
    lastName: null,
    firstName: null,
    country: null,
    zip: null,
    email: null,
    phone: null,
    password: null,
    passwordAgain: null,
    termsAccepted: null,
};
