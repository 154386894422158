import { FunctionComponent, useState, useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
// import { useLocation } from "react-router-dom";

const AnimatedTitle: FunctionComponent<any> = (props: any) => {
    const titles: string[] = JSON.parse(props.titles);
    const [titlesIndex, setTitlesIndex] = useState<number>(0);
    const ctrls = useAnimation();
    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    // const location = useLocation();

    useEffect(() => {
        const timers = [
            setTimeout(() => {
                ctrls.start("visible");
            }, 500),
            setTimeout(() => {
                ctrls.start("hidden");
            }, 5000),
        ];

        return () => timers.forEach(clearTimeout); // Töröljük a időzítőket, ha a komponens lecsatolódik
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     let visibleTimeout: ReturnType<typeof setTimeout> | undefined;
    //     let hiddenTimeout: ReturnType<typeof setTimeout> | undefined;

    //     if (inView) {
    //         // console.log("inView");
    //         const tick = () => {
    //             if (titles[titlesIndex]) {
    //                 // console.log("⏱ tick");
    //                 visibleTimeout = setTimeout(
    //                     () => ctrls.start("visible"),
    //                     500
    //                 );
    //                 hiddenTimeout = setTimeout(
    //                     () => ctrls.start("hidden"),
    //                     5000
    //                 );
    //             } else {
    //                 ctrls.start("hidden");
    //             }
    //         };
    //         const interval = setInterval(tick, 6300);
    //         return () => {
    //             clearInterval(interval);
    //             if (visibleTimeout !== undefined) clearTimeout(visibleTimeout);
    //             if (hiddenTimeout !== undefined) clearTimeout(hiddenTimeout);
    //         };
    //     } else {
    //         if (visibleTimeout !== undefined) clearTimeout(visibleTimeout);
    //         if (hiddenTimeout !== undefined) clearTimeout(hiddenTimeout);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [ctrls, inView, titles, titlesIndex]);

    const titleContainer = {
        hidden: { opacity: 0 },
        visible: (i = 1) => ({
            opacity: 1,
            transition: { staggerChildren: 0.01, delayChildren: 0.0002 * i },
        }),
    };

    const wordsContainer = {
        hidden: { opacity: 0 },
        visible: (i = 1) => ({
            opacity: 1,
            transition: { staggerChildren: 0.01, delayChildren: 0.02 * i },
        }),
    };

    const wordAnimation = {
        hidden: {},
        visible: {},
    };

    const characterAnimation = {
        visible: {
            opacity: 1,
            x: 0,
            y: 0,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 100,
            },
        },
        hidden: {
            opacity: 0,
            x: -20,
            y: 10,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 100,
            },
        },
    };

    return (
        <>
            <motion.div
                variants={titleContainer}
                ref={ref}
                initial="hidden"
                animate="visible"
            >
                {titles[titlesIndex]
                    ? titles[titlesIndex].split("<br>").map((words, index) => (
                          <motion.div
                              style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  fontSize: "3rem",
                                  fontWeight: 600,
                                  color: "#eee",
                                  justifyContent: "center",
                                  alignItems: "center",
                              }}
                              key={"rows-" + index}
                              variants={wordsContainer}
                              initial="hidden"
                              animate="visible"
                          >
                              {words.split(" ").map((word, index) => (
                                  <motion.span
                                      aria-hidden="true"
                                      key={"word-" + index}
                                      initial="hidden"
                                      animate={ctrls}
                                      variants={wordAnimation}
                                      transition={{
                                          delayChildren: index * 0.25,
                                          staggerChildren: 0.05,
                                      }}
                                      style={{
                                          display: "flex",
                                          marginRight: "0.25em",
                                      }}
                                  >
                                      {word
                                          .split("")
                                          .map((character, index) => {
                                              return (
                                                  <motion.span
                                                      aria-hidden="true"
                                                      key={"character-" + index}
                                                      variants={
                                                          characterAnimation
                                                      }
                                                  >
                                                      {character}
                                                  </motion.span>
                                              );
                                          })}
                                  </motion.span>
                              ))}
                          </motion.div>
                      ))
                    : null}
            </motion.div>
        </>
    );
};
export default AnimatedTitle;
