import { useEffect, useState } from "react";
import { useAppSelector } from "../../states/hooks";
import { RootState } from "../../states/store";
import ReactApexChart from "react-apexcharts";
import apexchart, { ApexOptions } from "apexcharts";

const VolumeBarChart = () => {
    const candles = useAppSelector((state: RootState) => state.stream.candles);

    const [barChartSeries, setBarChartSeries] = useState<any>([
        {
            data: [],
        },
    ]);

    const barChartOptions: ApexOptions = {
        chart: {
            id: "bar-chart",
            type: "bar",
            foreColor: "#eee",
            background: "#010138",
            animations: {
                enabled: false,
            },
            dropShadow: {
                enabled: false,
                enabledOnSeries: undefined,
            },
            toolbar: {
                show: false,
            },
        },
        grid: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
        stroke: {
            width: 1,
            curve: "smooth",
        },
        colors: ["#D67ED0"],
        xaxis: {
            type: "datetime",
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: { decimalsInFloat: 0 },
    };

    const getNewBarChartSeries = () => {
        return [
            {
                data: candles.map((candle) => {
                    return {
                        x: new Date(candle.timestamp),
                        y: candle.volume,
                    };
                }),
            },
        ];
    };

    useEffect(() => {
        setBarChartSeries(getNewBarChartSeries());
        apexchart.exec("bar-chart", "updateSeries", barChartSeries);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [candles]);

    return (
        <ReactApexChart
            options={barChartOptions}
            series={barChartSeries}
            type="bar"
            width="100%"
            height={100}
        />
    );
};

export default VolumeBarChart;
