import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useAppDispatch } from "./states/hooks";
import { setActiveLanguage } from "./states/common/coreSlice";
import ProtectedRoute from "./routes/ProtectedRoute";
import Home from "./pages/home/Home";
import Main from "./pages/main/Main";
import LoadingBackdrop from "./components/common/LoadingBackdrop";
import MessageDialog from "./components/common/MessageDialog";
import NotificationSnackbar from "./components/common/NotificationSnackbar";
import { LanguageEnum } from "./enums/common/languageEnum";

const App = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const localStorageUserLanguage = localStorage.getItem(
            "actLanguge"
        ) as LanguageEnum;

        if (
            localStorageUserLanguage &&
            Object.values<string>(LanguageEnum).includes(
                localStorageUserLanguage
            )
        ) {
            dispatch(setActiveLanguage(localStorageUserLanguage));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <LoadingBackdrop />
            <MessageDialog />
            <NotificationSnackbar />
            <Routes>
                <Route path="/*" element={<Home />} />
                <Route element={<ProtectedRoute />}>
                    <Route path={"/main/*"} element={<Main />} />
                </Route>
            </Routes>
        </>
    );
};

export default App;
