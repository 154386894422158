import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WebSocketConnectionState } from "../../enums/main/webSocketConnectionStateEnum";
import { CryptocurrencyPairEnum } from "../../enums/main/cryptocurrencyPairEnum";
import { StreamFrequency } from "../../enums/main/streamFrequencyEnum";
import CandleType from "../../types/main/candleType";
import InitialStreamState, {
    UpdateStreamAction,
} from "../../types/redux/main/streamType";

const initialStreamState: InitialStreamState = {
    connectionState: null,
    cryptocurrencyPair: CryptocurrencyPairEnum.BTCUSDC,
    frequency: StreamFrequency.ONE_SECOND,
    numberOfCandles: 70,
    candles: [],
};

export const streamSlice = createSlice({
    name: UpdateStreamAction,
    initialState: initialStreamState,
    reducers: {
        setWebSocketConnecting: (state) => {
            state.connectionState = WebSocketConnectionState.CONNECTING;
        },
        setWebSocketOpen: (state) => {
            state.connectionState = WebSocketConnectionState.OPEN;
        },
        setWebSocketClosed: (state) => {
            state.connectionState = WebSocketConnectionState.CLOSED;
        },
        setWebSocketReconnecting: (state) => {
            state.connectionState = WebSocketConnectionState.RECONNECTING;
        },
        setCryptocurrencyPair: (
            state,
            action: PayloadAction<CryptocurrencyPairEnum>
        ) => {
            state.cryptocurrencyPair = action.payload;
        },
        setStreamFrequency: (state, action: PayloadAction<StreamFrequency>) => {
            state.frequency = action.payload;
        },
        setNumberOfCandles: (state, action: PayloadAction<number>) => {
            state.numberOfCandles = action.payload;
        },
        addCandle: (
            state,
            action: PayloadAction<CandleType | null | undefined>
        ) => {
            if (
                action.payload !== undefined &&
                action.payload !== null &&
                typeof action.payload === "object"
            ) {
                if (state.candles.length >= state.numberOfCandles) {
                    state.candles.shift();
                }
                state.candles.push(action.payload);
            }
        },
        setCleanCandles: (state) => {
            state.candles = [];
        },
        setCleanStream: (state) => {
            state.connectionState = initialStreamState.connectionState;
            state.cryptocurrencyPair = initialStreamState.cryptocurrencyPair;
            state.frequency = initialStreamState.frequency;
            state.numberOfCandles = initialStreamState.numberOfCandles;
            state.candles = [];
        },
    },
});

// Action creators are generated for each case reducer function
export const { setWebSocketConnecting } = streamSlice.actions;
export const { setWebSocketOpen } = streamSlice.actions;
export const { setWebSocketClosed } = streamSlice.actions;
export const { setWebSocketReconnecting } = streamSlice.actions;
export const { setCryptocurrencyPair } = streamSlice.actions;
export const { setStreamFrequency } = streamSlice.actions;
export const { setNumberOfCandles } = streamSlice.actions;
export const { addCandle } = streamSlice.actions;
export const { setCleanCandles } = streamSlice.actions;
export const { setCleanStream } = streamSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default streamSlice.reducer;
