import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import WelcomeBackgroundImage from "../../assets/welcome-bg.jpg";
import AnimatedTitle from "../../components/common/AnimatedTitle";

const Welcome = () => {
    const titles = [
        "Üdvözöljük az<br>Automatizált Kriptovaluta Kereskedés<br>oldalán!",
        "Professzionális<br>vételi és eladási<br>stratégiák",
        "Automatizált kereskedés<br>a nap 24 órájában,<br>az év 365 napján",
        "Magasan titkosított kapcsolat<br>a kriptovaluta tőzsdékkel",
        "Egyszerű és felhasználóbarát felület",
        "Érzelemmentes kereskedés<br>előre beállított stratégiák mentén",
        "Nincs tranzakciós korlát",
    ];
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: `calc(100vh - 130px)`,
                backgroundImage: `url(${WelcomeBackgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <CssBaseline />
            <Box
                sx={{
                    mt: 15,
                }}
            >
                <Grid container spacing={0}>
                    <Grid item xs={0} sm={4}></Grid>
                    <Grid item xs={12} sm={8}>
                        <AnimatedTitle titles={JSON.stringify(titles)} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Welcome;
