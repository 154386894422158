import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../states/hooks";
import { setActiveMenuItem } from "../../states/common/coreSlice";
import {
    setOpenLoadingBackdrop,
    setCloseLoadingBackdrop,
    setOpenMessageDialog,
} from "../../states/common/coreSlice";
import { StatusCodes } from "http-status-codes";
import { statusAlertsData } from "../../datas/common/statusAlertsData";
import { validation } from "../../services/home/RegistrationService";

const Validation = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [searchParams] = useSearchParams();
    const validationId = searchParams.get("id");

    useEffect(() => {
        dispatch(setActiveMenuItem("validation"));
        if (validationId && validationId !== "") {
            dispatch(
                setOpenLoadingBackdrop({
                    message: "validálás folyamatban!",
                })
            );
            validation(validationId).then(
                () => {
                    dispatch(
                        setOpenMessageDialog({
                            severity: statusAlertsData.validated.severity,
                            title: statusAlertsData.validated.title,
                            message: statusAlertsData.validated.message,
                        })
                    );
                    navigate("../login?status=validated");
                    dispatch(setCloseLoadingBackdrop());
                },
                (error) => {
                    if (error && error.response && error.response.status) {
                        if (error.response.status === StatusCodes.BAD_REQUEST) {
                            dispatch(
                                setOpenMessageDialog({
                                    severity:
                                        statusAlertsData.validationlinkempty
                                            .severity,
                                    title: statusAlertsData.validationlinkempty
                                        .title,
                                    message:
                                        statusAlertsData.validationlinkempty
                                            .message,
                                })
                            );
                            navigate("../login?status=validationlinkempty");
                            dispatch(setCloseLoadingBackdrop());
                        } else if (
                            error.response.status === StatusCodes.NOT_FOUND
                        ) {
                            dispatch(
                                setOpenMessageDialog({
                                    severity:
                                        statusAlertsData.notfoundusertovalidate
                                            .severity,
                                    title: statusAlertsData
                                        .notfoundusertovalidate.title,
                                    message:
                                        statusAlertsData.notfoundusertovalidate
                                            .message,
                                })
                            );
                            navigate("../login?status=notfoundusertovalidate");
                            dispatch(setCloseLoadingBackdrop());
                        } else if (
                            error.response.status === StatusCodes.NOT_ACCEPTABLE
                        ) {
                            dispatch(
                                setOpenMessageDialog({
                                    severity:
                                        statusAlertsData.alreadyvalidated
                                            .severity,
                                    title: statusAlertsData.alreadyvalidated
                                        .title,
                                    message:
                                        statusAlertsData.alreadyvalidated
                                            .message,
                                })
                            );
                            navigate("../login?status=alreadyvalidated");
                            dispatch(setCloseLoadingBackdrop());
                        } else {
                            dispatch(
                                setOpenMessageDialog({
                                    severity:
                                        statusAlertsData.validationerror
                                            .severity,
                                    title: statusAlertsData.validationerror
                                        .title,
                                    message:
                                        statusAlertsData.validationerror
                                            .message,
                                })
                            );
                            navigate("../login?status=validationerror");
                            dispatch(setCloseLoadingBackdrop());
                        }
                    }
                }
            );
        } else {
            dispatch(
                setOpenMessageDialog({
                    severity: statusAlertsData.validationlinkempty.severity,
                    title: statusAlertsData.validationlinkempty.title,
                    message: statusAlertsData.validationlinkempty.message,
                })
            );
            navigate("../login?status=validationlinkempty");
            dispatch(setCloseLoadingBackdrop());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
};

export default Validation;
