import {
    SyntheticEvent,
    useEffect,
    useState,
    ChangeEvent,
    FormEvent,
} from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../states/hooks";
import { RootState } from "../../states/store";
import {
    setActiveMenuItem,
    setOpenLoadingBackdrop,
    setCloseLoadingBackdrop,
    setOpenNotificationSnackbar,
} from "../../states/common/coreSlice";
import ExchangeType from "../../types/main/exchangeType";
import ExchangeFormValuesType from "../../types/main/exchangeFormValuesType";
import ExchangeFormErrorsType from "../../types/main/exchangeFormErrorsType";
import { initialExchangeFormValues } from "../../datas/main/initialExchangeFormValues";
import { initialExchangeFormErrors } from "../../datas/main/initialExchangeFormErrors";
import { validateExchangeForm } from "../../utils/main/validateExchangeFormUtil";
import {
    createExchange,
    readUserExchanges,
    testExchangeConnection,
    deleteExchange,
} from "../../services/main/ExchangeService";
import {
    ExchangePlatform,
    ExchangeStatus,
} from "../../enums/main/exchangeEnum";
import { statusAlertsData } from "../../datas/common/statusAlertsData";
import { StatusCodes } from "http-status-codes";
import { pink } from "@mui/material/colors";
import Container from "@mui/material/Container";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import QRCode from "react-qr-code";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BinanceLogoImage from "../../assets/binance-logo.png";
import BinanceIconLogoImage from "../../assets/binance-icon-logo.png";
import BinanceGuideApiImage1 from "../../assets/binance-guide-api-1.png";
import BinanceGuideApiImage2 from "../../assets/binance-guide-api-2.png";
import BinanceGuideApiImage3 from "../../assets/binance-guide-api-3.png";
import BinanceGuideApiImage4 from "../../assets/binance-guide-api-4.png";
import CoinbaseIconLogoImage from "../../assets/coinbase-icon-logo.png";
import CryptocomIconLogoImage from "../../assets/cryptocom-icon-logo.png";

const Exchanges = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const userProfile = useAppSelector(
        (state: RootState) => state.user.profile
    );

    const binanceReferralLink =
        "https://accounts.binance.com/en/register?ref=393150390";
    const binance2FAGuideLink =
        "https://academy.binance.com/en/articles/binance-2fa-guide";

    const [formValues, setFormValues] = useState<ExchangeFormValuesType>(
        initialExchangeFormValues
    );
    const [formErrors, setFormErrors] = useState<ExchangeFormErrorsType>(
        initialExchangeFormErrors
    );

    const [isInit, setIsInit] = useState<boolean>(false);
    const [isTested, setIsTested] = useState<boolean>(false);
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [lastExchangeUpdate, setLastExchangeUpdate] = useState<Date | null>();
    const [exchanges, setExchanges] = useState<ExchangeType[]>([]);
    const [exchangeToDelete, setExchangeToDelete] = useState<string | null>(
        null
    );

    const [expanded, setExpanded] = useState<string | false>("panel1");

    useEffect(() => {
        dispatch(setActiveMenuItem("exchanges"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(setActiveMenuItem("exchanges"));
        if (userProfile && userProfile.email && exchangeToDelete === null) {
            dispatch(
                setOpenLoadingBackdrop({
                    message: "kriptovaluta tőzsde kapcsolat ellenőrzése!",
                })
            );
            readUserExchanges().then(
                (response) => {
                    setExchanges(response.data);
                    setIsInit(true);
                    dispatch(setCloseLoadingBackdrop());
                },
                (error) => {
                    // console.log(error)
                    setExchanges([]);
                    setIsInit(true);
                    dispatch(setCloseLoadingBackdrop());
                    if (error && error.response && error.response.status) {
                        if (
                            error.response.status === StatusCodes.UNAUTHORIZED
                        ) {
                            navigate("../logout");
                        } else {
                            //
                        }
                    }
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile, lastExchangeUpdate]);

    useEffect(() => {
        if (isTested) {
            setFormErrors(validateExchangeForm(formValues));
        }
    }, [formValues, isTested]);

    useEffect(() => {
        if (exchangeToDelete) {
            deleteExchange(exchangeToDelete).then(
                () => {
                    setLastExchangeUpdate(new Date());
                    setExchangeToDelete(null);
                    dispatch(
                        setOpenNotificationSnackbar({
                            severity: statusAlertsData.exchangedeleted.severity,
                            title: statusAlertsData.exchangedeleted.title,
                            message: statusAlertsData.exchangedeleted.message,
                        })
                    );
                },
                (error) => {
                    // console.log(error);
                    setLastExchangeUpdate(new Date());
                    if (error && error.response && error.response.status) {
                        if (
                            error.response.status === StatusCodes.UNAUTHORIZED
                        ) {
                            navigate("../logout");
                        } else if (
                            error.response.status === StatusCodes.NOT_FOUND
                        ) {
                            setOpenNotificationSnackbar({
                                severity:
                                    statusAlertsData.exchangenotfound.severity,
                                title: statusAlertsData.exchangenotfound.title,
                                message:
                                    statusAlertsData.exchangenotfound.message,
                            });
                        } else {
                            //
                        }
                    }
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exchangeToDelete]);

    const handleExpandChange =
        (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const handleFormChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsConnected(false);
        setFormValues({
            ...formValues,
            [event.target.name]: event.target.value,
        });
    };

    const getPlatformIconLogoImage = (exchangePlatform: ExchangePlatform) => {
        switch (exchangePlatform) {
            case ExchangePlatform.BINANCE:
                return BinanceIconLogoImage;
            case ExchangePlatform.COINBASE:
                return CoinbaseIconLogoImage;
            case ExchangePlatform.CRYPTOCOM:
                return CryptocomIconLogoImage;
            default:
                return BinanceIconLogoImage;
        }
    };

    const openInNewTab = (url: string) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsTested(true);
        const formErrorsObject: ExchangeFormErrorsType =
            validateExchangeForm(formValues);
        setFormErrors(formErrorsObject);
        if (Object.values(formErrorsObject).every((x) => !x)) {
            if (isConnected) {
                dispatch(
                    setOpenLoadingBackdrop({
                        message: "kapcsolat rögzítése folyamatban!",
                    })
                );
                createExchange(formValues).then(
                    () => {
                        setIsConnected(true);
                        setLastExchangeUpdate(new Date());
                        dispatch(setCloseLoadingBackdrop());
                        dispatch(
                            setOpenNotificationSnackbar({
                                severity:
                                    statusAlertsData.exchangecreated.severity,
                                title: statusAlertsData.exchangecreated.title,
                                message:
                                    statusAlertsData.exchangecreated.message,
                            })
                        );
                    },
                    (error) => {
                        // console.log(error);
                        setLastExchangeUpdate(new Date());
                        dispatch(setCloseLoadingBackdrop());
                        if (error && error.response && error.response.status) {
                            if (
                                error.response.status ===
                                StatusCodes.UNAUTHORIZED
                            ) {
                                navigate("../logout");
                            } else if (
                                error.response.status ===
                                StatusCodes.EXPECTATION_FAILED
                            ) {
                                setIsConnected(false);
                                dispatch(
                                    setOpenNotificationSnackbar({
                                        severity:
                                            statusAlertsData
                                                .exchangeconnectiontesterror
                                                .severity,
                                        title: statusAlertsData
                                            .exchangeconnectiontesterror.title,
                                        message:
                                            statusAlertsData
                                                .exchangeconnectiontesterror
                                                .message,
                                    })
                                );
                            } else if (
                                error.response.status === StatusCodes.CONFLICT
                            ) {
                                setIsConnected(false);
                                dispatch(
                                    setOpenNotificationSnackbar({
                                        severity:
                                            statusAlertsData.exchangalreadyinuse
                                                .severity,
                                        title: statusAlertsData
                                            .exchangalreadyinuse.title,
                                        message:
                                            statusAlertsData.exchangalreadyinuse
                                                .message,
                                    })
                                );
                            } else {
                                dispatch(
                                    setOpenNotificationSnackbar({
                                        severity:
                                            statusAlertsData
                                                .exchangeconnectiontesterror
                                                .severity,
                                        title: statusAlertsData
                                            .exchangeconnectiontesterror.title,
                                        message:
                                            statusAlertsData
                                                .exchangeconnectiontesterror
                                                .message,
                                    })
                                );
                            }
                        }
                    }
                );
            } else {
                dispatch(
                    setOpenLoadingBackdrop({
                        message: "kapcsolat tesztelése folyamatban!",
                    })
                );
                testExchangeConnection(formValues).then(
                    () => {
                        setIsConnected(true);
                        dispatch(setCloseLoadingBackdrop());
                        dispatch(
                            setOpenNotificationSnackbar({
                                severity:
                                    statusAlertsData.exchangeconnectiontestok
                                        .severity,
                                title: statusAlertsData.exchangeconnectiontestok
                                    .title,
                                message:
                                    statusAlertsData.exchangeconnectiontestok
                                        .message,
                            })
                        );
                    },
                    (error) => {
                        // console.log(error);
                        setIsConnected(false);
                        dispatch(setCloseLoadingBackdrop());
                        if (error && error.response && error.response.status) {
                            if (
                                error.response.status ===
                                StatusCodes.UNAUTHORIZED
                            ) {
                                navigate("../logout");
                            } else if (
                                error.response.status ===
                                StatusCodes.EXPECTATION_FAILED
                            ) {
                                dispatch(
                                    setOpenNotificationSnackbar({
                                        severity:
                                            statusAlertsData
                                                .exchangeconnectiontesterror
                                                .severity,
                                        title: statusAlertsData
                                            .exchangeconnectiontesterror.title,
                                        message:
                                            statusAlertsData
                                                .exchangeconnectiontesterror
                                                .message,
                                    })
                                );
                            } else {
                                dispatch(
                                    setOpenNotificationSnackbar({
                                        severity:
                                            statusAlertsData
                                                .exchangeconnectiontesterror
                                                .severity,
                                        title: statusAlertsData
                                            .exchangeconnectiontesterror.title,
                                        message:
                                            statusAlertsData
                                                .exchangeconnectiontesterror
                                                .message,
                                    })
                                );
                            }
                        }
                    }
                );
            }
        }
    };

    return (
        <Container
            component="main"
            sx={{
                mb: 2,
                pt: 2,
                pb: 2,
                boxShadow: 2,
                borderRadius: 2,
                background: "#0D0D45",
                overflow: "hidden",
            }}
            maxWidth="md"
        >
            <Typography
                variant="h5"
                component="h1"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    color: "#DCC28E",
                }}
            >
                <SettingsIcon
                    sx={{
                        mr: 1,
                        width: 27,
                        height: 27,
                    }}
                />
                Kriptovaluta tőzsde beállítása
            </Typography>
            {isInit ? (
                <>
                    {exchanges && exchanges.length > 0 ? (
                        <List>
                            {exchanges.map((exchange: any) => {
                                return (
                                    <ListItem
                                        key={"list-item-" + exchange.apiKey}
                                        sx={{ pl: 0 }}
                                    >
                                        <Tooltip title="kapcsolat törlése">
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => {
                                                    setExchangeToDelete(
                                                        exchange.apiKey
                                                    );
                                                }}
                                            >
                                                <DeleteForeverIcon
                                                    sx={{
                                                        mr: 1,
                                                        color: pink[500],
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={exchange.platform}>
                                            <img
                                                height="20"
                                                src={getPlatformIconLogoImage(
                                                    exchange.platform
                                                )}
                                                alt={
                                                    exchange.platform + " icon"
                                                }
                                            />
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                exchange.status ===
                                                ExchangeStatus.CONNECTED
                                                    ? "kapcsolódva"
                                                    : "nincs kapcsolat"
                                            }
                                        >
                                            <Typography
                                                component="span"
                                                align="center"
                                                sx={{
                                                    ml: 2,
                                                    mr: 2,
                                                    width: "8px",
                                                    cursor: "default",
                                                }}
                                            >
                                                {exchange.status ===
                                                ExchangeStatus.CONNECTED
                                                    ? "🟢"
                                                    : "🔴"}
                                            </Typography>
                                        </Tooltip>
                                        <Typography
                                            component="span"
                                            align="left"
                                            sx={{
                                                width: "10vw",
                                                minWidth: "60px",
                                                fontWeight: 900,
                                                cursor: "default",
                                            }}
                                        >
                                            apiKey:
                                        </Typography>
                                        <Typography component="span">
                                            {exchange.apiKey}
                                        </Typography>
                                    </ListItem>
                                );
                            })}
                        </List>
                    ) : (
                        <>
                            <Accordion
                                expanded={expanded === "panel1"}
                                onChange={handleExpandChange("panel1")}
                                sx={{ background: "#01003a" }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            sx={{ color: "#00FF80" }}
                                        />
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography
                                        component="h2"
                                        sx={{ color: "#00FF80" }}
                                    >
                                        1. lépés - Binace fiók létrehozása
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        sx={{
                                            mb: 2,
                                            color: "#eee",
                                        }}
                                    >
                                        A QR kód leolvasásával vagy az alábbi
                                        gomra kattintva nyissa meg a Binance
                                        oldalát és regisztráljon.
                                    </Typography>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems={{
                                            xs: "flex-start",
                                            sm: "center",
                                        }}
                                    >
                                        <Grid item xs={12} sm={6}>
                                            <Stack
                                                direction="column"
                                                alignItems="center"
                                                justifyContent="flex-start"
                                                spacing={2}
                                            >
                                                <Box
                                                    sx={{
                                                        alignItems: "center",
                                                        textAlign: "center",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <img
                                                        width="60%"
                                                        src={BinanceLogoImage}
                                                        alt="Binance logo"
                                                        onClick={() =>
                                                            openInNewTab(
                                                                binanceReferralLink
                                                            )
                                                        }
                                                    />
                                                </Box>
                                                <Typography
                                                    variant="body1"
                                                    textAlign="center"
                                                    component="p"
                                                    sx={{
                                                        mt: 0,
                                                        mb: 2,
                                                        color: "#eee",
                                                    }}
                                                >
                                                    Binance
                                                    Ajánlóazonosító(Referral
                                                    ID):
                                                    <br />
                                                    <strong>393150390</strong>
                                                </Typography>
                                                <Link
                                                    color="#eee"
                                                    variant="body2"
                                                    underline="hover"
                                                    onClick={() =>
                                                        openInNewTab(
                                                            binanceReferralLink
                                                        )
                                                    }
                                                    sx={{
                                                        mt: 0,
                                                        mb: 2,
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    {binanceReferralLink}
                                                </Link>
                                                <Button
                                                    variant="contained"
                                                    onClick={() =>
                                                        openInNewTab(
                                                            binanceReferralLink
                                                        )
                                                    }
                                                >
                                                    <Box
                                                        mr={1}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        sx={{
                                                            display: "flex",
                                                            // justify-content: center;
                                                        }}
                                                    >
                                                        <img
                                                            height="25"
                                                            src={
                                                                BinanceIconLogoImage
                                                            }
                                                            alt="Binance icon"
                                                        />
                                                    </Box>
                                                    Binance regisztráció
                                                </Button>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    height: "auto",
                                                    width: "100%",
                                                    maxWidth: "256px",
                                                    ml: "auto",
                                                    mr: "auto",
                                                    mt: 2,
                                                    mb: 3,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                                onClick={() =>
                                                    openInNewTab(
                                                        binanceReferralLink
                                                    )
                                                }
                                            >
                                                <QRCode
                                                    value={binanceReferralLink}
                                                    size={256}
                                                    style={{
                                                        height: "auto",
                                                        maxWidth: "100%",
                                                        width: "100%",
                                                        cursor: "pointer",
                                                    }}
                                                    viewBox={`0 0 256 256`}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel2"}
                                onChange={handleExpandChange("panel2")}
                                sx={{ background: "#01003a" }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            sx={{ color: "#00FF80" }}
                                        />
                                    }
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography
                                        component="h2"
                                        sx={{ color: "#00FF80" }}
                                    >
                                        2. lépés - KYC - személyazonosságának az
                                        igazolása
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        sx={{
                                            color: "#eee",
                                        }}
                                    >
                                        <strong>
                                            Fontos, hogy az API kulcs létrehozás
                                            előtt el kell végeznie a
                                            személyazonosságának az igazolásást
                                            (KYC - Identity Verification)!
                                        </strong>
                                        <br />
                                        Kérem készítse elő a személyazonosító
                                        igazolványát és lakcímkártyáját, töltse
                                        ki a Binace fiókjában a szükséges
                                        űrlapot, készülni fog egy fénykép is.
                                        <br />A KYC ellenőrzés egy egyszerű
                                        folyamat, amely mindössze 5 perc alatt
                                        elvégezhető. <br />A Binance részéről a
                                        jóváhagyás is jellemzően 30 percet vesz
                                        igénybe. <br />A KYC jóváhagyása után
                                        tudja folytatni a következő lépést.
                                        <br />
                                        <br />
                                        Tipp: javaslat okostelefonról elvégezni
                                        a műveletet. <br />
                                        Töltse le a Binace applikációt:
                                        <br />
                                        <Link
                                            color="#eee"
                                            variant="body1"
                                            underline="hover"
                                            onClick={() =>
                                                openInNewTab(
                                                    "https://www.binance.com/en/download"
                                                )
                                            }
                                            sx={{
                                                mt: 0,
                                                mb: 2,
                                                cursor: "pointer",
                                            }}
                                        >
                                            https://www.binance.com/en/download
                                        </Link>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel3"}
                                onChange={handleExpandChange("panel3")}
                                sx={{ background: "#01003a" }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            sx={{ color: "#00FF80" }}
                                        />
                                    }
                                    aria-controls="panel3a-content"
                                    id="panel3a-header"
                                >
                                    <Typography
                                        component="h2"
                                        sx={{ color: "#00FF80" }}
                                    >
                                        3. lépés - Binace kapcsolódási kulcs
                                        létrehozása
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        sx={{
                                            color: "#eee",
                                        }}
                                    >
                                        A Binace kapcsolódási kulcs (API)
                                        létrehozása lehetővé teszi, hogy az
                                        Automatizált Kriptovaluta Kereskedés
                                        össze tudjon kapcsolódni a Binance
                                        szervereivel. <br />
                                        Az API létrehozása egy egyszerű
                                        folyamat, amely mindössze 5 perc alatt
                                        elvégezhető. <br />
                                        <br />
                                    </Typography>
                                    <List
                                        sx={{
                                            listStyleType: "decimal",
                                            pl: 2,
                                            color: "#eee",
                                        }}
                                    >
                                        <ListItem
                                            sx={{
                                                display: "list-item",
                                                pl: 1,
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                component="p"
                                                sx={{
                                                    mb: 2,
                                                }}
                                            >
                                                Miután bejelentkezett
                                                Binance-fiókjába, kattintson az{" "}
                                                <strong>
                                                    [API Management]
                                                </strong>{" "}
                                                menüpontra a felhasználói
                                                központ ikonjából legördülő
                                                menüből.
                                            </Typography>
                                            <Box
                                                sx={{
                                                    alignItems: "center",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <img
                                                    width="100%"
                                                    src={BinanceGuideApiImage1}
                                                    alt="Binance Api útmutató 1."
                                                />
                                            </Box>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: "list-item",
                                                pl: 1,
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                component="p"
                                                sx={{
                                                    mb: 2,
                                                }}
                                            >
                                                Adja meg az API-kulcs
                                                címkéjét/nevét (Label Api key to
                                                proceed), majd kattintson az{" "}
                                                <strong>[Create API]</strong>{" "}
                                                gombra.
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="p"
                                                sx={{
                                                    mb: 2,
                                                }}
                                            >
                                                Biztonsági tipp: Mielőtt
                                                létrehozna egy API-t,
                                                engedélyeznie kell a{" "}
                                                <Link
                                                    color="#eee"
                                                    variant="body1"
                                                    underline="hover"
                                                    onClick={() =>
                                                        openInNewTab(
                                                            binance2FAGuideLink
                                                        )
                                                    }
                                                    sx={{
                                                        mt: 0,
                                                        mb: 2,
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    kétfaktoros hitelesítést
                                                </Link>{" "}
                                                (2FA) a fiókjában.
                                            </Typography>
                                            <Box
                                                sx={{
                                                    alignItems: "center",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <img
                                                    width="100%"
                                                    src={BinanceGuideApiImage2}
                                                    alt="Binance Api útmutató 2."
                                                />
                                            </Box>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: "list-item",
                                                pl: 1,
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                component="p"
                                                sx={{
                                                    mb: 2,
                                                }}
                                            >
                                                Végezze el a biztonsági
                                                ellenőrzést a regisztrált
                                                kétfaktoros hitelesítő
                                                eszközökkel.
                                            </Typography>
                                            <Box
                                                sx={{
                                                    alignItems: "center",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <img
                                                    width="100%"
                                                    src={BinanceGuideApiImage3}
                                                    alt="Binance Api útmutató 3."
                                                />
                                            </Box>
                                        </ListItem>
                                        <ListItem
                                            sx={{
                                                display: "list-item",
                                                pl: 1,
                                            }}
                                        >
                                            <Typography
                                                variant="body1"
                                                component="p"
                                                sx={{
                                                    mb: 2,
                                                }}
                                            >
                                                Az API létrejött.{" "}
                                                <Typography
                                                    variant="body1"
                                                    component="span"
                                                    sx={{
                                                        color: "red",
                                                    }}
                                                >
                                                    Kérjük, őrizze meg titkos
                                                    kulcsát biztonságosan, mert
                                                    többé nem fog megjelenni.
                                                </Typography>{" "}
                                                Ha elfelejti titkos kulcsát,
                                                törölnie kell az API-t, és újat
                                                kell létrehoznia.
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="p"
                                                sx={{
                                                    mb: 2,
                                                }}
                                            >
                                                Kérjük, vegye figyelembe az IP
                                                hozzáférési korlátozásokat.
                                                <br />
                                                Javasoljuk, hogy biztonsági
                                                okokból válassza a csak
                                                megbízható IP-címekre való
                                                hozzáférés korlátozása
                                                lehetőséget{" "}
                                                <strong>
                                                    [Restrict access to trusted
                                                    IPs only]
                                                </strong>
                                                <br />
                                                Itt állítsa be az Automatizált
                                                Kriptovaluta Kereskedés oldal IP
                                                címét: 193.201.184.77
                                                {/* TODO copy button */}
                                                {/* <strong>
                                                    {
                                                        process.env
                                                            .REACT_APP_ACT_HOST
                                                    }
                                                </strong> */}
                                                <br />
                                                <br />A korlátozások
                                                szerkesztése gombra{" "}
                                                <strong>
                                                    [Edit Restrictions]
                                                </strong>{" "}
                                                kattintva figyeljen rá, hogy az
                                                automatizált kereskedés
                                                működéséhez engedélyezni kell a
                                                olvasási{" "}
                                                <strong>
                                                    [Enable Reading],
                                                </strong>{" "}
                                                kereskedési{" "}
                                                <strong>
                                                    [Enable Spot & Margin
                                                    Trading],
                                                </strong>{" "}
                                                <strong>
                                                    [Enable Futures],
                                                </strong>{" "}
                                                <strong>
                                                    [Permits Universal
                                                    Transfer],
                                                </strong>{" "}
                                                <strong>
                                                    [Enable Withdrawals]
                                                </strong>{" "}
                                                és{" "}
                                                <strong>
                                                    [Enable European Options],
                                                </strong>{" "}
                                                jogosultságokat, ehhez a képen
                                                látható jelölő mezőket ki kell
                                                pipálni.
                                            </Typography>
                                            <Box
                                                sx={{
                                                    alignItems: "center",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <img
                                                    width="100%"
                                                    src={BinanceGuideApiImage4}
                                                    alt="Binance Api útmutató 4."
                                                />
                                            </Box>
                                        </ListItem>
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion
                                expanded={expanded === "panel4"}
                                onChange={handleExpandChange("panel4")}
                                sx={{ background: "#01003a" }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            sx={{ color: "#00FF80" }}
                                        />
                                    }
                                    aria-controls="panel4a-content"
                                    id="panel4a-header"
                                >
                                    <Typography
                                        component="h2"
                                        sx={{ color: "#00FF80" }}
                                    >
                                        3. lépés - Kapcsolodjon a Binance
                                        fiókjához
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ color: "#eee" }}>
                                    <Typography
                                        variant="body1"
                                        component="p"
                                        sx={{
                                            mb: 2,
                                        }}
                                    >
                                        Kérjük adja meg a Binance API
                                        kapcsolódási kulcsokat és nyomja meg a{" "}
                                        <strong>[Kapcsolódási teszt]</strong>{" "}
                                        gombot.
                                        <br />
                                        Sikereses kapcsolódás esetén kérem
                                        nyomja meg a <strong>
                                            [Rögzítés]
                                        </strong>{" "}
                                        gombot és indulhat az Automatizált
                                        Kriptovaluta Kereskedés! 🚀
                                    </Typography>
                                    <Container
                                        component="main"
                                        sx={{
                                            pl: 0,
                                            pr: 0,
                                            pt: 1,
                                            pb: 1,
                                        }}
                                        maxWidth="xs"
                                    >
                                        <Box
                                            component="form"
                                            noValidate
                                            onSubmit={handleSubmit}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        autoFocus
                                                        fullWidth
                                                        required
                                                        name="apiKey"
                                                        id="apiKey"
                                                        label="API kulcs (API key)"
                                                        autoComplete="API kulcs"
                                                        value={
                                                            formValues.apiKey
                                                        }
                                                        onChange={
                                                            handleFormChange
                                                        }
                                                        color={
                                                            isTested &&
                                                            formErrors.apiKey ===
                                                                null
                                                                ? "success"
                                                                : "info"
                                                        }
                                                        error={
                                                            isTested &&
                                                            formErrors.apiKey !==
                                                                null
                                                        }
                                                        helperText={
                                                            isTested &&
                                                            formErrors.apiKey !==
                                                                null
                                                                ? formErrors.apiKey
                                                                : null
                                                        }
                                                        inputProps={{
                                                            inputMode: "text",
                                                        }}
                                                        sx={{
                                                            "& .MuiOutlinedInput-root":
                                                                {
                                                                    "& > fieldset":
                                                                        {
                                                                            borderColor:
                                                                                isTested
                                                                                    ? formErrors.apiKey !==
                                                                                      null
                                                                                        ? null
                                                                                        : "green"
                                                                                    : "#DCC28E",
                                                                        },
                                                                },
                                                            "& .MuiOutlinedInput-root:hover":
                                                                {
                                                                    "& > fieldset":
                                                                        {
                                                                            borderColor:
                                                                                isTested
                                                                                    ? formErrors.apiKey !==
                                                                                      null
                                                                                        ? null
                                                                                        : "green"
                                                                                    : "orange",
                                                                        },
                                                                },
                                                            input: {
                                                                color: "#eee",
                                                                borderColor:
                                                                    isTested
                                                                        ? formErrors.apiKey !==
                                                                          null
                                                                            ? null
                                                                            : "green"
                                                                        : "orange",
                                                            },
                                                            label: {
                                                                color: "#eee",
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        autoFocus
                                                        fullWidth
                                                        required
                                                        name="secret"
                                                        id="secret"
                                                        label="Titkos kulcs (Secret key)"
                                                        autoComplete="Titkos kulcs"
                                                        value={
                                                            formValues.secret
                                                        }
                                                        onChange={
                                                            handleFormChange
                                                        }
                                                        color={
                                                            isTested &&
                                                            formErrors.secret ===
                                                                null
                                                                ? "success"
                                                                : undefined
                                                        }
                                                        error={
                                                            isTested &&
                                                            formErrors.secret !==
                                                                null
                                                        }
                                                        helperText={
                                                            isTested &&
                                                            formErrors.secret !==
                                                                null
                                                                ? formErrors.secret
                                                                : null
                                                        }
                                                        inputProps={{
                                                            inputMode: "text",
                                                        }}
                                                        sx={{
                                                            "& .MuiOutlinedInput-root":
                                                                {
                                                                    "& > fieldset":
                                                                        {
                                                                            borderColor:
                                                                                isTested
                                                                                    ? formErrors.secret !==
                                                                                      null
                                                                                        ? null
                                                                                        : "green"
                                                                                    : "#DCC28E",
                                                                        },
                                                                },
                                                            "& .MuiOutlinedInput-root:hover":
                                                                {
                                                                    "& > fieldset":
                                                                        {
                                                                            borderColor:
                                                                                isTested
                                                                                    ? formErrors.secret !==
                                                                                      null
                                                                                        ? null
                                                                                        : "green"
                                                                                    : "orange",
                                                                        },
                                                                },
                                                            input: {
                                                                color: "#eee",
                                                                borderColor:
                                                                    isTested
                                                                        ? formErrors.secret !==
                                                                          null
                                                                            ? null
                                                                            : "green"
                                                                        : "orange",
                                                            },
                                                            label: {
                                                                color: "#eee",
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {isTested && isConnected ? (
                                                <>
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={
                                                            <SaveAltIcon />
                                                        }
                                                        sx={{ mt: 3 }}
                                                    >
                                                        Rögzítés
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        color="success"
                                                        startIcon={
                                                            <HandshakeIcon />
                                                        }
                                                        sx={{ mt: 3 }}
                                                    >
                                                        Kapcsolódási teszt
                                                    </Button>
                                                </>
                                            )}
                                        </Box>
                                    </Container>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    )}
                </>
            ) : null}
        </Container>
    );
};

export default Exchanges;
