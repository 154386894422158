import RegistrationFormValuesType from "../../types/home/registrationFormValuesType";
import RegistrationFormErrorsType from "../../types/home/registrationFormErrorsType";
import { initialRegistrationFormErrors } from "../../datas/home/initialRegistrationFormErrors";

export const validateRegistrationForm = (
    values: RegistrationFormValuesType
) => {
    const errors: RegistrationFormErrorsType = {
        ...initialRegistrationFormErrors,
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    const checkLowerCase = (password: string) => {
        for (let char of password) {
            if (char === char.toLowerCase() && char.match(/[a-z]/i)) {
                return true;
            }
        }
        return false;
    };

    const checkUpperCase = (password: string) => {
        for (let char of password) {
            if (char === char.toUpperCase() && char.match(/[A-Z]/i)) {
                return true;
            }
        }
        return false;
    };

    const checkNumberCase = (password: string) => {
        for (let char of password) {
            if (!isNaN(Number(char)) && char.match(/[0-9]/i)) {
                return true;
            }
        }
        return false;
    };

    if (!values.lastName || values.lastName === "") {
        errors.lastName = "A vezetéknevet kötelező kitölteni!";
    } else if (values.lastName.length > 40) {
        errors.lastName = "A vezetéknév nem lehet hosszabb 40 betűnél!";
    }

    if (!values.firstName || values.firstName === "") {
        errors.firstName = "A keresztnevet kötelező kitölteni!";
    } else if (values.firstName.length > 40) {
        errors.firstName = "A keresztnév nem lehet hosszabb 40 betűnél!";
    }

    if (!values.country) {
        errors.country = "Az országot kötelező kiválasztani!";
        errors.phone =
            "Az országot kötelező kiválasztani a országelőhívószám miatt!";
        errors.zip =
            "Az országot kötelező kiválasztani az irányítószám ellenőrzés miatt!";
    }

    if (!values.zip || values.zip === "") {
        errors.zip = "Az irányítószámot kötelező kitölteni!";
    }

    if (!values.email || values.email === "") {
        errors.email = "Az e-mail címet kötelező kitölteni!";
    } else if (!emailRegex.test(values.email)) {
        errors.email = "Hibás e-mail cím formátum!";
    }

    if (!values.phone || values.phone === "") {
        errors.phone = "A mobil telefonszámot kötelező kitölteni!";
    }

    if (
        values.password &&
        values.password !== "" &&
        values.passwordAgain &&
        values.passwordAgain !== "" &&
        values.password !== values.passwordAgain
    ) {
        errors.password = "A beírt jelszavaknak meg kell egyeznie!";
        errors.passwordAgain = "A beírt jelszavaknak meg kell egyeznie!";
    }

    if (!values.password || values.password === "") {
        errors.password = "A jelszót kötelező kitölteni!";
    } else if (values.password.length < 8) {
        errors.password = "A jelszó nem lehet rövidebb 8 karakternél!";
    } else if (values.password.length > 30) {
        errors.password = "A jelszó nem lehet hosszabb 30 karakternél!";
    } else if (!checkLowerCase(values.password)) {
        errors.password = "A jelszónak legalább egy kisbetűt kell tartalmazni!";
    } else if (!checkUpperCase(values.password)) {
        errors.password =
            "A jelszónak legalább egy nagybetűt kell tartalmazni!";
    } else if (!checkNumberCase(values.password)) {
        errors.password = "A jelszónak legalább egy számot kell tartalmaznia!";
    }

    if (!values.passwordAgain || values.passwordAgain === "") {
        errors.passwordAgain = "A jelszót kötelező kitölteni!";
    } else if (values.passwordAgain.length < 8) {
        errors.passwordAgain = "A jelszó nem lehet rövidebb 8 karakternél!";
    } else if (values.passwordAgain.length > 40) {
        errors.passwordAgain = "A jelszó nem lehet hosszabb 40 karakternél!";
    } else if (!checkLowerCase(values.passwordAgain)) {
        errors.passwordAgain =
            "A jelszónak legalább egy kisbetűt kell tartalmaznia!";
    } else if (!checkUpperCase(values.passwordAgain)) {
        errors.passwordAgain =
            "A jelszónak legalább egy nagybetűt kell tartalmaznia!";
    } else if (!checkNumberCase(values.passwordAgain)) {
        errors.passwordAgain =
            "A jelszónak legalább egy számot kell tartalmaznia!";
    }

    if (!values.termsAccepted) {
        errors.termsAccepted =
            "A szabályzatot és az adatkezelési tájékoztatót el kell fogdani a regisztrációhoz!";
    }

    // magyar sajátosságok
    if (values.country && values.country.code && values.country.code === "HU") {
        // Magyarországon a legrövidebb vezetéknév 2 betűből áll.
        if (values.lastName.length < 2) {
            errors.lastName = "A vezetéknév nem lehet rövidebb 2 betűnél!";
        }

        // Magyarországon a legrövidebb keresztnév 3 betűből áll.
        if (values.firstName && values.firstName.length < 3) {
            errors.firstName = "A keresztnév nem lehet rövidebb 3 betűnél!";
        }

        // Magyarországon az irányítószámok 4 számból állnak és nem nullával kezdődnek
        if (values.zip) {
            if (values.zip.length < 4) {
                errors.zip = "Az irányítószám túl rövid!";
            } else if (values.zip.length > 4) {
                errors.zip = "Az irányítószám túl hosszú!";
            } else if (isNaN(Number(values.zip))) {
                errors.zip = "Az irányítószám csak számokból állhat!";
            } else {
                const zipFirstNumber = values.zip.slice(0, 1);
                if (zipFirstNumber === "0") {
                    errors.zip = "Az irányítószám nem kezdődhet nullával!";
                }
            }
        }

        // Magyarországon a mobil telefonszámok 9 számból állnak (ország előhívó nélkül)
        if (values.phone) {
            if (values.phone.length < 9) {
                errors.phone = "A mobil telefonszám túl rövid";
            } else if (values.phone.length > 9) {
                errors.phone = "A mobil telefonszám túl hosszú!";
            } else if (isNaN(Number(values.phone))) {
                errors.phone = "A mobil telefonszám csak számokból állhat!";
            } else {
                const mobileCode = values.phone.slice(0, 2);
                if (
                    mobileCode !== "20" &&
                    mobileCode !== "30" &&
                    mobileCode !== "31" &&
                    mobileCode !== "50" &&
                    mobileCode !== "60" &&
                    mobileCode !== "70"
                ) {
                    errors.phone = "Érvénytelen mobil előhívószám!";
                }
            }
        }
    }

    return errors;
};
